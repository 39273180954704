import { useContext, useEffect, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { VisiteContext } from "../../services/visiteContext";

const TitrePerso = ({ block }) => {

    const { handleChange } = useContext(VisiteContext);

    const [titre, setTitre] = useState(null);
    const [edit, setEdit] = useState(false);

    useEffect(() => {

        setTitre(block.titreperso ? block.titreperso : '');

    }, [block]);

    const updateTitre = (event) => {
        event.stopPropagation();
        setTitre(event.target.value);
        handleChange(block.id, event);
    }

    const toggleEdit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setEdit(!edit);
    }

    const tooltip = (props) => (
        <Tooltip id="tooltip-edit-titre-perso" {...props}>
            {edit ? 
                "Valider" : 
                titre ? "Modifier le titre personnalisé" : "Rédiger un titre personnalisé"
            }
        </Tooltip>
    );

    return(
        <div className="titre-perso">
            {edit ? 
                <Form.Group>
                    <Form.Control size="sm" type="text" value={titre} onChange={updateTitre} onInput={(e) => e.stopPropagation()} onClick={(e) => e.stopPropagation()} />
                </Form.Group> :
                <span>{titre}</span>
            }
            <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={tooltip}>
                <a className="btn" onClick={toggleEdit} href="edit-title">
                    {edit ? <i className="icon icon-check"></i> : <i className="icon icon-pencil"></i>}
                </a>
            </OverlayTrigger>
        </div>
    )
}

export default TitrePerso;