import { dbGet } from "./indexedDBService"

const useBlock = () => {

    const cloneElement = (originalElements, newElement) => {

        // Recherche de la correspondance à la racine d'originalElements
        const currentIndex = originalElements.findIndex(element => element.id === newElement.id);

        if(currentIndex !== -1){
            // Initialiser le nouvel element et récupérer son nouvel id et sa position dans le tableau
            const {id, index} = initElement(newElement, originalElements);

            // Intégrer le nouvel element dans le tableau à l'index donné
            originalElements.splice(index, 0, newElement);
            
            return {id: id, elements: [...originalElements]};
        }
    }

    const initElement = (block, originalElements) => {

        let nextNumber = 1;
        let nextId;
        let nextIndex = originalElements.findIndex(element => element.id === block.id) + 1;

        while(nextNumber < 100){

            // Créer un nouvel id
            const tempId = createId(block.id, nextNumber);

            // Vérifier si le nouvel id existe déjà dans le tableau
            const existe = originalElements.findIndex(element => element.id === tempId);

            if(existe !== -1){
                nextNumber += 1;
                nextIndex = existe + 1;
            } else {
                nextId = tempId;
                break;
            }
        }

        block.id = nextId;

        const updateChildren = (children) => {
            children.forEach(child => {
                child.id = createId(child.id, nextNumber);
                if(child.elements) updateChildren(child.elements);
            });
        }

        if(block.elements){
            updateChildren(block.elements);
        }

        return {id: nextId, index: nextIndex};
    }

    const createId = (originalId, nextNumber) => {

        const id = originalId;

        // Prochain suffixe
        let suffixe = '~' + nextNumber;

        // Prochain id
        let nextId = id + suffixe;

        // si l'id a déjà un suffixe
        if(id.indexOf('~') !== -1){
            suffixe = '~' + nextNumber;
            nextId = nextId.substring(0, id.indexOf('~')) + suffixe;
        }

        return nextId;
    }

    const getNewElements = async (visite, newBlock) => {

        const contentId = [visite.uid, visite.type_bien];
        
        return await dbGet('visits_content', contentId)
        .then(result => {
            if(result){
                const originalElements = result.content;
                const finalContent = cloneElement(originalElements, newBlock);
                return finalContent;
            }
        });
    }

    const deleteBlock = async (visite, block) => {

        const contentId = [visite.uid, visite.type_bien];

        return await dbGet('visits_content', contentId)
        .then(result => {

            const originalElements = result.content;
            const index = originalElements.findIndex(element => element.id === block.id);

            if(index !== -1){
                
                const nextElements = originalElements.filter(element => element.id !== block.id);
                return [...nextElements];
    
            }
        });

    }

    return { getNewElements, deleteBlock }

}

export default useBlock;