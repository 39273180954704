import { Modal } from "react-bootstrap";
import LocationItem from "./biens/location-item/location-item";

const ModalBiens = (props) => {

    return(
        <Modal {...props} size="xl" centered>
            <Modal.Header closeButton>
                <Modal.Title>{`${props.biens.length} ${props.biens.length > 1 ? "biens" : "bien"}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.biens.map((bien, index) => <LocationItem key={`${bien.id}-${index}`} location={bien} />)}
            </Modal.Body>
        </Modal>
    )
}

export default ModalBiens;