import React, { useEffect, useState } from "react";
import { Badge, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import DatePicker from 'react-date-picker';

import { config } from "../../config";
import useAutorisations from '../../services/useAutorisations';
import { getCurrentVisite, getIsForVisite, setCurrentVisite, saveCurrentVisite } from "../../services/visitesService";

import ClesEstimees from "./cles-estimees";
import ButtonDeleteVisite from "../button-delete-visite";
import ButtonDuplicateVisite from '../button-duplicate-visite';
import ButtonLockVisite from "../button-lock-visite";
import ModalComment from "../modal-comment";
import ButtonFiles from "../button-files";
import { subscribeIsOnline } from "../../services/isonlineService";

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

const VisitEditHeader = ({ hlo, agr, title, clesCalcul, date, visite}) => {

    const [dateVisite, setDateVisite] = useState(new Date(date * 1000));
    const isInsolite = visite.type_bien === 'inso';
    const typeBienName = config.group_visit[visite.type_bien].name;
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isOnline, setIsOnline] = useState(window.navigator.onLine);

    const { canDeleteVisite } = useAutorisations();

    //ne pas afficher les clés ou autres fonctionnalités (sauf suppression) si type_visite.forvisit == false
    const showFullToolbar = getIsForVisite(visite);

    useEffect(() => {

        const onOnline = (isonline) => {
            setIsOnline(isonline);
        }

        const unsubscribeOnline = subscribeIsOnline(onOnline);

        return () => unsubscribeOnline(onOnline);

    }, []);

    useEffect(() => {
        const nextDate = new Date(dateVisite);
        setCurrentVisite({date_visite: nextDate.getTime() / 1000});
    }, [dateVisite]);

    const tooltipCompteRendu = (props) => (
        <Tooltip id="tooltip-compte-rendu-visite" {...props}>
            {(visite.cpt_rendu && visite.cpt_rendu !== '<p></p>') ? "Modifier le compte-rendu de cette visite" : "Rédiger un compte-rendu pour cette visite"}
        </Tooltip>
    );

    const tooltipClesCalculees = (props) => (
        <Tooltip id="tooltip-cles-calculees-visite" {...props}>Clé calculée</Tooltip>
    );

    const closeModalComment = () => {
        visite.cpt_rendu = getCurrentVisite().cpt_rendu;
        setShowCommentModal(false);
    }

    const onSave = async () => {
        setIsSaving(true);
        const saveuid = await saveCurrentVisite(visite);
        if(saveuid) {
            console.log(saveuid);
        } else {
            console.log('ERROR');
        }
        setIsSaving(false);
    }

    return (
        <div className="content-header visite-header bg-light border-bottom">
            <div className="d-flex justify-content-between align-items-center px-3">
                <div className="d-flex align-items-center">
                    <h1 className="visite-title fs-5 mb-0 d-flex">

                        {showFullToolbar && <>
                            {(isInsolite && clesCalcul === 5) ? 
                                <span className="badge rounded-pill bg-success"><i className="icon icon-check"></i></span> :
                                !isInsolite && <div className="cles-container">
                                    <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={tooltipClesCalculees}>
                                        <Badge pill title="Clés calculées">
                                            {clesCalcul} <i className="icon icon-cle"></i>
                                        </Badge>
                                    </OverlayTrigger>
                                    <ClesEstimees clesCalcul={clesCalcul} visite={visite} />
                                </div>
                            }
                        </>
                       }

                        <div className="visite-name">
                            <span className="fs-6 fw-light">{visite.uid} / {hlo} / {agr} / {typeBienName}</span>
                            <br/>
                            <strong>{title}</strong>
                        </div>
                    </h1>
                </div>
                <div className="vr"></div>
                {showFullToolbar ?
                    <div className="visite-toolbar d-flex align-tems-center">

                        {visite.date_visite > config.autorisations.duplication.date_visite &&
                            <ButtonDuplicateVisite visite={visite}/>
                        }

                        <ButtonLockVisite visite={visite} />

                        {canDeleteVisite(visite) && <ButtonDeleteVisite visite={visite} />}

                        <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={tooltipCompteRendu}>
                            <Button variant={(visite.cpt_rendu && visite.cpt_rendu !== '<p></p>') ? "success" : "outline-secondary"} onClick={() => setShowCommentModal(true)} title="Éditer le compte-rendu de cette visite"><i className="fs-5 icon icon-text"></i></Button>
                        </OverlayTrigger>

                        {(visite.type_bien && visite.type_bien === "inso") &&
                            <ButtonFiles visite={visite} />
                        }

                    </div>
                    :
                    <div className="visite-toolbar d-flex align-tems-center">
                        {canDeleteVisite(visite) && <ButtonDeleteVisite visite={visite} />}
                    </div>

                }
                <div className="vr"></div>
                <div className="visite-date ps-2">
                    <div className="d-flex gap-3 align-items-end">
                        <div className="d-flex flex-column">
                            <span className="me-2 text-muted">Date visite</span>
                            <DatePicker value={dateVisite} onChange={setDateVisite} locale="fr-FR" format="dd-MM-y" clearIcon={null} />
                        </div>
                        <button className="btn btn-success d-flex align-items-center gap-1" type="button" onClick={onSave} disabled={isSaving || !isOnline}>
                            Enregistrer
                            {isSaving && <span className="spinner-border spinner-border-sm" role="status"></span>}
                        </button>
                    </div>
                </div>
            </div>
            {showCommentModal && <ModalComment show={showCommentModal} onHide={closeModalComment} visite={visite} />}
        </div>
    )
}

export default VisitEditHeader;
