import { config } from "../config";
import { dbBulkAdd, dbFetchStore } from "./indexedDBService";

let users = [];
let currentUser = null;
const subscribers = [];

const loadUsers = async (credentials) => {
    // Chargement des users à partir de la base locale
    await dbFetchStore('users').then(async data => {
        users = data;
        if(credentials){
            // Chargement des utilisateurs à partir du serveur
            if(window.navigator.onLine){
                /* UN seul technicien, chargé dans Login.js depuis le SAML */
                await _loadUsersFromServer(credentials).then(result => {
                    users = result;
                    _setCurrentUser(credentials);
                });
            } else {
                console.log('credentials:',credentials);
                _setCurrentUser(credentials);
            }

        }
    });

    _publish();
}

const _setCurrentUser = (credentials) => {
    currentUser = users.find(user => user.email.toLowerCase() === credentials.login.toLowerCase());
    if(!currentUser){
        currentUser = {
            mdp: credentials.pass,
            email: credentials.login
        }
    } else {
        currentUser.mdp = credentials.pass;
        currentUser.email = credentials.login;
    }
}

const subscribeUsers = (subscriber) => {
    if(subscribers.indexOf(subscriber) === -1){
        subscribers.push(subscriber);
        return _unsubscribe;
    }
}

const getUserByEmail = (email) => {
    return users.find(user => user.email === email);
}

const getUserById = (id) => {
    const res = users.find(user => user.uid === id );
    if(res !== undefined){
        return res;
    }else{
        return {"firstname" :"Technicien", "lastname" :"inconnu"}
    }
}

const setCurrentUser = (user) => {
    currentUser = user;
}

const getCurrentUser = () => {
    return currentUser;
}

const _loadUsersFromServer = async (credentials) => {

    try {
        const host = config.apiData.domain;
        const pass = encodeURIComponent(credentials.pass);
        const response = await fetch(host + config.apiData.entities.users.getList + '&login=' + credentials.login + '&pass=' + pass);
        if(response.ok){
            const data = await response.json();
            // await dbClearTable('users');
            return dbBulkAdd('users', data.users).then(result => {
                users = result;
                return users;
            });
        } else {
            console.warn('MAUVAIS LOGIN');
            return users;
        }
    } catch (error) {
        console.warn('ERREUR : ', error);
        return users;
    }
}

const _unsubscribe = (subscriber) => {
    const index = subscribers.indexOf(subscriber);
    if(index !== -1){
        subscribers.splice(index, 1);
    }
}

const _publish = () => {
    subscribers.forEach(subscriber => {
        subscriber(users);
    });
}

export { loadUsers, subscribeUsers, getUserByEmail, getUserById, setCurrentUser, getCurrentUser }
