let isReloadProtected = true;

const removeAccents = text => text.normalize("NFD").replace(/\p{Diacritic}/gu, "");

const getTimestamp = () => Math.round(new Date().getTime() / 1000);

const convertTimeToTimestamp = (time) => Math.round(time / 1000);

const getTimestampFromDate = (date) => Math.round(new Date(date).getTime() / 1000);

const getDateFromTimestamp = (timestamp, heures=false) => {
    const date = new Date(timestamp * 1000);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: heures ? '2-digit' : undefined, minute: heures ? '2-digit' : undefined };
    return date.toLocaleDateString('fr-FR', options);
}
const getCurrentDate = () => {
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const day = new Date().getDate();
    const nextDate = new Date(year , month, day);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return nextDate.toLocaleDateString('fr-FR', options);
}
const convertToFrenchDate = (date) => {
    const fdate = new Date(date);
    return getDateLightFromTimestamp(convertTimeToTimestamp(fdate.getTime()));
}

const getDateLightFromTimestamp = (timestamp, heures=false) => {
    const date = new Date(timestamp * 1000);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: heures ? '2-digit' : undefined, minute: heures ? '2-digit' : undefined };
    return date.toLocaleDateString('fr-FR', options);
}

const getDateYearsAdded = (timestamp, years) => {
    const date = new Date(timestamp * 1000);
    const year = new Date(date).getFullYear();
    const month = new Date(date).getMonth();
    const day = new Date(date).getDate();
    const nextDate = new Date(year + years, month, day);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return nextDate.toLocaleDateString('fr-FR', options);
}
const getYearYearsAdded = (timestamp, years) => {
    const date = new Date(timestamp * 1000);
    const year = new Date(date).getFullYear();
    const month = new Date(date).getMonth();
    const day = new Date(date).getDate();
    const nextDate = new Date(year + years, month, day);
    const options = { year: 'numeric' };
    return nextDate.toLocaleDateString('fr-FR', options);
}

const getDateMonthsAdded = (timestamp, months) => {
    const date = new Date(timestamp * 1000);
    const year = new Date(date).getFullYear();
    const month = new Date(date).getMonth();
    const day = new Date(date).getDate();
    const nextDate = new Date(year, month + months, day);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return nextDate.toLocaleDateString('fr-FR', options);
}

const stopReloadProteted = () => {
    isReloadProtected = false;
    setTimeout(() => {
        isReloadProtected = true;
    }, 1000);
}

const setReloadProtected = () => {
    isReloadProtected = true;
}

const disableReloadProtection = () => {
    isReloadProtected = false;
}

const getIsReloadProtected = () => {
    return isReloadProtected;
}

export {
    removeAccents,
    getTimestamp,
    getCurrentDate,
    getTimestampFromDate,
    getDateFromTimestamp,
    getDateLightFromTimestamp,
    getDateYearsAdded,
    getYearYearsAdded,
    getDateMonthsAdded,
    stopReloadProteted,
    setReloadProtected,
    disableReloadProtection,
    getIsReloadProtected,
    convertTimeToTimestamp,
    convertToFrenchDate
}
