import React from 'react';
import { Col, Row } from 'react-bootstrap';
import InfosLocations from '../components/dashboard/infos-locations';
import InfosVisites from '../components/dashboard/infos-visites';
import InfosNewLocations from '../components/dashboard/infos-newlocations';
import InfosDernieresVisites from '../components/dashboard/infos-dernieres-visites';
// import InfosLocationsWithoutAgr from '../components/dashboard/infos-locationswithoutagr';

const Dashboard = () => {

    return (
        <div className="dashboard-body">
            <div className="content-header border-bottom bg-light">
                <h1>Bienvenue dans Nomade</h1>
            </div>
            <div className="content-body">
                <Row>
                    <Col>
                        <InfosLocations />
                    </Col>
                    <Col>
                        <InfosVisites />
                    </Col>
                </Row>
                <Row className="py-3">
                    <Col>
                        <InfosDernieresVisites />
                    </Col>
                    <Col>
                        <InfosNewLocations />
                    </Col>
                </Row>

            </div>
        </div>
    );
}

export default Dashboard;
