import React, { useState, useContext, useEffect } from "react";
import { VisiteContext } from "../../services/visiteContext";
import DatePicker from "react-date-picker";

const InputDate = ({ block }) => {

    const [date, setDate] = useState(block.valeur);
    const { handleChange } = useContext(VisiteContext);

    useEffect(() => {
        if(block.valeur) {
            setDate(new Date(block.valeur * 1000));
        }
    }, [block]);

    return (
        <div className="d-flex gap-2 align-items-center">
            <label htmlFor={block.id} className="form-label">{block.titre}</label>
            <DatePicker value={date} onChange={event => handleChange(block.id, event)} locale="fr-FR" format="dd-MM-y" clearIcon={null} />
            {block.aide && <div id={"help-" + block.id} className="form-text" dangerouslySetInnerHTML={{__html: block.aide}} />}
        </div>
    );
}

export default InputDate;