import { Button, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import FileLoader from "./files/file-loader";
import { useEffect, useState } from "react";
import {subscribeIsOnline} from "../services/isonlineService";

const ButtonFiles = ({visite}) => {

    const [isEnabled, setIsEnabled] = useState(window.navigator.onLine);

    useEffect( ()=>{
        const onOnline = (onLine) =>{
            setIsEnabled(onLine);
        }
        const unSubScribe = subscribeIsOnline(onOnline);
        return ()=>{
            unSubScribe(onOnline);
        }
    }, [])

    const popover = (
        <Popover id="popover-gestion-fichiers" style={{ maxWidth: '400px' }}>
            <Popover.Body>
                <div className="d-flex justify-content-between">
                    <FileLoader group_visite="inso" uid={visite.uid}  label="" />
                </div>
            </Popover.Body>
        </Popover>
    );

    const tooltip = (props) => (
        <Tooltip id="gestion-fichiers" {...props}>
            Gestion des fichiers
        </Tooltip>
    );

    return(
        <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={tooltip}>
            <span>
                <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={popover}>
                    <Button className="btn btn-info text-white" disabled={!isEnabled}><i className="fs-5 icon icon-share-ios" title="Gestion des fichiers"></i></Button>
                </OverlayTrigger>
            </span>
        </OverlayTrigger>
    )
};

export default ButtonFiles;