import React, { Fragment, useState, useEffect, useRef } from "react";
import { getBiens, subscribeLocations } from "../services/locationsService";
import { FixedSizeList as List } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import LocationItem from "../components/biens/location-item/location-item";
import BienFilters from "../components/biens/filters";
import { Alert } from "react-bootstrap";
import useQuery from "../services/useQuery";
import useBiens from "../services/useBiens";

const Biens = () => {

    const searchParams = useQuery();
    const [locations, setLocations] = useState([]);
    const [isloading, setIsloading] = useState(true);
    const [sort, setSort] = useState({by: 'date_visite', order: 'ASC'});

    const { biensSansVisite, biensAvecVisiteNonScellee, biensRefaire6Mois, biensRefaire5Ans, visites } = useBiens();

    useEffect(() => {

        let mounted = true;

        const updateBiens = () => {

            let biens = getBiens();

            if(searchParams.get('search')){
                biens = biens.filter(bien => bien.loc_hlo === searchParams.get('search'));
            }

            if(searchParams.get('actifs')){
                biens = biens.filter(bien => bien.state === 'actif');
            }

            if(searchParams.get('inactifs')){
                biens = biens.filter(bien => bien.state === 'inactif');
            }

            if(searchParams.get('sansdatevisite')){
                biens = biens.filter(bien => bien.date_visite === null);
            }
            if(searchParams.get('sansagrement')){
                biens = biens.filter(bien => !bien.agr);
            }

            if(searchParams.get('sansvisite')){
                const hlos = visites.map(visite => visite.loc_hlo);
                biens = biens.filter(bien => hlos.indexOf(bien.loc_hlo) === -1);
            }

            if(searchParams.get('sansvisite')){
                if(searchParams.get('actifs')) biens = biensSansVisite.filter(bien => bien.state === 'actif');
                if(searchParams.get('inactifs')) biens = biensSansVisite.filter(bien => bien.state === 'inactif');
            }

            if(searchParams.get('visitesnonscellees')){
                if(searchParams.get('actifs')) biens = biensAvecVisiteNonScellee.filter(bien => bien.state === 'actif');
                if(searchParams.get('inactifs')) biens = biensAvecVisiteNonScellee.filter(bien => bien.state === 'inactif');
            }

            if(searchParams.get('visite6mois')){
                if(searchParams.get('actifs')) biens = biensRefaire6Mois.filter(bien => bien.state === 'actif');
                if(searchParams.get('inactifs')) biens = biensRefaire6Mois.filter(bien => bien.state === 'inactif');
            }

            if(searchParams.get('visite5Ans')){
                if(searchParams.get('actifs')) biens = biensRefaire5Ans.filter(bien => bien.state === 'actif');
                if(searchParams.get('inactifs')) biens = biensRefaire5Ans.filter(bien => bien.state === 'inactif');
            }

            if(searchParams.get('type') === 'loc'){
                biens = biens.filter(bien => bien.type === 'loc');
            }

            if(searchParams.get('type') === 'ch'){
                biens = biens.filter(bien => bien.type === 'ch');
            }

            if(searchParams.get('type') === 'inso'){
                biens = biens.filter(bien => bien.type === 'inso');
            }

            if(searchParams.get('type') === 'hll'){
                biens = biens.filter(bien => bien.type === 'hll');
            }

            if(searchParams.get('departement')){
                const biensFiltered = biens.filter(bien => bien.geo_dep_id === searchParams.get('departement'));
                biens = biensFiltered;
            };

            setSort({by: 'date_visite', order: 'ASC'});

            setLocations(biens);
        }

        if(mounted){
            updateBiens();
        }

        const updateLocations = (data) => {
            setLocations(data)
        };

        const unsubscribe = subscribeLocations(updateLocations);

        return () => {
            unsubscribe(updateLocations);
            mounted = false;
        }

    }, [visites, searchParams, biensSansVisite, biensAvecVisiteNonScellee, biensRefaire6Mois, biensRefaire5Ans]);

    useEffect(() => {
        if(isloading) setIsloading(false);
    }, [locations, isloading]);

    const sortRef = useRef();
    useEffect(() => {
        sortRef.current = () => {

            if(!locations.length) return;

            const biensSansValeur = locations.filter(bien => !bien[sort.by]);
            const biensAvecValeur = locations.filter(bien => bien[sort.by] !== null && bien[sort.by] !== undefined);

            const nextBiens = biensAvecValeur.sort((a, b) => {
                if(a[sort.by] < b[sort.by]) return -1;
                if(a[sort.by] > b[sort.by]) return 1;
                return 0;
            });


            if(sort.order === 'DESC'){
                nextBiens.reverse();
            }
            const finalBiens = nextBiens.concat(biensSansValeur);

            setLocations(finalBiens);
        }
    });

    useEffect(() => {

        sortRef.current();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort]);

    //
    const itemRenderer = ({data, index, style}) => {
        return(
            <div style={style}>
                <LocationItem style={style} location={data[index]} />
            </div>
        )
    }

    const updateSort = (value) => {
        if(value === sort.by){
            setSort({...sort, order: sort.order === 'ASC' ? 'DESC' : 'ASC'});
        } else {
            setSort({...sort, by: value});
        }
    }

    return(
        <Fragment>
            <div className="content-header px-3 d-flex align-items-center justify-content-between border-bottom">
                <div className="d-flex align-items-center">
                    <h1 className="fs-2">{locations.length} Biens</h1>
                </div>
                <BienFilters />
            </div>
            <div className="content-body biens-content-body">
                <div className="liste-biens-header sticky-top border-bottom bg-light">
                    <div className={`location-infos${sort.by === 'loc_hlo' ? " active" : ""}`} onClick={(e) => updateSort('loc_hlo')}><small>HLO</small></div>
                    <div className={`location-type${sort.by === 'type' ? " active" : ""}`} onClick={(e) => updateSort('type')}><small>Type</small></div>
                    <div className={`location-date_visite${sort.by === 'date_visite' ? " active" : ""}`} onClick={(e) => updateSort('date_visite')}><small>Date visite</small></div>
                    <div className="location-contact"><small>Contact</small></div>
                    <div className={`location-commune${sort.by === 'commune' ? " active" : ""}`} onClick={(e) => updateSort('commune')}><small>Commune</small></div>
                    <div className="location-actions">&nbsp;</div>
                </div>
                <div className="liste-biens-container">
                    {locations.length ?
                        <AutoSizer>
                            {({ height, width }) => (
                                <List
                                    height={height}
                                    itemCount={locations.length}
                                    itemSize={100}
                                    layout="vertical"
                                    width={width}
                                    itemData={locations}
                                    itemKey={(index, data) => data[index].loc_hlo}
                                >
                                    {itemRenderer}
                                </List>
                            )}
                        </AutoSizer> :
                        <div className="p-5 text-center">
                            {(isloading && locations.length === 0) && <Alert variant="secondary">Chargement des données…</Alert>}
                            {locations.length === 0 && <Alert variant="info">Aucun bien ne correspond à vos critères</Alert>}
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    );
}

export default Biens;
