const Constantes = {

    // Chaines pour tooltip lockable
    TEXT_TOOLTIP_SI_SCELLABLE: 'Sceller cette visite ?',
    TEXT_TOOLTIP_SI_NON_SCELLABLE: 'Vous ne pouvez pas sceller les visites 5 clés ou insolite. Veuillez-vous rapprocher du référent Qualité à la Fédération afin de présenter ce dossier pour examen et validation.',
    TEXT_TOOLTIP_SI_NON_SCELLABLE_INCOMPLETE: 'Finaliser la saisie pour pouvoir sceller cette visite.',
    TEXT_TOOLTIP_SI_NON_SCELLABLE_TYPE_VISITE: 'Les visites de tests ou de conseils ne sont pas scellables.',
    TEXT_TOOLTIP_SI_NON_CONNECTE: 'Cette visite ne peut être scellée car vous êtes hors ligne...',
    TEXT_TOOLTIP_SI_DEJA_SCELLE: 'Cette visite est déjà scellée !',
    TEXT_TOOLTIP_FOR_THEMES: 'Cette visite ne peut être scellée car elle ne sert qu\'aux thèmes.',
    TEXT_TOOLTIP_PAS_DE_CLES: 'Cette visite ne peut être scellée car elle n\'a pas de clé',
    TEXT_TOOLTIP_INSO_PAS_DE_FICHIER: 'Une copie de l’autorisation d’implantation doit obligatoirement être jointe afin de sceller cette visite (déclaration de travaux, permis de construire, attestation sur l’honneur… Une vérification sera également effectué par la Clévacances France',
    TEXT_TOOLTIP_INSO_CRITERE_2_4_OBLIGATOIRE: 'Le critère 2.4 doit être obligatoirement renseigné.',
    TEXT_TOOLTIP_NEW_VISITE: 'Cette visite a été créée en mode déconnecté. Veuillez la quitter et revenir afin de l\'actualiser.',
}

export default Constantes;