import { useEffect, useState } from "react";
import { ListGroup, Badge } from "react-bootstrap";
import { subscribeNextLevelCount } from "../../services/visitesService";

const VisiteSommaireItem = ({ block, isInsolite, current, itemHandler }) => {

    const id = isInsolite ? block.id.substring(5) : block.id;

    const [nextLevelCount, setNextLevelCount] = useState();

    useEffect(() => {

        const onCount = (data) => {
            if(data.id === block.id){
                setNextLevelCount(data.count);
            }
        }

        const unsubscribe = subscribeNextLevelCount(onCount);

        return () => {
            unsubscribe(onCount);
        }
    }, [block?.id]);

    return (
        <ListGroup.Item key={block.id} className={`${current === block.id && 'active'}`}>
            <div onClick={() => itemHandler(block.id)}>
                <div className="title d-flex">
                    <span className="item-badge">
                    {isInsolite ?
                        block.cles > 4 && <span className="badge rounded-pill bg-success"><i className="icon icon-check"></i></span> :
                        !block.calcul && <Badge pill bg="info">{block.cles}</Badge>
                    }</span>
                    {id && <strong className="item-id">{id}</strong>}
                    <span>{block.titre} {nextLevelCount > 0 && <span className="next-level-count">{nextLevelCount}</span>}</span>
                </div>
            </div>
        </ListGroup.Item>
    );
};

export default VisiteSommaireItem;