import { useEffect, useState } from "react";
import { Card, ListGroup, ListGroupItem } from "react-bootstrap";
import { Link } from "react-router-dom";
import { config } from "../../config";
import { dbFetchStore } from "../../services/indexedDBService";
import { getDateLightFromTimestamp } from "../../services/utilities";

const InfosDernieresVisites = () => {

    const [visites, setVisites] = useState([]);
    
    useEffect(() => {

        let isMounted = true;
        let isCancelled = false;

        const loadVisites = () => {
            dbFetchStore('visits')
            .then(data => {
                const lastVisites = data.sort((a, b) => a.date_update - b.date_update).reverse().slice(0,5);
                if(!isCancelled) setVisites(lastVisites);
            })
        }

        if(isMounted){
            loadVisites();
        }

        return () => {
            isCancelled = true;
            isMounted = false;
        }
    }, []);

    const getTypevisite = (visite) => {
        const value = config.visits_types.find(v => v.id === visite.type_visite).name;
        return value;
    }

    return(
        <Card className="card-biens">
            <Card.Body>
                <div className="d-flex justify-content-between">
                    <Card.Title>Vos dernières visites en cours</Card.Title>
                </div>
            </Card.Body>
            <ListGroup variant="flush">
                {visites.map(visite => 
                    <ListGroupItem key={visite.uid}>
                        <div className="d-flex justify-content-between">
                            <div className="title">
                                {visite.type_visite !== 'vstarch' ? 
                                    <Link to={`/visit/${visite.uid}`}>{visite.loc_hlo}</Link> : visite.loc_hlo
                                }
                            </div>
                            <div className="status d-flex">{getTypevisite(visite)}</div>
                            <div className="status d-flex">{getDateLightFromTimestamp(visite.date_update)}</div>
                        </div>
                    </ListGroupItem>
                )}
            </ListGroup>
        </Card>
    )
};

export default InfosDernieresVisites;
