import { Text, View, Font } from '@react-pdf/renderer';
import parse, { domToReact } from 'html-react-parser';

import RobotoRegular from "../../fonts/Roboto-Regular.ttf";
import RobotoBold from "../../fonts/Roboto-Bold.ttf";

Font.register({
    family: 'Roboto',
    format: "truetype",
    src: RobotoRegular
});

Font.register({
    family: 'RobotoBold',
    format: "truetype",
    src: RobotoBold
});

const CompteRenduHtml = ({ html }) => {

    const options = {
        replace: ({ name, children }) => {
            if(name === 'h1'){
                return <Text style={{fontSize: 32, marginBottom: 10}}>{domToReact(children, options)}</Text>;
            }
            if(name === 'h2'){
                return <Text style={{fontSize: 24, marginBottom: 10}}>{domToReact(children, options)}</Text>;
            }
            if(name === 'h3' || name === 'h4'){
                return <Text style={{fontSize: 18, marginBottom: 10}}>{domToReact(children, options)}</Text>;
            }
            if(name === 'p'){
                return <Text style={{fontSize: 12, marginBottom: 10}}>{domToReact(children, options)}</Text>;
            }
            if(name === 'strong'){
                return <Text style={{fontFamily: 'RobotoBold'}}>{domToReact(children, options)}</Text>;
            }
            if(name === 'ul'){
                return <View style={{fontSize: 12, paddingLeft: 20, marginBottom: 10}}>{domToReact(children, options)}</View>;
            }
            if(name === 'li'){
                return <View style={{display: 'flex', width: '100%', marginBottom: 5}}>
                    <Text style={{textIndent: -6, paddingLeft: 6}}>• {domToReact(children, options)}</Text>
                </View>;
            }
        }
    };

    const renderHtml = () => {
        return parse(html, options);
    }

    return(
        <div>
            {renderHtml()}
        </div>
    );
}

export default CompteRenduHtml;