import { Link } from "react-router-dom";
import { getLocationByHLO } from "../../services/locationsService";
import useAutorisations from "../../services/useAutorisations";

const VisiteHeader = ({ visite }) => {

    const bien = getLocationByHLO(visite.loc_hlo);
    const { agr } = bien;
    const {canUpdateVisite} = useAutorisations();

    return(
        <div className="content-header visite-header bg-light border-bottom">
            <div className="d-flex align-items-center justify-content-between mx-3">
                <div className="d-flex gap-3 align-items-center">
                    <h1 className="mb-0">{visite.loc_hlo}</h1>
                    {agr && <span className="fs-4 text-muted">{agr}</span>}
                </div>
                {canUpdateVisite(visite) && <Link className="btn btn-outline-success" to={`/visit-update/${visite.uid}`}>Modifier cette visite</Link>}
            </div>
        </div>
    );

}
export default VisiteHeader;
