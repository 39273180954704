import { config } from "../config";
import { getCurrentUser } from "./usersService";

const loadFilesFromServer = async (uid) => {

    if( getCurrentUser() )
    {
        const { email, mdp } = getCurrentUser();
        const pass = encodeURIComponent(mdp);
        const apiGetVisitsFiles = config.apiData.domain+config.apiData.entities.visits.visit_files_get+`&login=${email}&pass=${pass}&visit_uid=${uid}`;

        return await fetch(apiGetVisitsFiles, { method: 'GET' })
            .then(response => response.json())
            .then(data => {
                return data.files;
            });
    }else{
        return;
    }
}

export {
    loadFilesFromServer
}
