import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import FormElement from "./formElement";

const H3 = ({ block }) => {

    const renderAide = (props) => {
        return (
            <Popover id="aide" className="help-popover" {...props}>
                <Popover.Body>
                    <div dangerouslySetInnerHTML={{__html: block.aide}}></div>
                </Popover.Body>
            </Popover>
        );
    }

    return (
        <div className="group">
            <h3 className="text-secondary fs-6 mb-3" id={block.id}>
                <div className="d-flex gap-2">
                    <span>{block.id}</span>
                    {block.aide ? <OverlayTrigger overlay={renderAide}>
                            <i className="icon icon-info fs-5 text-secondary"></i>
                        </OverlayTrigger> : ''
                    }
                    <span>{block.titre}</span>
                </div>
            </h3>
            {block.elements ? block.elements.map((element, i) => <FormElement key={i} block={element} />) : null}
        </div>
    );
}

export default H3;