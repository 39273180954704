import { useEffect, useState } from "react";

const Image = ({ block }) => {

    const { nom } = block;
    const [src, setSrc] = useState(null);

    useEffect(() => {
        import(`../../images/grilles/${nom}`).then(result => {
            setSrc(result.default)
        });
    }, [nom]);


    if(!src) return <div></div>;

    return (
            <img src={src} title={nom} alt={nom} className="img-fluid" />
    );
}

export default Image;
