import Dexie from 'dexie';
import { config } from '../config.js';

// La base de données
let db;

/* MÉTHODES PUBLIQUES */

const initDB = async () => {

    db = new Dexie(config.dbNomade.name);
    db.version(config.dbNomade.version).stores(config.dbNomade.stores);
    await db.open().catch(function (e) {
        console.error("Open failed: " + e);
    });
}

const dbFetchStore = async(table) => {

    return await db[table].toArray()
    .then(data => {
        return data;
    });

}

const dbGet = async (table, id) => {
    const result = await db[table].get(id);
    return result;
}

const dbGetByParams = async (table, params) => {
    const result = await db[table].get(params);
    return result;
}

const dbFetchFiltered = async (table, filterName, filterValue) => {
    const result = await db[table].where(filterName).equals(filterValue).toArray();
    return result;
}

const dbFetchFilteredNotEqual = async (table, filterName, filterValue) => {
    const result = await db[table].where(filterName).notEqual(filterValue).toArray();
    return result;
}

const dbDeleteFiltered = async (table, filterName, filterValue) => {
    return await db[table].where(filterName).equals(filterValue).delete().then(count => {
        return count;
    });
}

const dbAdd = async (table, data) => {
    const id = await db[table].put(data);
    return id;
}

const dbBulkAdd = async (table, data) => {
    if(db){
        return await db[table].bulkPut(data).then((lastkey) => {
            return dbFetchStore(table);
        }).catch(Dexie.BulkError, (event) => {
            console.warn(`Erreur lors de l'enregistrement dans ${table} : ${event.failures.length} items n'ont pas été enregistrés.`);
        });
    } else {
        console.warn('Pas de base connectée');
    }
}

const dbUpdate = async (table, id, newdata) => {
    return await db[table].update(id, newdata)
    .then(updated => {
        if(updated) console.log('Mise à jour effectuée');
        else console.log('Mise à jour non effectuée.');
        return updated;
    }).catch(Dexie.DexieError, (event) => {
        console.warn(`Erreur lors de la mise à jour de ${table}.`);
    });
}

const dbDelete = async (table, id) => {
    return await db[table].delete(id).then(result => {
        return id;
    }).catch(error => {
        console.log(error);
        return error;
    });
}

export { db, initDB, dbFetchStore, dbGet, dbGetByParams, dbAdd, dbUpdate, dbDelete, dbBulkAdd, dbFetchFiltered, dbFetchFilteredNotEqual, dbDeleteFiltered }
