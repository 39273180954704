import React, { useContext } from "react";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import { VisiteContext } from "../../services/visiteContext";

const InputInteger = ({block}) => {

    const { handleChange } = useContext(VisiteContext);

    return (
        <div className="mb-3">
            <InputGroup>
                {block.prefixe && <InputGroup.Text>{block.prefixe}</InputGroup.Text>}
                <FormControl type="number" step="1" value={block.valeur} onChange={event => handleChange(block.id, event)} />
                {block.suffixe && <InputGroup.Text>{block.suffixe}</InputGroup.Text>}
            </InputGroup>
            {block.aide && <Form.Text muted>{block.aide}</Form.Text>}
        </div>
    );

};

export default InputInteger;