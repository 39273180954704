import React, { useContext } from "react";
import { Badge, OverlayTrigger, Popover } from "react-bootstrap";
import useNextLevel from "../../services/useNextLevel";
import { VisiteContext } from "../../services/visiteContext";

const Checkbox = ({ block }) => {

    const { handleChange } = useContext(VisiteContext);
    const { isNextLevel } = useNextLevel({block});

    const isTheme = block.id.indexOf('th_') !== -1;
    const isInsolite = block.id.startsWith('inso-');
    const blockId = isInsolite ? block.id.substring(block.id.indexOf('-') + 1, block.id.length) : block.id;

    block.cles = Number.isInteger(block.cles) ? [block.cles] : block.cles;

    const renderAide = (props) => {
        return(
            <Popover id="aide" className="help-popover" {...props}>
                <Popover.Body>
                    <div dangerouslySetInnerHTML={{__html: block.aide}}></div>
                </Popover.Body>
            </Popover>
        );
    }

    const clesLabel = block.cles ? (block.cles.length > 1) ? `${block.cles[0]} ➝ ${block.cles[block.cles.length - 1]}` : `${block.cles[0]}` : null;

    return (
        <div className={`form-check form-switch visit-form-check${isNextLevel ? " next" : ""}`} data-cles={`${block.cles}`} data-type={`${block.type}`}>
            <input className="form-check-input" type="checkbox" checked={block.valeur || false} id={block.id} name={block.nom} onChange={event => handleChange(block.id, event)} />
            <label className={`form-check-label ${isTheme && "theme-label"}`} htmlFor={block.id}>
                {!isTheme && <span className="label-id">{blockId}</span>}
                {(!isTheme && !isInsolite && clesLabel) && <span><Badge bg="secondary" pill>{clesLabel} <i className="icon icon-cle"></i></Badge></span>}
                {block.aide ? <OverlayTrigger overlay={renderAide} placement="auto">
                    <i className="icon icon-info fs-5 text-secondary"></i>
                    </OverlayTrigger> : <i className="spacer"></i>
                }
                {block.facultatif ? <i className="icon-facultatif fs-5 text-secondary"></i> :'' }
                <span className="titre">{block.titre}</span>
            </label>
        </div>
    );
}

export default Checkbox;
