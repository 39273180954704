import { Fragment } from "react";
import { Accordion, Badge, Card, ListGroup } from "react-bootstrap"

const CardVisitContent = ({ visite }) => {

    const contents = visite.contents.find(content => !content.group_visite.startsWith('th_'));
    const isInsolite = visite.type_bien === 'inso';

    const renderClesFromArray = (cles) => {
        return Array.isArray(cles) ? cles.length > 1 ? `${cles[0]} ➝ ${cles[cles.length - 1]}` : `${cles[0]}` : cles;
    }

    const renderElements = (element, selection=null) => {
        switch(element.type){
            case 'decimal':
                return (element.valeur !== undefined && element.valeur.length !== 0) && <ListGroup.Item>{element.prefixe} : {element.valeur} {element.suffixe}</ListGroup.Item>;
            case 'text':
                return <ListGroup.Item>{element.titre} : {element.valeur}</ListGroup.Item>
            case 'checkbox':
                const checked = selection ? element.cles : element.valeur || false;
                return <>
                    <ListGroup.Item data-id={element.id}>
                        <div className={`form-check form-switch visit-form-check`}>
                            <input className="form-check-input" type="checkbox" checked={checked} onChange={() => false} disabled />
                            <label className={`form-check-label d-flex gap-2`}>
                                <span className="label-id text-nowrap">{getIDLabel(element.id)}</span>
                                {(element.cles !== undefined && element.cles !== 0 && !isInsolite) && <span><Badge bg="secondary" pill>{renderClesFromArray(element.cles)} <i className="icon icon-cle"></i></Badge></span>}
                                <span className="titre">{element.titre}</span>
                            </label>
                        </div>
                    </ListGroup.Item>
                    {element.elements && <ListGroup variant="flush" data-id={element.id}>
                        {element.elements.map(child => <Fragment key={child.id}>{renderElements(child)}</Fragment>)}
                    </ListGroup>}
                </>
            case 'checkboxgroup':
                return <>
                    {element.options && <ListGroup variant="flush" data-id={element.id}>
                        {element.options.map(child => <Fragment key={child.id}>{renderElements(child, true)}</Fragment>)}
                    </ListGroup>}
                </>
            case 'radio':
                return <>
                    <ListGroup.Item data-id={element.id}>
                        <div className={`form-check visit-form-check`}>
                            <input className="form-check-input" type="radio" checked={element.valeur === selection} disabled />
                            <label className={`form-check-label d-flex gap-2`}>
                                <span className="label-id text-nowrap">{getIDLabel(element.id)}</span>
                                {(element.cles !== undefined && Number.isInteger(element.cles) && element.cles !== 0 && !isInsolite) ? <span><Badge bg="secondary" pill>{element.cles} <i className="icon icon-cle"></i></Badge></span> : null}
                                <span className="titre">{element.valeur}</span>
                            </label>
                        </div>
                    </ListGroup.Item>
                    {element.elements && <ListGroup variant="flush" data-id={element.id} className={`${element.type} border-bottom`}>
                        {element.elements.map(child => <Fragment key={child.id}>{renderElements(child)}</Fragment>)}
                    </ListGroup>}
                </>;
            case 'radiogroup':
                return <>
                    <ListGroup.Item data-id={element.id}>
                    {element.valeur ? <div className={`form-check visit-form-check`}>
                                <input className="form-check-input" type="radio" checked={element.selected} disabled />
                                <label className={`form-check-label d-flex gap-2`}>
                                    <span className="label-id text-nowrap">{getIDLabel(element.id)}</span>
                                    {(element.cles !== undefined && Number.isInteger(element.cles) && element.cles !== 0 && !isInsolite) ? <span><Badge bg="secondary" pill>{element.cles} <i className="icon icon-cle"></i></Badge></span> : null}
                                    <span className="titre">{element.titre} {element.valeur && <strong>{element.valeur}</strong>}</span>
                                </label>
                            </div> : <div>{element.id}<span className="text-muted">{element.valeur} – Aucune sélection</span></div>}
                    </ListGroup.Item>
                    {element.elements && <ListGroup variant="flush" data-id={element.id} className={`${element.type} border-bottom`}>
                        {element.elements.map(child => <Fragment key={child.id}>{renderElements(child, element.valeur)}</Fragment>)}
                    </ListGroup>}
                </>;
            case 'h2':
            case 'h3':
                return <><ListGroup.Item as={element.type} className="fs-6">
                    {getIDLabel(element.id)}. {element.titre}
                </ListGroup.Item>
                {element.elements && <ListGroup variant="flush" data-id={element.id}>
                    {element.elements.map(child => <Fragment key={child.id}>{renderElements(child)}</Fragment>)}
                </ListGroup>}
                </>;
            default:
                return null;
        }
    }

    const getIDLabel = (id) => {
        let nextId = id;
        if(isInsolite){
            nextId = id.substring(id.indexOf('-') + 1, id.length);
        }
        return nextId;
    }

    const activeKeys = contents.content.reduce((acc, current) => {
        acc.push(current.id);
        return acc;
    }, []);

    return (
        <Card>
            <Card.Header>
                <Card.Title className="mb-0">Contenu de la visite</Card.Title>
            </Card.Header>
            <Accordion defaultActiveKey={activeKeys} flush alwaysOpen>
                {contents.content.map(content =>
                    <Fragment key={content.id}>
                        <Accordion.Item eventKey={content.id}>
                            <Accordion.Header as="h1">
                                <div className="d-flex align-items-center gap-2">
                                    <span>
                                        {getIDLabel(content.id)}. {content.titre} {content.titreperso && <em className="fs-6 mb-0">({content.titreperso})</em>}
                                    </span>
                                    {(content.id !== '0' && !isInsolite) && <span><Badge bg="info" pill>{content.cles} <i className="icon icon-cle"></i></Badge></span>}
                                </div>
                            </Accordion.Header>
                            <Accordion.Body className="p-0">
                                {(content.commentaire && content.commentaire.length > 0) && <div className="p-3 bg-light border-bottom"><em>{content.commentaire}</em></div>}
                                <ListGroup variant="flush">
                                    {content.elements.map(element => <Fragment key={element.id}>{renderElements(element)}</Fragment>)}
                                </ListGroup>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Fragment>
                )}
            </Accordion>
        </Card>
    );
};

export default CardVisitContent;