import { Modal } from "react-bootstrap";
import VisiteItem from "./visites/visiteItem";

const ModalVisites = (props) => {

    return(
        <Modal {...props} size="xl" centered>
            <Modal.Header closeButton>
                <Modal.Title>{`${props.visites.length} ${props.visites.length > 1 ? "visites" : "visite"}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.visites.map((visite, index) => <VisiteItem key={`${visite.uid}-${index}`} visite={visite} />)}
            </Modal.Body>
        </Modal>
    )
}

export default ModalVisites;