import React, { useContext } from "react";
import { VisiteContext } from "../../services/visiteContext";

const Input = ({ block }) => {

    const { handleChange } = useContext(VisiteContext);

    return (
        <div className="mb-3">
            <label htmlFor={block.id} className="form-label">{block.titre}</label>
            {block.aide ? 
                <>
                    <input type={block.type} className="form-control" id={block.id} value={block.valeur || ''} aria-describedby={"help-" + block.id}  onChange={event => handleChange(block.id, event)} />
                    <div id={"help-" + block.id} className="form-text" dangerouslySetInnerHTML={{__html: block.aide}} />
                </> : 
                <input type={block.type} className="form-control" id={block.id} value={block.valeur || ''} onChange={event => handleChange(block.id, event)} />
            }
        </div>
    );
}

export default Input;