import { config } from "../config";
import { dbGet, dbAdd } from "./indexedDBService";
import { getCurrentUser } from "./usersService";
import { getTimestamp } from "./utilities";

const noLoggedNodes = ["aide", "type", "version"];

const useDuplicateVisite = () => {

    /**
     * Crée une copie de la visite dont l'uid correspond,
     * l'associe au bien dont hlo correspond,
     * la compare avec la dernière version du schéma,
     * et si besoin, met à jour la grille de la copie.
     *
     * @param {int} uid L'identifiant de la visite source.
     * @param {String} hlo L'identifiant du bien associé à cette visite.
     * @param {String} type Le type de visite.
     * @returns La copie de la visite et le tableau des différences ou false si erreur.
     */
    const getDiffsAndCopy = async (uid, hlo, type) => {

        // Créer une copie de la visite originale à partir la base de données locale.
        const copy = await createCopy(uid, hlo, type);

        if(copy){

            if(copy.contents.length){

                // Trouver le contenu principal de la source.
                const mainCopyContent = copy.contents[0];

                if(mainCopyContent){

                    // Grille de la source
                    const grilleSource = mainCopyContent.content;

                    // Chargement de la grille modèle
                    const lastVersion = config.group_visit[copy.type_bien].version;
                    const grilleModele = await import(`../form-schema/${copy.type_bien}-schema.${lastVersion}.json`);

                    // Tableau pour stoquer les différences.
                    const differences = { newdiffs: [], deletediffs: [], updatediffs: [], count: 0};

                    // Boucle récursive qui trouve les différences et met à jour la copie
                    compareAndUpdate(grilleSource, grilleModele.default, differences);

                    // Suppression des cles calculées si difference
                    Object.values(differences).forEach(diff => {
                        if(diff.length){
                            copy.cles_calcul = 0;
                        }
                    });

                    return {
                        visite: copy,
                        diffs: differences
                    };
                }

            }

        }

        return false;

    }

    /**
     * Crée une copie temporaire (non sauvegardée) de la visite source.
     *
     * @param {String} uid L'identifiant de la la visite source.
     * @param {String} hlo Le hlo du bien associé à cette visite.
     * @param {String} type Le type de visite.
     * @returns La copie de la visite source avec un identifiant temporaire
     */
    const createCopy = async (uid, hlo, type) => {

        /** Charger la visite à partir de la base de données locale */
        const copy = await dbGet('visits', uid);

        if(copy){

            copy.uid = `fk-${getTimestamp()}`; // Créer un identifiant temporaire
            copy.loc_hlo = hlo; // Mise à jour du loc_hlo
            copy.cles = 0; // suppression de la clé estimée
            copy.scelle = null; //les visites dupliquées ne sont plus sclellées

            // Mise à jour des dates
            const now = getTimestamp();
            copy.date_update = now;
            copy.date_create = now;
            copy.date_visite = now;

            // Mise à jour du technicien
            const user = getCurrentUser();
            copy.user_uid = user.uid;
            copy.user_email = user.email;
            copy.user_tel = user.tel;
            copy.user_gender = user.gender;
            copy.user_lastname = user.lastname;
            copy.user_firstname = user.firstname;
            copy.user_adress1 = user.adress1;
            copy.user_adress2 = user.adress2;
            copy.user_adress3 = user.adress3;
            copy.user_commune = user.commune;
            copy.user_postal_code = user.postal_code;

            /** Charger le contenu original à partir la base de données locale */
            const copyContent = await dbGet('visits_content', [uid, type]);

            if(copyContent){
                copyContent.uid = copy.uid;
                copy.contents = [copyContent];
            }
            return copy;
        }
        return null;
    }

    /**
     * Fonction récursive qui liste les différences
     * entre le modele et la source
     *
     * @param {Array} source Les nœuds originaux.
     * @param {Array} modele Les noeuds du modèle
     * @param {Array} differences, tableau à remplir avec les différences trouvées
     */
    const compareAndUpdate = (source, modele, differences) => {

        /** Liste des noeuds à supprimer de la source */
        const toDelete = [];

        /** Liste des noeuds à ajouter à la source */
        const toAdd = [];

        /** Rechercher les nouveaux noeuds du modèle à ajouter à la source  */
        modele.forEach(node => {

            const noeudSource = source.find(critere => critere.id === node.id);

            /** Si le noeud n'existe pas dans la source, l'ajouter */
            if(!noeudSource){
                toAdd.push({...node});
            }

        });

        /** Rechercher les sources qui n'existent plus dans le modèle */
        source.forEach(node => {

            const noeudModele = modele.find(critere => critere.id === node.id);

            /** Si le noeud n'existe pas dans le modèle et qu'il ne s'agit pas d'un bloc copié, le supprimer */
            if(!noeudModele && node.id.indexOf('~') === -1){
                toDelete.push({...node});
            }

        });

        /** Effectuer les suppressions */
        toDelete.forEach(node => {
            addDifference(differences, "delete", node);
            const index = source.findIndex(item => item.id === node.id);
            source.splice(index, 1);
        });

        /** Effectuer les ajouts */
        toAdd.forEach(node => {
            addDifference(differences, "new", node);
            const index = modele.findIndex(item => item.id === node.id);
            source.splice(index, 0, node);
        });

        /** Ordonner les noeuds selon les ids */
        source.sort((a, b) => {
            const va = a.id.split(".");
            const vb = b.id.split(".");
            let i = 0;
            while (i < va.length && i < vb.length && (+va[i]) === (+vb[i])){
                i++;
            }
            return (+va[i]) - (+vb[i]);
        });

        /** Finalement, comparer les deux grilles */
        modele.forEach(node => {

            const noeudSource = source.find(critere => critere.id === node.id);
            const noeudModele = modele.find(critere => critere.id === node.id);

            /** Si le noeud existe dans les deux grilles, les comparer */
            if(noeudSource && noeudModele){

                const newDiff = compareNodes(noeudSource, noeudModele);

                if(newDiff) {

                    /** Enregistrer la différence */
                    differences.updatediffs.push(newDiff);
                    differences.count += 1;

                    /** Mettre à jour le noeud source */
                    updateSourceNode(noeudSource, noeudModele, newDiff);

                }

                /** si le noeud a un tableau d'enfants comparer récursivement */
                if(noeudModele.elements && noeudSource.elements) {
                    compareAndUpdate(noeudSource.elements, noeudModele.elements, differences);
                }

            }
        });

    }

    /**
     *
     * @param {Array} differences Le tableau des différences.
     * @param {String} type Le type de différence (new, delete, update).
     * @param {Object} node Le noeud qui fait l'objet d'une différence.
     */
    const addDifference = (differences, type, node) => {

        const titre = node.titre ? `${node.titre}` : "";
        let comment = "";

        if(type === "new"){
            comment = ['h1', 'h2', 'h3'].includes(node.type) ? "Nouveau groupe de critères" : "Nouveau critère";

            // Enregistrer la différence
            differences.newdiffs.push({
                diff: type,
                comment: `<b class="text-info">${comment} – ${node.id} :</b> ${titre}.`,
                node: node
            });
        }

        if(type === "delete"){
            comment = ['h1', 'h2', 'h3'].includes(node.type) ? "Groupe de critères supprimé" : "Critère supprimé";

            // Enregistrer la différence
            differences.deletediffs.push({
                diff: type,
                comment: `<b class="text-danger">${comment} – ${node.id} ${titre ? " :" : ""}</b> ${titre}`,
                node: node
            });
        }

        if(type === "update"){
            comment = ['h1', 'h2', 'h3'].includes(node.type) ? "Groupe de critères supprimé" : "Critère supprimé";

            // Enregistrer la différence
            differences.updatediffs.push({
                diff: type,
                comment: `${comment} – ${node.id} ${titre ? " :" : ""} ${titre}`,
                node: node
            });
        }

        differences.count += 1;

    }

    /**
     * Compare deux noeuds en se basant
     * sur le numéro de version.
     *
     * @param {Object} source Le nœud de la source
     * @param {Object} modele Le nœud correspondant du modèle
     * @returns un objet représentant la différence ou null si pas de différence.
     */
    const compareNodes = (source, modele) => {

        const titreSource = source.titre ? `${source.titre}` : "";

        if(modele.version && (!source.version || (source.version !== modele.version))){
            return {
                diff: "update",
                comment: `<b class="text-success">Critère mis à jour – ${source.id} ${titreSource ? ":" : ""}</b> ${titreSource} <span class="badge bg-warning text-uppercase"></span>`,
                node: modele
            };
        }

        return null;
    }

    /**
     * Met à jour les attributs de noeudSource selon noeudModele
     *
     * @param {Object} noeudSource Le nœud source à mettre à jour
     * @param {Object} noeudModele Le nœud du modèle
     * @param {Object} diff Objet représentant la différence
     */
    const updateSourceNode = (noeudSource, noeudModele, diff) => {

        /** Mettre à jour ou ajoute les attributs du noeud souce */
        Object.entries(noeudModele).forEach(([key, value]) => {

            if(key !== 'elements'){

                /** Si l'attribut n'existe pas dans la source, l'ajouter */
                if(!noeudSource[key]){
                    noeudSource[key] = noeudModele[key];
                    if(noLoggedNodes.indexOf(key) === -1){
                        diff.comment += `<br>Nouvel attribut <i>« ${key} »</i>.`;
                    }
                }

                /** Si un titre a été mis à jour */
                if(['h1', 'h2', 'h3'].includes(value) && noeudSource[key] !== noeudModele[key]){
                    noeudSource[key] = noeudModele[key];
                    if(noLoggedNodes.indexOf(key) === -1){
                        diff.comment += `<br>Mise à jour du titre ${noeudModele.id}.`;
                    }
                }

                /** Si la valeur du noeud modèle est différente */
                if(Array.isArray(value)){
                    if(value.toString() !== noeudSource[key].toString()){
                        noeudSource[key] = value;
                        if(noLoggedNodes.indexOf(key) === -1){
                            diff.comment += `<br>Nouvelle valeur pour l'attribut : <i>« ${key} ».</i>`;
                        }
                    }
                } else {
                    if(value !== noeudSource[key]){
                        noeudSource[key] = value;
                        if(noLoggedNodes.indexOf(key) === -1){
                            diff.comment += `<br>Nouvelle valeur pour l'attribut : <i>« ${key} ».</i>`;
                        }
                    }
                }
            } else {
                /** Si la source n'a pas d'enfants et que le modele oui, les ajouter à la source */
                if(!noeudSource[key] && noeudModele[key]){
                    noeudSource[key] = noeudModele[key];
                    if(noLoggedNodes.indexOf(key) === -1){
                        diff.comment += `<br>Nouveaux critères « ${key} ».`;
                    }
                }
            }
        });

        /** Supprimer du noeud source les attributs inexistants dans le noeud modèle */
        Object.entries(noeudSource).forEach(([key, value]) => {
            if(noeudModele[key] === undefined && (key !== 'valeur' && key !== 'commentaire')){
                if(noLoggedNodes.indexOf(key) === -1){
                    diff.comment += `<br>Suppression de l'attribut <i>« ${key} »</i>.`;
                }
                delete noeudSource[key];
            }
        });
    }

    /**
     * Enregistre la copie sur le serveur (si en ligne) et dans la base de données locale.
     *
     * @param {Object} copy La visite à enregistrer.
     * @returns {Boolean} true si tout s'est bien passé ou false si erreur.
     */
    const saveCopy = async (copy) => {

        /** Enregistrement de la visite sur le serveur pour obtenir un uid */
        if(window.navigator.onLine){
            const uid = await saveCopyToServer(copy);
            copy.uid = uid;
            if(copy.contents){
                copy.contents.forEach(content => {
                    content.uid = uid;
                });
            }
        }

        /** Enregistrement des contenus de la copie dans la base de données locale */
        copy.contents.forEach(async content => {
            const newContents = await dbAdd('visits_content', content);
            if(!newContents) {
                return false;
            }
        });

        /** Enregistrement de la copie dans la base de données locale */
        delete copy.contents;
        const newVisite = await dbAdd('visits', copy);
        if(newVisite){
            return true;
        }

        return false;
    }

    /**
     * Enregistre la visite copiée sur le serveur.
     *
     * @param {Object} copy La visite à enregsitrer sur le serveur.
     * @returns l'uid de la visite ou false si une erreur est survenue.
     */
    const saveCopyToServer = async (copy) => {

        if(!copy) return;

        const currentUser = getCurrentUser();
        const api_pass = encodeURIComponent(currentUser.mdp);
        const host = config.apiData.domain;

        const endpoint = `${host}${config.apiData.entities.visits.new}&login=${currentUser.email}&pass=${api_pass}&user_uid=${currentUser.uid}&date_create=${copy.date_create}&date_visite=${copy.date_visite}&version=${copy.version}&type_visite=${copy.type_visite}&type_bien=${copy.type_bien}&loc_hlo=${copy.loc_hlo}&user_email=${currentUser.email}&user_tel=${currentUser.tel}&user_lastname=${currentUser.lastname}&user_firstname=${currentUser.firstname}&user_adress1=${currentUser.adress1}&user_adress2=${currentUser.adress2}&user_adress3=${currentUser.adress3}&user_commune=${currentUser.commune}&user_postal_code=${currentUser.postal_code}`;

        const requestBody = new FormData();
        requestBody.append("contents", JSON.stringify(copy.contents));
        if(copy.cpt_rendu){
            requestBody.append('cpt_rendu', JSON.stringify(copy.cpt_rendu));
        }

        const response = await fetch(endpoint, {
            method: 'POST',
            body: requestBody
        });

        if(response.ok){
            const data = await response.json();
            if(!data.success) {
                console.warn("ERREUR LORS DE L'ENREGISTREMENT DE LA COPIE : ", data.message);
                return false;
            }
            return parseInt(data.uid);
        } else {
            console.warn("ERREUR LORS DE L'ENREGISTREMENT DE LA COPIE !");
            return false;
        }
    }

    return { getDiffsAndCopy, saveCopy };

}

export default useDuplicateVisite;
