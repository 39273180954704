import { useContext } from "react";
import { AccordionContext, useAccordionButton } from "react-bootstrap";

const AccordionButton = ({ children, eventKey, callback }) => {

    const { activeEventKey } = useContext(AccordionContext);

    const onItemClick = useAccordionButton(eventKey, () => {
        return callback && callback(eventKey)
    });

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <button type="button" className={`sticky-top accordion-button${isCurrentEventKey ? "" : " collapsed"}`} onClick={onItemClick}>
            {children}
        </button>
    )
};

export default AccordionButton;