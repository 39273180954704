import { getCurrentUser, getUserById } from "./usersService";
import {config} from '../config.js';
import { useEffect } from 'react';
import { subscribeIsOnline } from "./isonlineService";
import { useState } from 'react';

const useAutorisations = () => {

    const [isOnline, setIsOnline] = useState(window.navigator.onLine);
    const user = getCurrentUser();

    useEffect(() => {

        const onOnlineChange = (status) => {
            setIsOnline(status);
        }

        const unsubscribeOnline = subscribeIsOnline(onOnlineChange);

        return () => {
            unsubscribeOnline(onOnlineChange);
        }
    }, []);

    const canUpdateVisite = (visite) => {

        const userCreate = getUserById(visite.user_uid);
        const isAdmin = parseInt(user.level) > 70;
        const isAuthor = userCreate ? user.uid === userCreate.uid : true;

        const now = new Date();
        const date = new Date(visite.date_visite * 1000);
        const year = new Date(date).getFullYear();
        const month = new Date(date).getMonth();
        const day = new Date(date).getDate();
        const dateMax = new Date(year, month + parseInt(config.periodUpdate.mois), day);
        const isInTime = dateMax >= now;

        if(userCreate){

            if(isOnline && isNaN(visite.uid)){
                return false;
            }

            if( isAdmin ){
                return true;
            }

            if(!visite.scelle && isAuthor){
                return true;
            }

            if(visite.scelle && isAuthor && isInTime){
                return true;
            }

            return false;

        } else {
            return false;
        }

    }

    const canDeleteVisite = (visite) => {

        const userCreate = getUserById(visite.user_uid);
        const isAdmin = parseInt(user.level) > 70;
        const isAuthor = userCreate ? user.uid === userCreate.uid : true;

        if(!isOnline) return false;
        if(isAdmin) return true;
        if(visite.scelle) return false;
        if(!isAuthor) return false;

        return true;
    }

    return { canUpdateVisite, canDeleteVisite }
}

export default useAutorisations;
