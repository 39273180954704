import { validateTheme } from "../services/visitesService";
import { dbAdd, dbDelete } from "./indexedDBService";
import _ from "lodash";

const useTheme = () => {

    const updateTheme = (theme, blockid, event) => {

        const nextContents = [...theme.content];

        const updateValues = (block => {

            const { type, id } = block;

            if (blockid === id) {
                switch (type) {
                    case 'radio':
                    case 'checkbox':
                        block['valeur'] = event.target.checked;
                        break;
                    case 'checkboxgroup':
                        break;
                    case 'radiogroup':
                        break;
                    case 'text':
                        block['valeur'] = event.target.value;
                        break;
                    case 'h1':
                    case 'div':
                        if(event.target.type === "text"){
                            block["titreperso"] = event.target.value;
                        } else {
                            block["commentaire"] = event.target.value;
                        }
                        break;
                    default:
                        block['valeur'] = event.target.value;
                        break;
                }
            } else {
                if(block.elements){
                    block.elements.forEach(element => updateValues(element));
                }
            }

        });

        nextContents.forEach(block => updateValues(block));

        const isValide = validateTheme(nextContents);

        const updatedTheme = {
            ...theme,
            content: nextContents,
            cles: isValide
        };

        return updatedTheme;
    }

    const saveTheme = async (theme) => {
        return await dbAdd('visits_content', theme)
        .then(id => {
            return id;
        })
    }

    const loadTheme = async (theme, visiteUid) => {
        return await import("../form-schema/" + theme.id + "-schema." + theme.version + ".json")
        .then(result => {
            const nextContent = _.cloneDeep(result.default);
            const nextTheme = {
                uid: visiteUid, 
                content: nextContent, 
                group_visite: theme.id, 
                valide: false, 
                validable: theme.validable, 
                label_lie: theme.label_lie, 
                label_lie2: theme.label_lie2 
            };
            return {...nextTheme};
        });
    }

    const deleteThemeFromDB = async (theme) => {
        return await dbDelete('visits_content', [theme.uid, theme.group_visite]);
    }

    return { updateTheme, saveTheme, loadTheme, deleteThemeFromDB };
}

export default useTheme;
