import { useEffect, useState } from "react";
import { config } from "../config";
import { getBiens, getLocationByHLO } from "./locationsService";
import { getAllVisites } from "./visitesService";

const useBiens = () => {

    // Liste des biens
    const biens = getBiens();

    // Liste des visites
    const [visites, setVisites] = useState([]);

    // Liste des biens sans date_visite
    const biensSansDateVisite = biens.filter(bien => bien.date_visite === null);

    // Liste des biens à revisiter dans 6 mois
    const [biensRefaire6Mois, setBiensRefaire6Mois] = useState([]);
    // Liste des biens à revisiter dans 5 ans
    const [biensRefaire5Ans, setBiensRefaire5Ans] = useState([]);

    useEffect(() => {

        getAllVisites().then(data => {
            const nextData = data.filter(visite => visite.type_visite === 'vst' || visite.type_visite === 'vstre' || visite.type_visite === 'vstarch');
            setVisites(nextData);
        });

        if(biens && biens.length){

            const nextBiensRefaire6Mois = biens.filter(bien => {

                if(bien && bien.loc_hlo && bien.date_visite){
                    const dateVisite = bien.date_visite * 1000;
                    const aujourdhuiMoinsQuatreAns = new Date().setMonth(new Date().getMonth() - 60);
                    const aujourdhuiMoinsTroisAnsEtDemi = new Date().setMonth(new Date().getMonth() - 54);
                    return (dateVisite > aujourdhuiMoinsQuatreAns) && (dateVisite < aujourdhuiMoinsTroisAnsEtDemi);
                }

                return false;
            });

            setBiensRefaire6Mois(nextBiensRefaire6Mois);

            const nextBiensRefaire5Ans = biens.filter(bien => {

                if(bien && bien.loc_hlo && bien.date_visite){
                    const dateVisite = bien.date_visite * 1000;
                    const nextDateVisite = new Date(dateVisite).setFullYear(new Date(dateVisite).getFullYear() + 5);
                    const now = new Date().getTime();
                    return nextDateVisite <= now;
                }

                return false;
            });

            setBiensRefaire5Ans(nextBiensRefaire5Ans);
        }

        const nextBiensSansAgrement = biens.filter(bien => {
            return ( ! bien.agr );
        });
        setBienSansAgrement(nextBiensSansAgrement);

    }, [biens]);

    // Liste des biens avec visite
    const [biensAvecVisite, setBiensAvecVisites] = useState([]);

    // Liste des visites scellees et non scellees
    const [visitesScellees, setVisiteScellees] = useState([]);
    const [visitesNonScellees, setVisitesNonScellees] = useState([]);
    const [visitesNonScelleesActifs, setVisitesNonScelleesActifs] = useState([]);
    const [visitesNonScelleesInactifs, setVisitesNonScelleesInactifs] = useState([]);

    useEffect(() => {

        if(visites.length){

            const nextVisitesScellees = visites.filter(visite => visite.scelle);
            setVisiteScellees(nextVisitesScellees);

            const nextVisitesNonScellees = visites.filter(visite => !Number.isInteger(visite.scelle) && visite.scelle !== true && visite.type_visite !== 'vstarch');
            setVisitesNonScellees(nextVisitesNonScellees);

            const nextBiensAvecVisite = [];
            visites.forEach(visite => {
                const bien = getLocationByHLO(visite.loc_hlo);
                if(nextBiensAvecVisite.indexOf(bien) === -1){
                    nextBiensAvecVisite.push(bien);
                }
            });
            setBiensAvecVisites(nextBiensAvecVisite);

            const nextVisitesNonScelleesActifs = nextVisitesNonScellees.filter(visite => {
                const bien = getLocationByHLO(visite.loc_hlo);
                return bien && bien.state === "actif";
            });
            setVisitesNonScelleesActifs(nextVisitesNonScelleesActifs);

            const nextVisitesNonScelleesInactifs = nextVisitesNonScellees.filter(visite => {
                const bien = getLocationByHLO(visite.loc_hlo);
                return bien && bien.state === "inactif";
            });
            setVisitesNonScelleesInactifs(nextVisitesNonScelleesInactifs);
        }

    }, [visites]);

    // Liste des hlo des visites scellees
    const [hloVisitesScellees, setHloVisitesScellees] = useState([]);
    useEffect(() => {

        if(visitesScellees && visitesScellees.length){

            const nextHlo = visitesScellees.map(visite => {
                return visite.loc_hlo;
            });

            // Suppression des éventuelles valeurs dupliquées
            nextHlo.filter((hlo, index) => hloVisitesScellees.indexOf(hlo) === index);

            setHloVisitesScellees(nextHlo);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visitesScellees]);

    // Liste des biens avec visites non scellées
    const [biensAvecVisiteNonScellee, setBiensAvecVisiteNonScellee] = useState([]);
    // Liste des biens avec visites scellées
    const [biensAvecVisiteScellee, setBiensAvecVisiteScellee] = useState([]);
    // Liste des biens sans visite
    const [biensSansVisite, setBiensSansVisite] = useState([]);
    // Liste des biens sans agrément
    const [biensSansAgrement, setBienSansAgrement] = useState([]);
    // Liste des biens dont visite scellée et date visite > 3,5 années
    const [biensARevisiter, setBiensARevisiter] = useState([]);

    useEffect(() => {

        if(biensAvecVisite && biensAvecVisite.length){

            const nextBiensAvecVisiteNonScellee = biensAvecVisite.filter(bien => {
                if(bien && bien.loc_hlo) {
                    return visitesNonScellees.find(visite => visite.loc_hlo === bien.loc_hlo);
                } else {
                    return false;
                }
            });

            setBiensAvecVisiteNonScellee(nextBiensAvecVisiteNonScellee);

            const nextBiensAvecVisiteScellee = biensAvecVisite.filter(bien => {
                if(!bien) return false;
                return hloVisitesScellees.includes(bien.loc_hlo);
            });

            setBiensAvecVisiteScellee(nextBiensAvecVisiteScellee);

            const nextBiensSansVisite = biens.filter(bien => {
                return !biensAvecVisite.includes(bien);
            });
            setBiensSansVisite(nextBiensSansVisite);

            const nextBiensArevisiter = biensAvecVisite.filter(bien => {
                if(bien && bien.loc_hlo){
                    const visite = visites.find(visite => visite.loc_hlo === bien.loc_hlo);
                    if(visite){
                        const dateVisite = new Date(visite.date_visite * 1000);
                        const now = new Date();
                        const mois = (now.getFullYear() - dateVisite.getFullYear()) * 12 + (now.getMonth() - dateVisite.getMonth());
                        return mois >= config.revisite.mois - 6; // 54 mois, soit 4 ans et demi
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            });

            setBiensARevisiter(nextBiensArevisiter);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [biensAvecVisite]);

    return {
        visites,
        biensAvecVisiteNonScellee,
        biensAvecVisiteScellee,
        visitesNonScelleesActifs,
        visitesNonScelleesInactifs,
        biensSansDateVisite,
        biensSansVisite,
        biensSansAgrement,
        biensARevisiter,
        biensRefaire6Mois,
        biensRefaire5Ans
    }
}

export default useBiens;
