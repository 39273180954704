/*
         * Calcule le nombre de cles pour chaque element de @children.
         *
         * @children : tableau des elements à parcourir.
         * @clesActives : tableau cumulant les cles calculees.
        */
const getClesByElement = (children, clesActives) => {

    if(!children) return;

    children.forEach(child => {
        
        const { id, type, elements, cles, valeur, facultatif, calcul } = child;

        // Ne fait rien si calcul=non ou si le champ est facultatif
        if(calcul === 'non' || facultatif) return;

        switch(type) {
            case 'h1':
                let score = getGroupeCles(elements);
                child.cles = score;
                clesActives.push([[score], true, id]);
                break;
            case 'radio':
                console.log(child);
                break;
            case 'checkbox':
                if(!cles) return;
                const isChecked = valeur ? true : false;
                const cleMin = [cles[0] - 1]; // Tableau contenant la valeur la plus basse moins 1
                if(isChecked){
                    clesActives.push([cles, true, id]);
                } else {
                    clesActives.push([cleMin, true, id]);
                }
                break;
            case 'radiogroup':
                if(child.valeur){
                    for(let radio of child.elements) {
                        const radioIsSelected = radio.selected || child.valeur === radio.valeur;
                        if(radioIsSelected){
                            if(radio.cles && Array.isArray(radio.cles)){
                                clesActives.push([radio.cles, radioIsSelected, radio.id]);
                            } else if(radio.elements) {
                                getClesByElement(radio.elements, clesActives);
                            }
                        }
                    }
                } else {
                    const firstRadio = elements[0];
                    if(firstRadio.cles && Array.isArray(firstRadio.cles)){
                        const nextCles = [firstRadio.cles[0] - 1]
                        clesActives.push([nextCles, true, firstRadio.id]);
                    }
                }
                break;
            case 'checkboxgroup':
                [1,2,3,4,5].forEach(nombre => {
                    if(valeur === nombre){
                        clesActives.push([[valeur], true, child.id]);
                    }
                });
                break;
            default:
                getClesByElement(elements, clesActives);
                break;
        }
    });

}

/*
 * @block : tableau des éléments à parcourir
 * 
 * renvoie la valeur la plus grande trouvée 
 * dans les champs actifs
*/
const getGroupeCles = (block) => {

    if(block.facultatif) return;

    // clesActives<Array> : tableau des clés pour chaque élément du block
    let clesActives = [];
    
    // Fonction récursive qui va remplir le tableau des clés
    // @clesActives : tableau des cles à remplir
    getClesByElement(block, clesActives);

    if(!clesActives.length) return 0;

    // Tableau des scores
    const scores = clesActives.reduce((prev, current) => {
        try {
            return prev.concat(Math.max(...current[0]));
        } catch(error) {
            console.warn("Erreur lors du calcul sur les clés actives : ", error, prev, current);
            return prev;
        }
    }, []);

    // Renvoie la valeur la plus petite du tableau des scores
    return Math.min(...scores);
}

const getScore = (groupe) => {
    
    if(groupe && groupe.length) {
    
        // Lancer le calcul sur tous les elements du groupe pour
        // calculer un nombre de clé à chaque élément du groupe.
        getGroupeCles(groupe);
        
        // Calcul du résultat de la visite
        const resultatParBlock = [];
        groupe.forEach(item => {
            if(item.type === 'h1' && !item.calcul){
                let scoreH1 = item.cles; // getGroupeCles(item.elements);
                if(item.minimum && scoreH1 < item.minimum) {
                    scoreH1 = item.minimum;
                }
                resultatParBlock.push(scoreH1);
            }
        });
        
        // Valeur minimale de tous les scores du groupe
        const result = Math.min(...resultatParBlock);

        // Mise à jour de la valeur finale
        return result;
    } else {
        return 0;
    }

}

export { getScore }