import { useState, useEffect } from "react"
import { dbAdd } from "./indexedDBService";
import { setSyncActive } from "./visitesService";

const useSave = () => {

    // UID de la visite à sauvegarder
    const [uid, setUID] = useState();

    // Lancement de la synchro si navigateur en ligne et que la visite n'est pas fake
    const startSavingVisite = (id) => {

        if(!window.navigator.onLine || !id || uid) return;

        setUID(id);

    }

    // Fonction pour enrgistrer une visite dans indexDB
    const saveVisite = async (visite) => {

        // Tester si la visite reçue est valide (cas des contenus vides)
        const isValide = visite.contents.filter(contentItem => contentItem.content.length > 0).length > 0;
        if(!isValide) return;
        
        await saveVisiteToDB({...visite});

    }

    // Fonction pour n'enregistrer que les contenus d'une visite

    const saveVisiteToDB = async (visite) => {
        
        // Enregistrement des contenus
        visite.contents.forEach(async contentItem => {
            await dbAdd('visits_content', {...contentItem});
        });
        
        // Préparer la visite pour l'enregistrer
        const visiteToSave = Object.assign({}, visite);
        delete visiteToSave.contents;
        await dbAdd('visits', visiteToSave);

    }
    
    useEffect(() => {

        if(uid) {
            if(isNaN(uid)){
                // Nouvelle visite
            } else {
                // Lancement des synchros
                setSyncActive(true);
            }
        }

    }, [uid]);
    
    return { startSavingVisite, saveVisite }
}

export default useSave;