import React from "react";
import FormElement from "./formElement";
import { OverlayTrigger, Popover } from "react-bootstrap";

const H2 = ({ block }) => {

    const isTheme = block.id.startsWith('th_');
    const isInsolite = block.id.startsWith('inso-');
    const blockId = isInsolite ? block.id.substring(block.id.indexOf('-') + 1, block.id.length) : block.id;

    const renderAide = (props) => {
        return(
            <Popover id="aide" className="help-popover" {...props}>
                <Popover.Body>
                    <div dangerouslySetInnerHTML={{__html: block.aide}}></div>
                </Popover.Body>
            </Popover>
        );
    }

    return (
        <div className="group group-h2">
            <h2 className="fs-6 fw-bold d-flex align-items-center gap-1 mb-0" id={block.id}>
                {!isTheme && <><span>{blockId}</span><span>–</span></>}
                {block.aide ? <OverlayTrigger overlay={renderAide}>
                    <i className="icon icon-info fs-5 text-secondary"></i>
                </OverlayTrigger> : ''
                }
                <span>{block.titre}</span>
            </h2>
            {block.elements && <div className="mt-1 mb-4">{block.elements.map((element, i) => <FormElement key={i} block={element} />)}</div>}
        </div>
    );
}

export default H2;
