import { Badge, Card, ListGroup } from "react-bootstrap"
import { getUserById } from "../../services/usersService";
import { getDateLightFromTimestamp } from "../../services/utilities";
import { config } from "../../config";

const CardVisite = ({ visite }) => {

    const { date_create, date_visite, date_update, scelle, user_uid, type_visite, cles, cles_calcul } = visite;
    const visiteType = config.visits_types.find(item => item.id === type_visite).name;
    const isInsolite = visite.type_bien === 'inso';
    const technicien = getUserById(user_uid);

    return(
        <Card>
            <Card.Header>
                <Card.Title className="mb-0">{visiteType} du {getDateLightFromTimestamp(date_visite)}</Card.Title>
            </Card.Header>
            <ListGroup variant="flush">
                <ListGroup.Item>
                    <>
                        {technicien && <p className="mb-0">
                            Créée le {getDateLightFromTimestamp(date_create)} <span className="text-nowrap">par {technicien.firstname} {technicien.lastname}</span>
                        </p>}
                        Modifiée le {getDateLightFromTimestamp(date_update)}
                        {scelle && <p className="mb-0">
                            Scellée le {getDateLightFromTimestamp(scelle)}
                        </p>}
                    </>
                </ListGroup.Item>
                {!isInsolite && <>
                    <ListGroup.Item>
                        <div className="d-flex align-items-center gap-2">
                            <h4 className="mb-0">
                                <Badge bg="success" pill><i className="icon icon-cle"></i> {cles}</Badge>
                            </h4>
                            <div>Clé estimée</div>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <div className="d-flex align-items-center gap-2">
                            <h4 className="mb-0">
                                <Badge bg="info" pill><i className="icon icon-cle"></i> {cles_calcul}</Badge>
                            </h4>
                            <div>Clé calculée</div>
                        </div>
                    </ListGroup.Item>
                </>}
            </ListGroup>
        </Card>
    );
}

export default CardVisite;
