import { Button, Card, ListGroup, Modal } from "react-bootstrap";

const ModalCopyDiffs = (props) => {

    const { data, onCopy, onCancel } = props;
    const { newdiffs, deletediffs, updatediffs, count } = data.diffs;

    return(
        <Modal {...props} size="xl" scrollable={true} centered onHide={() => onCancel()}>
            <Modal.Header closeButton>
                <Modal.Title>{`${count} ${count > 1 ? "différences" : "différence"} sur la grille de visite`}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-0">

                {newdiffs.length > 0 && 
                    <Card className="mb-3">
                        <Card.Header>{newdiffs.length} {newdiffs.length > 1 ? "Ajouts" : "Ajout"}</Card.Header>
                        <ListGroup variant="flush">
                            {newdiffs.map(diff => <ListGroup.Item key={diff.node.id} dangerouslySetInnerHTML={{__html: diff.comment}}></ListGroup.Item>)}
                        </ListGroup>
                    </Card>
                }

                {deletediffs.length > 0 && 
                    <Card className="mb-3">
                        <Card.Header>{deletediffs.length} {deletediffs.length > 1 ? "Suppressions" : "Suppression"}</Card.Header>
                        <ListGroup variant="flush">
                            {deletediffs.map(diff => <ListGroup.Item key={diff.node.id} dangerouslySetInnerHTML={{__html: diff.comment}}></ListGroup.Item>)}
                        </ListGroup>
                    </Card>
                }

                {updatediffs.length > 0 && 
                    <Card className="mb-3">
                        <Card.Header>{updatediffs.length} {updatediffs.length > 1 ? "Modifications" : "Modification"}</Card.Header>
                        <ListGroup variant="flush">
                            {updatediffs.map(diff => <ListGroup.Item key={diff.node.id} dangerouslySetInnerHTML={{__html: diff.comment}}></ListGroup.Item>)}
                        </ListGroup>
                    </Card>
                }

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => onCancel()}>Annuler</Button>
                <Button variant="primary" onClick={() => onCopy(data.visite)}>Dupliquer</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalCopyDiffs;