import { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { getElementsCount, getInputsChecked, getIsTheme } from "../../services/themeService";
import { getThemeSchemaById } from "../../services/visitesService";

const ThemeItem = ({ theme }) => {

    const [themeSchema, setThemeSchema] = useState(null);

    useEffect(() => {

        const schema = getThemeSchemaById(theme.id);
        setThemeSchema(schema);

    }, [theme]);

    if(!themeSchema) return <div></div>;

    const totalToCheck = getElementsCount(themeSchema.elements);
    const total = getInputsChecked(theme.id);
    const themeValide = getIsTheme(theme);
    let isValide = false;

    //si aucun critère dans le schéma, c'est un label
    if(totalToCheck === 0){
        isValide = themeValide;
    }else{
        isValide = themeValide ? true : total >= totalToCheck;
    }

    return(
        <div className="theme-item">
            {isValide ?
                <Badge bg="success" pill><i className="icon icon-check"></i></Badge> :
                <Badge bg="info" pill>{total}/{totalToCheck}</Badge>
            }
            <div className="theme-item-details">
                <span className="titre">{theme.name}</span>
            </div>
        </div>
    )
}

export default ThemeItem;
