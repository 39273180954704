import React, {useContext} from "react";
import { VisiteContext } from "../../services/visiteContext";
import Input from "./input";
import Checkbox from "./checkbox";
import Radio from "./radio";
import H1 from "./h1";
import H2 from "./h2";
import H3 from "./h3";
import Image from "./image";
import RadioGroup from "./radioGroup";
import CheckboxGroup from "./checkboxGroup";
import Div from "./div";
import InputDecimal from "./input-decimal";
import InputInteger from "./input-integer";
import InputDate from "./input-date";

const FormElement = ({ block, itemHandler }) => {

    const { locationRef } = useContext(VisiteContext);

    var condDep = false;
    
    if(block.conditions){
        block.conditions.forEach(condition => {
            if(condition.departement){
                const locDepartement = parseInt(locationRef.current.geo_dep_id);
                if( condition.departement.includes(locDepartement) ){
                    condDep = true;
                };

                //si les conditions sont remplies, le critère devient obligatoire !
                switch(condition.operateur){
                    case 'egal':
                        if(condDep){
                            block.facultatif = false;
                        }
                        break;
                    case 'different':
                        if( ! condDep){
                            block.facultatif = false;
                        }
                        break;
                    default:
                        break;
                };
            };
        });
    }

    switch(block.type){
        case 'div':
            return <Div block={block} />;
        case 'h1':
            return <H1 block={block} itemHandler={itemHandler} />;
        case 'h2':
            return <H2 block={block} />;
        case 'h3':
            return <H3 block={block} />;
        case 'image':
            return <Image block={block} />;
        case 'text':
        case 'input':
            return <Input block={block} />;
        case 'checkbox':
            return <Checkbox block={block} />;
        case 'radio':
            return <Radio block={block} />;
        case 'radiogroup':
            return <RadioGroup block={block} />;
        case 'checkboxgroup':
            return <CheckboxGroup block={block} />;
        case 'décimal':
        case 'decimal':
            return <InputDecimal block={block} />;
        case 'entier':
            return <InputInteger block={block} />;
        case 'date':
            return <InputDate block={block} />;
        default:
            return null;
    }
}

export default FormElement;
