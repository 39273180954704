import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { config } from "../config";
import { createVisite, getVisitesFiltered } from "../services/visitesService";
import { useHistory } from "react-router";
import { getCurrentUser } from "../services/usersService";


const ModaleVisite = (props) => {

    const { loc_hlo } = props;

    const history = useHistory();

    const [typeSelected, setTypeSelected] = useState(null);
    const [typeVisites, setTypeVisites] = useState([]);

    const onChangeHandler = (event) => {
        setTypeSelected(event.target.value);
    }

    const onEnter = () => {
        setTypeSelected(null);
    }

    const onSubmit = async () => {
        await createVisite({ type_visite: typeSelected }).then(visiteUid => {
            history.push('/visit-update/' + visiteUid);
        });
    }

    useEffect(() => {

        // Copie profonde des types de visites dans config
        const allTypesVisites = Array.from(new Set(config.visits_types));

        // Ne garder que les types à afficher
        const filteredTypesVisite = allTypesVisites.filter(type => type.id !== 'undefined' && type.id !== 'vstarch')

        // Tableau pour stocker les types mis à jour
        const nextTypeVisites = [];

        //Chargement des visites existantes pour de ce bien
        getVisitesFiltered({ hlo: loc_hlo }).then(bienVisites => {

            const visitesLabellisation = bienVisites.filter(visite => visite.type_visite === 'vst');
            visitesLabellisation.sort((a, b) => a.date_visite > b.date_visite);

            const reVisites = bienVisites.filter(visite => visite.type_visite === 'vstre');
            reVisites.sort((a, b) => a.date_visite > b.date_visite);

            const visitesThematiques = bienVisites.filter(visite => visite.type_visite === 'vs4themes');
            visitesThematiques.sort((a, b) => a.date_visite > b.date_visite);

            filteredTypesVisite.forEach(type => {

                const typeUpdated = {...type}; // création d'une copie plutot que d'une reference

                if(type.id === 'vst'){
                    if(visitesLabellisation.length > 0 || reVisites.length > 0) {
                        const uid = reVisites.length ? reVisites[0].uid : visitesLabellisation[0].uid;
                        typeUpdated.visiteUid = uid;
                        typeUpdated.message = "Hébergement déjà labellisé";
                        typeUpdated.disabled = true;
                    }
                }

                if(type.id === 'vs4themes'){
                    if(visitesThematiques.length > 0 || reVisites.length > 0 || visitesLabellisation.length > 0) {
                        const uid = reVisites.length ? reVisites[0].uid : visitesLabellisation.length ? visitesLabellisation[0].uid : visitesThematiques[0].uid;
                        typeUpdated.visiteUid = uid;
                        typeUpdated.message = "Gérer les thèmes via la visite existante";
                        typeUpdated.disabled = true;
                    }
                }

                nextTypeVisites.push(typeUpdated);

            });
        
            const user = getCurrentUser();
            if(parseInt(user.level) !== 100){
                setTypeVisites(nextTypeVisites.filter(type => type.id !== 'dev'));
            } else {
                setTypeVisites([...nextTypeVisites]);
            }
            
        });

    }, [loc_hlo]);

    return (
        <Modal {...props} onEnter={onEnter} centered className="modal-visite" backdropClassName="modal-visite-bg">
            <Modal.Header closeButton>
                <Modal.Title>Nouvelle visite</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {typeVisites.map((input, index) =>
                        <div key={index} className="mb-3">
                            <Form.Check className="mb-0" key={index} type="radio" label={input.name} name={'visite'} value={input.id} id={`radio-${index}`} onChange={(e) => onChangeHandler(e)} disabled={input.disabled} />
                            {input.message && <small className="ms-4">{input.message} – <Link to={`/visit/${input.visiteUid}`}>Voir visite {input.visiteUid}</Link></small>}
                        </div>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Annuler</Button>
                <Button variant="primary" disabled={!typeSelected} onClick={onSubmit}>Commencer la visite</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModaleVisite;
