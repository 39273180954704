import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getVisiteById } from "../services/visitesService";
import { getDateLightFromTimestamp } from "../services/utilities";
import { Col, Row } from "react-bootstrap";

import CardBien from "../components/visite-read/card-bien";
import CardVisite from "../components/visite-read/card-visite";
import VisiteHeader from "../components/visite-read/visite-header";
import CardCompteRendu from "../components/visite-read/card-compte-rendu";
import CardVisitContent from "../components/visite-read/card-visit-content";
import CardVisiteThemes from "../components/visite-read/card-visite-themes";
import {getLocationByHLO} from "../services/locationsService";
import {getProprioByUID} from "../services/propriosService";


const VisitRead = () => {

    let { id } = useParams();

    const [visite, setVisite] = useState();

    useEffect(() => {
        getVisiteById(id).then(result => {

            if(result) setVisite(result);

            //Génération du titre page pour l'export PDF
            //VISITE_Nom(10premierscaractères)_Prénom(1Caratère)_DateEdition(jj_mm_aa)_"AGR"
            let docTitle = "VISITE_";
            let location = getLocationByHLO(result.loc_hlo);

            if(location && location.hasOwnProperty('props') ){
                const propsUids = location.props.split(',');
                const props = propsUids.map(propUid => getProprioByUID(propUid));
                props.forEach(prop => {
                    if( prop.is_default === "1" ){
                        docTitle += prop.lastname.slice(0, 10)+"_"+prop.firstname.slice(0, 1);
                    }
                });
            }

            let dateUpdate = getDateLightFromTimestamp(result.date_update);
            dateUpdate = dateUpdate.replace("/", "_");
            dateUpdate = dateUpdate.replace("/", "_");
            docTitle += "_"+dateUpdate;
            ( ! location.agr )?docTitle += "_"+location.loc_hlo:docTitle += "_"+location.agr;

            document.title = docTitle;
        });

        return () => {
            document.title = "Nomade";
        }
    }, [id]);

    if(!visite) return (<div></div>);

    return (
        <div className="visite visite-read">
            <VisiteHeader visite={visite} />
            <div className="content-body">
                <Row>
                    <Col sm={4}>
                        <div className="sticky-top">
                            <div className="d-flex flex-column gap-4">
                                <CardBien hlo={visite.loc_hlo} />
                                <CardVisite visite={visite} />
                                <CardVisiteThemes visite={visite} />
                            </div>
                        </div>
                    </Col>
                    <Col sm={8}>
                        <CardCompteRendu visite={visite} />
                        <CardVisitContent visite={visite} />
                    </Col>
                </Row>
            </div>
        </div>
    );
}


export default VisitRead;
