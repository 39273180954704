import React, { useContext } from "react";
import {Accordion, Badge, OverlayTrigger, Popover} from "react-bootstrap";
import { cloneDeep } from 'lodash';
import { VisiteContext } from "../../services/visiteContext";
import ButtonDuplicateBloc from "../button-duplicate-bloc";
import ButtonDeleteBlock from "../button-delete-block";
import Commentaire from "./commentaire";
import FormElement from "./formElement";
import TitrePerso from "./titre-perso";
import AccordionButton from "../visite-edit/accordion-button";
import { NextLevelContext } from "../../services/nextLevelContext";
import { publishNextLevelCount } from "../../services/visitesService";

const H1 = ({ block, itemHandler }) => {

    const { duplicateElement } = useContext(VisiteContext);
    const { id, titre, multi, elements, commentaire } = block;
    const isTheme = block.id.indexOf('th_') !== -1;
    const isInsolite = block.id.indexOf('inso-') !== -1;

    // Tableau pour stoquer le nombre de champs 
    // à remplir pour passer au niveau supérieur
    const nextLevelInputs = [];

    const onDuplicate = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const newElement = cloneDeep(block);
        await duplicateElement(newElement);
    }

    const onKeyUp = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    const renderAide = (props) => {
        return(
            <Popover id="aide" className="help-popover" {...props}>
                <Popover.Body>
                    <div dangerouslySetInnerHTML={{__html: block.aide}}></div>
                </Popover.Body>
            </Popover>
        );
    }

    const nextLevelInputsCount = (input) => {

        const existe = nextLevelInputs.find(i => i.id === input.id);

        if(!existe && input.isNext){
            nextLevelInputs.push(input);
        }

        publishNextLevelCount({ id: block.id, count: nextLevelInputs.length });

    }

    const isDropable = id.indexOf('~') !== -1 || block.deletable;

    return (
        <Accordion.Item eventKey={block.id} className={`item-${block.id}`}>
            <AccordionButton eventKey={block.id} callback={itemHandler}>
                <div className="w-100 me-3" onKeyUp={onKeyUp}>
                        <h1 id={id} className="d-flex justify-content-between align-items-center w-100 fs-5 mb-0 me-3">
                            <div className={`grid${isTheme ? " grid-theme" : ""}`}>
                                { isInsolite ?
                                    block.cles > 4 && <span className="badge rounded-pill bg-success"><i className="icon icon-check"></i></span> :
                                    (!isTheme && !block.calcul) && <>
                                        <span>{id}</span>
                                        <span><Badge bg="info" pill>{block.cles} <i className="icon icon-cle"></i></Badge></span>
                                    </>
                                }

                                {block.aide && <OverlayTrigger overlay={renderAide}>
                                    <span className="d-flex align-items-center"><i className="icon icon-info fs-5 text-secondary"></i></span>
                                </OverlayTrigger>}

                                <span className="titre-h1">
                                    <>
                                        <span>{titre}</span>
                                        <TitrePerso block={block} />
                                    </>
                                    {commentaire && <span className="ms-2 fs-5"><i className="icon icon-text text-success"></i></span> }
                                </span>
                            </div>
                            <div className="actions">
                                <div className="btn-group">
                                    {multi && <ButtonDuplicateBloc id={id} action={onDuplicate} />}
                                    {isDropable && <ButtonDeleteBlock block={block} />}
                            </div>
                            </div>
                        </h1>
                </div>
            </AccordionButton>
            <Accordion.Body>
                <NextLevelContext.Provider value={{ rootCles: block.cles, nextLevelInputsCount }}>
                    <>
                    <Commentaire block={block} />
                    { elements && elements.map((element, i) => <FormElement key={i} block={element} />) }
                    </>
                </NextLevelContext.Provider>
            </Accordion.Body>
        </Accordion.Item>
    );
}

export default H1;
