import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { useContext } from "react";
import { VisiteContext } from "../services/visiteContext";

const ButtonDeleteBlock = ({block}) => {

    const { removeElement } = useContext(VisiteContext);

    const onDelete = (event) => {
        event.preventDefault();
        event.stopPropagation();
        removeElement(block);
        document.body.click();
    }

    const onCancel = (event) => {
        event.preventDefault();
        event.stopPropagation();
        document.body.click();
    }

    const onWantDelete = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    const popover = (
        <Popover id="popover-effacer-visite">
            <Popover.Body>
                <p className="text-center fs-6">Êtes-vous sur de vouloir supprimer ce bloc ?</p>
                <div className="d-flex justify-content-between">
                    <button className="btn btn-outline-secondary" onClick={onCancel}>Annuler</button>
                    <button className="btn btn-outline-danger" onClick={onDelete}>Supprimer</button>
                </div>
            </Popover.Body>
        </Popover>
    );

    const tooltip = (props) => (
        <Tooltip id="tooltip-effacer-visite" {...props}>
            Supprimer ce bloc
        </Tooltip>
    );

    return(
        <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={tooltip}>
            <div className="d-flex">
                <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={popover}>
                    <a href="./" className="btn btn-danger" onClick={onWantDelete}><i className="fs-5 icon icon-trash" title="Supprimer ce bloc"></i></a>
                </OverlayTrigger>
            </div>
        </OverlayTrigger>
    )
};

export default ButtonDeleteBlock;
