import React, {Fragment, useContext, useEffect, useState} from "react";
import useNextLevel from "../../services/useNextLevel";
import { VisiteContext } from "../../services/visiteContext";
import FormElement from "./formElement";
import RadiogroupInput from "./radiogroup-input";

const RadioGroup = ({ block }) => {

    const { handleChange } = useContext(VisiteContext);
    const { isNextLevel } = useNextLevel({ block });

    const [valeur, setValeur] = useState();

    const handleClick = (event) => {
        
        setValeur(event.target.value);
        block.valeur = event.target.value;
        block.selected = event.target.checked;
        block.cles = parseInt(event.target.getAttribute('cles'));

        // Récupérer le tableau des valeurs du radio sélectionné
        const nextRange = event.target.getAttribute('range');
        if(nextRange){
            block.range = nextRange.split(',').map(nombre => parseInt(nombre));
        }

        handleChange(block.id, event);
    }

    useEffect(() => {

        setValeur(block.valeur);

    }, [block]);

    return (
        <div className={`radio-group mb-4 mt-2${isNextLevel ? " next" : ""} ${block.id}`} data-cles={`${block.cles}`} data-facultatif={`${block.facultatif}`}>
            {block.titre && <label className="group-title fw-normal">{block.titre}</label>}
            {block.elements.map(radio => {
                return <Fragment key={radio.id}>
                    <RadiogroupInput block={block} radio={radio} valeur={valeur} onClick={handleClick} />
                    { (radio.elements && valeur === radio.valeur) && <div className="group radio-group-children">
                        {radio.elements.map(element => <FormElement key={element.id} block={element} />)}
                    </div> }
                </Fragment>
            })}
        </div>
    );
}

export default RadioGroup;
