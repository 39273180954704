import { createContext, useContext, useReducer } from "react";
import { db } from '../services/indexedDBService';
import { validateTheme } from "../services/visitesService";

const ThemeContext = createContext(null);
const ThemeDispatchContext = createContext(null);

const saveTheme = async (theme) => {
    await db.visits_content.put(theme);
}

const updateTheme = (theme, blockid, event) => {

    const nextContents = [...theme.content];

    const updateValues = (block => {

        const { type, id } = block;

        if (blockid === id) {
            switch (type) {
                case 'radio':
                case 'checkbox':
                    block['valeur'] = event.target.checked;
                    break;
                case 'checkboxgroup':
                    break;
                case 'radiogroup':
                    break;
                case 'text':
                    block['valeur'] = event.target.value;
                    break;
                case 'h1':
                case 'div':
                    if(event.target.type === "text"){
                        block["titreperso"] = event.target.value;
                    } else {
                        block["commentaire"] = event.target.value;
                    }
                    break;
                default:
                    block['valeur'] = event.target.value;
                    break;
            }
        } else {
            if(block.elements){
                block.elements.forEach(element => updateValues(element));
            }
        }

    });

    nextContents.forEach(block => updateValues(block));

    const isValide = validateTheme(nextContents);

    const updatedTheme = {
        ...theme,
        content: nextContents,
        cles: isValide
    };

    return updatedTheme;
}

const themeReducer = (theme, action) => {
    switch(action.type){
        case 'init': {
            return {...action.theme};
        }
        case 'date-fin-label': {
            const key = action.value.label === 'label2' ? 'date_fin_label2' : 'date_fin_label';
            const validekey = action.value.label === 'label2' ? 'valide2' : 'valide';
            const nextDate = action.value.date ? new Date(action.value.date) / 1000 : null;
            const nextTheme = {...theme, [key]: nextDate, [validekey]: nextDate ? theme[validekey] : false};
            saveTheme(nextTheme);
            return nextTheme;
        }
        case 'add-files': {
            const themeFiles = theme.files ? theme.files : [];
            const existe = themeFiles.includes(action.value[0]);
            if(!existe){
                const nextFiles = [...themeFiles, ...action.value]
                const themeWithFiles = {...theme, files: nextFiles};
                saveTheme(themeWithFiles);
                return themeWithFiles;
            } else {
                return theme;
            }
        }
        case 'delete-file': {
            const themeFiles = theme.files ? theme.files : [];
            const index = themeFiles.indexOf(action.value.file);
            if(index !== -1) {
                themeFiles.splice(index, 1);
                if(themeFiles.length === 0){
                    action.value.label === 'label2' ? theme.valide2 = false : theme.valide = false;
                }
                const nextTheme = {...theme, files: themeFiles};
                saveTheme(nextTheme);
                return nextTheme;
            } else {
                return theme;
            }
        }
        case 'force-theme-valide': {
            const key = action.value === 'label2' ? 'valide2' : 'valide';
            const nextTheme = {...theme, [key]: theme[key] ? !theme[key] : true};
            saveTheme(nextTheme);
            return nextTheme;
        }
        case 'update-theme': {
            const nextTheme = updateTheme(action.value.theme, action.value.blockid, action.value.event);;
            return nextTheme;
        }
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}

const ThemeProvider = ({ children }) => {

    const [theme, dispatchTheme] = useReducer(themeReducer, null);

    return(
        <ThemeContext.Provider value={theme}>
            <ThemeDispatchContext.Provider value={dispatchTheme}>
                {children}
            </ThemeDispatchContext.Provider>
        </ThemeContext.Provider>
    )
}

const useTheme = () => {
    return useContext(ThemeContext);
}

const useThemeDispatch = () => {
    return useContext(ThemeDispatchContext);
}

export {
    ThemeContext, 
    ThemeProvider, 
    useTheme, 
    useThemeDispatch, 
}