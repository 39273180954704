import { useEffect, useState } from "react";
import { Button, Card, ListGroup } from "react-bootstrap"
import { dbGet } from "../../services/indexedDBService";
import { setSyncActive } from "../../services/visitesService";
import ModalComment from "../modal-comment";

const CardCompteRendu = ({ visite }) => {

    const [showCommentModal, setShowCommentModal] = useState(false);
    const [content, setContent] = useState();

    useEffect(() => {

        dbGet('visits', visite.uid)
        .then(result => {
            if(result.cpt_rendu){
                setContent(result.cpt_rendu);
            } else {
                setContent({
                    textpublic: "<p>Pas de compte-rendu public</p>",
                    textprive: "<p>Pas de compte-rendu privé</p>"
                });
            }
        });


    }, [visite, showCommentModal]);
    
    const onOpenCommentModal = () => {
        setSyncActive(false);
        setShowCommentModal(true);
    }

    const onCloseCommentModal = () => {
        setSyncActive(true);
        setShowCommentModal(false);
    }
    
    if(!content) return <div></div>;

    const htmlPublic = content.textpublic ? {__html: content.textpublic} : {__html: '<p>Pas de compte-rendu public</p>'};
    const htmlPrive = content.textprive ? {__html: content.textprive} : {__html: '<p>Pas de compte-rendu privé</p>'};

    return (
        <div className="compte-rendu">
            <Card className="mb-4">
                <Card.Header><strong>Compte-rendu public</strong></Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        <div dangerouslySetInnerHTML={htmlPublic} />
                    </ListGroup.Item>
                </ListGroup>
                <Card.Header><strong>Compte-rendu privé</strong></Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        <div dangerouslySetInnerHTML={htmlPrive} />
                    </ListGroup.Item>
                </ListGroup>
                <Card.Footer>
                    <div className="d-flex justify-content-end">
                        <Button variant={visite.cpt_rendu ? "success" : "outline-success"} onClick={onOpenCommentModal}>
                            <i className="icon icon-text"></i> Modifier le compte-rendu
                        </Button>
                    </div>
                </Card.Footer>
            </Card>
            {showCommentModal && <ModalComment show={showCommentModal} onHide={onCloseCommentModal} visite={visite} canstopsync="true" />}
        </div>
    );
};

export default CardCompteRendu;
