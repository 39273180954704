import React, { useEffect, useState } from "react";
import { Form, Row, Col, CloseButton } from "react-bootstrap";
import { config } from "../../config";
import { filterLocations } from "../../services/locationsService";
import styles from "./filters.module.scss";

const BienFilters = () => {

    const typesDeBiens = Object.entries(config.group_visit).filter(([key, value]) => value.theme === false);

    const onTypeChange = (event) => {
        const newType = event.target.value;
        if(newType !== 'tous'){
            filterLocations({type: newType});
        } else {
            filterLocations({type: null});
        }
    }

    const [searchString, setSearchString] = useState('');

    const onSearch = (event) => {
        setSearchString(event.target.value);
    }

    useEffect(() => {
        if(searchString.length){
            filterLocations({search: searchString});
        } else {
            filterLocations({search: null});
        }

    }, [searchString]);

    const clearSearch = (event) => {
        event.preventDefault();
        setSearchString('');
    }

    return (
        <div className="filters_container d-flex">
            <Form>
                <Row>
                    <Col>
                        <Form.Group className={styles.searchInputGroup}>
                            <Form.Control id={"search-input-location"} className="search-input" size="sm" placeholder="Votre recherche" value={searchString} onChange={onSearch} />
                            {searchString.length > 0 && <CloseButton onClick={clearSearch} />}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Select size="sm" onChange={onTypeChange}>
                            <option value="tous">Tous les biens</option>
                            {typesDeBiens.map(type => <option key={type[0]} value={type[0]}>{type[1].name}</option>)}
                        </Form.Select>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default BienFilters;
