import React, {Fragment, useEffect, useState} from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import {config} from "../../../config";
import { getGenderName, getProprioByUID } from "../../../services/propriosService";
import { initVisite, getLastVisitesByHlo, refreshVisites, getIsForVisite } from "../../../services/visitesService";
import {getDateLightFromTimestamp} from "../../../services/utilities";
import useDuplicateVisite from '../../../services/useDuplicateVisite';
import {Badge, OverlayTrigger, Tooltip} from "react-bootstrap";
import ModaleVisite from "../../modal-visite";
import ModaleCopyDiffs from "../../modal-copy-diffs";
import useAutorisations from "../../../services/useAutorisations";
import "./location-item.scss";

const LocationItem = ({ location, visiteuid, hide }) => {

    const history = useHistory();

    const propsUids = location.props.split(',');
    const props = propsUids.map(propUid => getProprioByUID(propUid));
    const { canUpdateVisite } = useAutorisations();
    const { getDiffsAndCopy, saveCopy } = useDuplicateVisite();

    const [showVisite, setShowVisite] = useState(false);
    const [showDiffs, setShowDiffs] = useState(false);
    const [ isloading, setIsloading] =  useState(false);
    const [ lastVisite, setLastVisite] =  useState(null);

    useEffect(() => {

        let isMounted = true;

        if(isMounted){
            getLastVisitesByHlo(location.loc_hlo).then(visite => {
                setLastVisite(visite);
            });
        }

        return ()=>{
            isMounted = false;
        }
    },[location]);

    var date_visite = "Aucune";
    if(location.date_visite){
        date_visite = getDateLightFromTimestamp(location.date_visite);
    }

    const newVisite = () => {
        initVisite(location.loc_hlo);
        setShowVisite(true);
    }

    const duplicate = () => {

        setIsloading(true);

        getDiffsAndCopy(visiteuid, location.loc_hlo, location.type)
        .then((copy) => {
            if(copy.diffs.count){
                setShowDiffs(copy);
            } else {
                doCopy(copy.visite);
            }
            setIsloading(false);
        });

    }

    const cancelDuplicate = () => {
        setShowDiffs(null);
    }

    const doCopy = (visite) => {
        saveCopy(visite)
        .then(result => {
            if(result) {
                refreshVisites()
                .then(() => {
                    if(hide) hide();
                    history.push(`/visit-update/${visite.uid}`);
                });
            }
        });
    }

    const tooltipEdit = (props) => (
        <Tooltip id="tooltip-editer-visite" {...props}>Modifier la dernière visite</Tooltip>
    );

    const tooltipRead = (props) => (
        <Tooltip id="tooltip-read-visite" {...props}>Consulter la dernière visite</Tooltip>
    );

    return(
        <div className={`location-item${isloading ? ' loading' : ''}`}>
            <div className="location-infos">
                <div className="location-hlo d-flex gap-2">
                    <div className="location-status">
                        {location.state === 'actif' ?
                            <Badge pill bg="success"><i className="icon icon-check"></i></Badge> :
                            <Badge pill bg="warning"><i className="icon icon-ios-close"></i></Badge>
                        }
                    </div>
                    <div>
                        <strong>{location.loc_hlo} / {location.agr}</strong>
                        {location.name && <span className="location-name d-block"><i>"{location.name}"</i></span>}
                        {location.nature && <span className="location-nature d-block">Nature : {location.nature}</span>}
                    </div>
                </div>
            </div>
            <div className="location-type">{config.group_visit[location.type].name}</div>
            <div className="location-date_visite">{date_visite}</div>
            <div className="location-contact">
                {props && props.map(prop => (prop) && 
                    <Fragment key={prop.uid}>
                        <span>{prop.hasOwnProperty('gender') && getGenderName(prop.gender)} {prop.firstname} {prop.lastname}</span>
                        <br/>
                    </Fragment>
                )}
            </div>
            <div className="location-commune">{location.commune} {location.geo_dep_id && `(${location.geo_dep_id})`}</div>
            <div className="location-actions">

                <div className="d-flex gap-2">
                    {visiteuid ?
                        <button className="btn btn-outline-success" onClick={duplicate}>Sélectioner</button> :
                        <button className="btn btn-outline-success" onClick={newVisite}>Visiter</button>
                    }

                    {lastVisite &&
                        <>
                            <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={tooltipRead}>
                                <Link to={`/visit/${lastVisite.uid}`} className="btn btn-outline-success" title="Dernière visite">
                                    <i className="icon icon-eye"></i>
                                </Link>
                            </OverlayTrigger>

                            { (canUpdateVisite(lastVisite) && getIsForVisite(lastVisite)) &&
                                <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={tooltipEdit}>
                                    <Link
                                        className={`btn btn-outline-info`}
                                        to={`/visit-update/${lastVisite.uid}`} title="Modifier cette visite">
                                        <i className="icon icon-pencil"></i>
                                    </Link>
                                </OverlayTrigger>
                            }
                        </>
                    }
                </div>

            </div>
            {showVisite && <ModaleVisite show={showVisite} loc_hlo={location.loc_hlo} onHide={() => setShowVisite(false)} />}
            {showDiffs && <ModaleCopyDiffs show={showDiffs} data={showDiffs} onCancel={cancelDuplicate} onCopy={doCopy} />}
        </div>
    );
}

export default LocationItem;
