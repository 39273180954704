import { useEffect, useState } from "react";
import { Modal, Tab, Tabs, Button } from "react-bootstrap";
import TextEditor from "./text-editor";
import StarterKit from '@tiptap/starter-kit';
import { useEditor } from '@tiptap/react';

import { dbGet } from "../services/indexedDBService";
import { saveVisiteComment } from "../services/visitesService";

const emptyComments = {
    textpublic: '', 
    textprive: ''
};

const ModalComment = (props) => {

    const [visite, setVisite] = useState();

    const editorPrive = useEditor({
        extensions: [
            StarterKit,
        ],
        content: null
    });

    const editorPuplic = useEditor({
        extensions: [
            StarterKit,
        ],
        content: null
    });

    useEffect(() => {

        dbGet('visits', props.visite.uid).then(result => {
            setVisite(result);
        });

    }, [props]);

    useEffect(() => {
        if(visite){
            const { textpublic, textprive } = visite ? (visite.cpt_rendu || emptyComments) : emptyComments;
            editorPrive.commands.setContent(textprive);
            editorPuplic.commands.setContent(textpublic);
        }
    }, [visite, editorPrive, editorPuplic]);

    // Enregistrement des deux commentaires et fermeture de la modale
    const saveComments = () => {

        const contentPublic = editorPuplic.getHTML();
        const contentPrive = editorPrive.getHTML();

        saveVisiteComment(visite.uid, {
            textprive: contentPrive !== '<p></p>' ? contentPrive : null,
            textpublic: contentPublic !== '<p></p>' ? contentPublic : null,
        }).then(result => {
            props.onHide();
        });
    }

    // Si la visite n'est pas encore chargée (dans useEffect)
    if(!visite) return <div></div>;

    return (
        <Modal {...props} size="xl" backdrop="static">
            <Modal.Body>
                <div className="textinput-container">
                    <Tabs defaultActiveKey="public" transition={true} className="justify-content-center">
                        <Tab eventKey="public" title="Compte-rendu public">
                            {visite && <TextEditor visite={props.visite} type="textpublic" editor={editorPuplic} />}
                        </Tab>
                        <Tab eventKey="prive" title="Compte-rendu privé">
                            <TextEditor visite={props.visite} type="textprive" editor={editorPrive} />
                        </Tab>
                    </Tabs>
                </div>
                <div className="d-flex justify-content-end gap-3 mt-3">
                    <Button variant="secondary" onClick={() => props.onHide()}>Annuler</Button>
                    <Button variant="primary" onClick={saveComments}>Enregistrer</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalComment;