import FileItem from "./file-item";

const FileList = ({files, deleteHandler, visiteUid}) => {

    return (
        <div className="file-list d-flex flex-column gap-2">
            {files.map((f, index) => <FileItem key={`${f}-${index}`} file={f} deleteHandler={deleteHandler} visiteUid={visiteUid} />)}
        </div>
    )
}

export default FileList;