import { useEffect, useState } from "react";
import { getScore } from "./score-service";

/*
 * @groupe : tableau des noeuds à parcourir
*/
const useGroupeCles = (groupe) => {

    const [calcul, setCalcul] = useState([]);

    useEffect(() => {

        if(groupe) {
            const score = getScore(groupe);
            setCalcul([score]);
        }

    }, [groupe]);

    return calcul[0];
}

export default useGroupeCles;