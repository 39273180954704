import React, { useContext } from "react";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import { VisiteContext } from "../../services/visiteContext";

const InputDecimal = ({block}) => {

    const { handleChange } = useContext(VisiteContext);

    return (
        <div className="mb-3">
            <InputGroup>
                <InputGroup.Text>{block.prefixe}</InputGroup.Text>
                <FormControl type="number" step="0.5" value={block.valeur || ''} onChange={event => handleChange(block.id, event)} />
                <InputGroup.Text>{block.suffixe}</InputGroup.Text>
            </InputGroup>
            {block.aide && <Form.Text muted>{block.aide}</Form.Text>}
        </div>
    );

};

export default InputDecimal;