import { ListGroup, ListGroupItem } from "react-bootstrap";
import { Link } from "react-router-dom";

const InfosVisitesByDeps = ({ biensSansAgrement, biensSansDateVisite, visitesNonScelleesActifs, visitesNonScelleesInactifs, biensRefaire5Ans, biensRefaire6Mois, departement }) => {
    return (
        <ListGroup variant="flush">
            <ListGroupItem>
                <div className="d-flex justify-content-between">
                    <span className="title">Biens avec visite expirée 5 ans</span>
                    <div className="status d-flex">
                        <span className="active">
                            <Link to={`/locations?actifs=true&visite5Ans=true${departement ? `&departement=${departement}`: ''}`}>{biensRefaire5Ans.filter(bien => bien.state === 'actif').length}</Link>
                        </span>
                        <span className="inactive">
                            <Link to={`/locations?inactifs=true&visite5Ans=true${departement ? `&departement=${departement}`: ''}`}>{biensRefaire5Ans.filter(bien => bien.state === 'inactif').length}</Link>
                        </span>
                    </div>
                </div>
            </ListGroupItem>
            <ListGroupItem>
                <div className="d-flex justify-content-between">
                    <span className="title">Biens sans date de visite</span>
                    <div className="status d-flex">
                        <span className="active">
                            <Link to={`/locations?actifs=true&sansdatevisite=true${departement ? `&departement=${departement}`: ''}`}>{biensSansDateVisite.filter(bien => bien.state === 'actif').length}</Link>
                        </span>
                        <span className="inactive">
                            <Link to={`/locations?inactifs=true&sansdatevisite=true${departement ? `&departement=${departement}`: ''}`}>{biensSansDateVisite.filter(bien => bien.state === 'inactif').length}</Link>
                        </span>
                    </div>
                </div>
            </ListGroupItem>
            <ListGroupItem>
                <div className="d-flex justify-content-between">
                    <span className="title">Biens avec visites non scellées (hors visites archives)</span>
                    <div className="status d-flex">
                        <span className="active">
                            <Link to={`/visits?actifs=true&scelle=false${departement ? `&departement=${departement}`: ''}`}>{visitesNonScelleesActifs?.length}</Link>
                        </span>
                        <span className="inactive">
                            <Link to={`/visits?inactifs=true&scelle=false${departement ? `&departement=${departement}`: ''}`}>{visitesNonScelleesInactifs?.length}</Link>
                        </span>
                    </div>
                </div>
            </ListGroupItem>

            <ListGroupItem>
                <div className="d-flex justify-content-between">
                    <span className="title">Biens à revisiter dans les six mois</span>
                    <div className="status d-flex">
                        <span className="active">
                            <Link to={`/locations?actifs=true&visite6mois=true&revisite=true${departement ? `&departement=${departement}`: ''}`}>{biensRefaire6Mois.filter(bien => bien.state === 'actif').length}</Link>
                        </span>
                        <span className="inactive">
                            <Link to={`/locations?inactifs=true&visite6mois=true&revisite=true${departement ? `&departement=${departement}`: ''}`}>{biensRefaire6Mois.filter(bien => bien.state === 'inactif').length}</Link>
                        </span>
                    </div>
                </div>
            </ListGroupItem>

            <ListGroupItem>
                <div className="d-flex justify-content-between">
                    <span className="title">Biens sans agrément</span>
                    <div className="status d-flex">
                        <span className="active">
                            <Link to={`/locations?actifs=true&sansagrement=true${departement ? `&departement=${departement}`: ''}`}>{biensSansAgrement.filter(bien => bien.state === 'actif').length}</Link>
                        </span>
                        <span className="inactive">
                            <Link to={`/locations?inactifs=true&sansagrement=true${departement ? `&departement=${departement}`: ''}`}>{biensSansAgrement.filter(bien => bien.state === 'inactif').length}</Link>
                        </span>
                    </div>
                </div>
            </ListGroupItem>
        </ListGroup>
    )
};

export default InfosVisitesByDeps;
