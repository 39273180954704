import { useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { config } from "../../config";
import { setVisiteType } from "../../services/visitesService";

const VisiteTypeButton = ({ visite }) => {

    const typeVisiteLabel = config.visits_types.find(type => type.id === visite.type_visite);

    const [changeType, setChangeType] = useState(false);

    const onChangeType = (event) => {
        event.preventDefault();
        setChangeType(!changeType);
    }

    const tooltipTypeVisite = (props) => (
        <Tooltip id="tooltip-type-visite" {...props}>Modifier le type de visite</Tooltip>
    );

    const onTypeVisite = (event) => {
        setVisiteType(visite.uid, event.target.value);
    }

    if(changeType){
        return(
            <Form.Select aria-label="Choisir un type de visite" size="sm" onChange={onTypeVisite} defaultValue={visite.type_visite}>
                {config.visits_types.map(type =>
                    type.id !== 'vstarch'
                    && type.id !== 'undefined'
                    && type.id !== 'vs4themes'
                    && <option key={type.id} value={type.id}>{type.name}</option>
                )}
            </Form.Select>
        );
    } else {
        return(
            <small className="visite-type-label">
                <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={tooltipTypeVisite}>
                    <a href={visite.uid} title="Modifier le type de visite" onClick={(e) => onChangeType(e)}>{typeVisiteLabel.name}</a>
                </OverlayTrigger>
            </small>
        );
    }
};

export default VisiteTypeButton;
