import { Card, Tabs, Tab } from "react-bootstrap";
import { getLocationByHLO } from "../../services/locationsService";
import useBiens from "../../services/useBiens";
import { getCurrentUser } from "../../services/usersService";
import InfosVisitesByDeps from "./infos-visites-by-deps";

const InfosVisites = () => {

    const user = getCurrentUser();
    const departements = user.deps.split(',').sort();

    const {
        visites,
        biensAvecVisiteNonScellee,
        biensSansVisite,
        biensSansAgrement,
        visitesNonScelleesActifs,
        visitesNonScelleesInactifs, 
        biensSansDateVisite,
        biensRefaire6Mois,
        biensRefaire5Ans
    } = useBiens();

    if(!visites.length) return <div></div>;

    return(
        <Card className="card-biens">
            <Card.Body>
                <div className="d-flex justify-content-between">
                    <Card.Title>Visites à effectuer</Card.Title>
                    <div className="status d-flex">
                        <span className="active">Actifs</span>
                        <span className="inactive">Inactifs</span>
                    </div>
                </div>
            </Card.Body>
            {departements.length > 1 ?
                <Tabs variant="pills">
                    <Tab eventKey="tous" title="Toutes">
                        <InfosVisitesByDeps
                            biensSansVisite={biensSansVisite}
                            biensSansAgrement={biensSansAgrement}
                            biensSansDateVisite={biensSansDateVisite}
                            visitesNonScelleesActifs={visitesNonScelleesActifs}
                            visitesNonScelleesInactifs={visitesNonScelleesInactifs}
                            biensAvecVisiteNonScellee={biensAvecVisiteNonScellee}
                            biensRefaire5Ans={biensRefaire5Ans}
                            biensRefaire6Mois={biensRefaire6Mois}
                        />
                    </Tab>
                    {departements.map(departement =>
                        {
                            const biensSansVisiteDep = biensSansVisite.filter(bien => bien.geo_dep_id === departement);
                            const biensSansAgrementDep = biensSansAgrement.filter(bien => bien.geo_dep_id === departement);
                            const biensSansDateVisiteDep = biensSansDateVisite.filter(bien => bien.geo_dep_id === departement);
                            const biensAvecVisiteNonScelleeDep = biensAvecVisiteNonScellee.filter(bien => bien.geo_dep_id === departement);
                            const biensRefaire5AnsDep = biensRefaire5Ans.filter(bien => bien.geo_dep_id === departement);
                            const biensRefaire6MoisDep = biensRefaire6Mois.filter(bien => bien.geo_dep_id === departement);
                            const visitesNonScelleesActifsDep = visitesNonScelleesActifs.filter(visite => getLocationByHLO(visite.loc_hlo).geo_dep_id === departement);
                            const visitesNonScelleesInactifsDep = visitesNonScelleesInactifs.filter(visite => getLocationByHLO(visite.loc_hlo).geo_dep_id === departement);
                            return (
                                <Tab key={departement} eventKey={departement} title={departement}>
                                    <InfosVisitesByDeps
                                        biensSansVisite={biensSansVisiteDep}
                                        biensSansAgrement={biensSansAgrementDep}
                                        biensSansDateVisite={biensSansDateVisiteDep}
                                        biensAvecVisiteNonScellee={biensAvecVisiteNonScelleeDep}
                                        biensRefaire5Ans={biensRefaire5AnsDep}
                                        biensRefaire6Mois={biensRefaire6MoisDep}
                                        visitesNonScelleesActifs={visitesNonScelleesActifsDep}
                                        visitesNonScelleesInactifs={visitesNonScelleesInactifsDep}
                                        departement={departement}
                                    />
                                </Tab>
                            );
                        }
                    )}
                </Tabs> :
                <InfosVisitesByDeps
                    biensSansVisite={biensSansVisite}
                    biensSansAgrement={biensSansAgrement}
                    biensSansDateVisite={biensSansDateVisite}
                    biensAvecVisiteNonScellee={biensAvecVisiteNonScellee}
                    biensRefaire5Ans={biensRefaire5Ans}
                    biensRefaire6Mois={biensRefaire6Mois}
                />
            }
        </Card>
    )
};

export default InfosVisites;
