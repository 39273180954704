import React from "react";
import { ListGroup } from "react-bootstrap";
import VisiteSommaireItem from "./visite-sommaire-item";

const VisiteSommaire = ({ data, visite, toggler, itemHandler, current }) => {

    const isInsolite = visite.type_bien === 'inso';
    const isWindow = navigator.appVersion.indexOf("Win") !== -1;

    return (
        <div className="sidebar sidebar-sommaire">
            <button className="btn sidebar-toggler" onClick={() => toggler('sommaire')}>
                <i className="icon icon-arrow-left"></i>
                <i className="icon icon-arrow-right"></i>
            </button>
            <div className={`sidebar-content${isWindow ? '' : ' hide-scrollbar'}`}>
                <ListGroup className="visite-sommaire" variant="flush">
                    {data.map(block => 
                        <VisiteSommaireItem key={block.id} block={block} isInsolite={isInsolite} current={current} itemHandler={itemHandler} />
                    )}
                </ListGroup>
            </div>
        </div>
    );
};

export default VisiteSommaire;
