import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router";
import { isLoggedIn, subscribeLoggedIn } from "../services/loginService";

const PrivateRoute = ({ children, ...rest }) => {

    const [loggedin, setLoggedin] = useState(isLoggedIn());

    useEffect(() => {
        
        const onLogout = (isloggedin) => {
            setLoggedin(isloggedin);
        }

        const unsubscribe = subscribeLoggedIn(onLogout);

        return () => unsubscribe(onLogout);
        
    }, []);

    return(
        <Route {...rest} render={() => {
            return loggedin ? children : <Redirect to="/identification" />
        }} />
    )
}

export default PrivateRoute;