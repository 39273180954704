import React, { Fragment, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FixedSizeList as List } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import ProprietaireItem from "../components/proprietaires/proprietaire-item";
import { subscribeProprios, getPropriosFiletered, getLocalProprios } from "../services/propriosService";
import useQuery from "../services/useQuery";

const defaultFilters = {
    texte: null,
    actifs: null,
    departement: null
}

const Proprietaires = () => {

    const searchParams = useQuery();

    const [proprios, setProprios] = useState([]);
    const [filters, setFilters] = useState(defaultFilters);

    useEffect(() => {

        let nextFilters = {...filters};

        if(searchParams.get('search')){
            nextFilters = {...nextFilters, texte: searchParams.get('search')}
        }

        if(searchParams.get('filter')){
            nextFilters = {...nextFilters, actifs: searchParams.get('filter')}
        }
        
        if(searchParams.get('departement')){
            nextFilters = {...nextFilters, departement: searchParams.get('departement')}
        }

        setFilters(nextFilters);

        const onData = (data) => {
            setProprios(data);
        }

        const unsubscribe = subscribeProprios(onData);

        setProprios(getLocalProprios());

        return () => unsubscribe(onData);
        // eslint-disable-next-line
    }, [searchParams]);

    const onActifs = (event) => {
        setFilters({...filters, actifs: event.target.value !== "false" ? event.target.value : null});
    }

    const onSearch = (event) => {
        const search = event.target.value !== '' ? event.target.value : null;
        setFilters({...filters, texte: search});
    }

    const clearSearch = () => {
        setFilters({...filters, texte: null});
    }

    useEffect(() => {
        const nextProprios = getPropriosFiletered(filters);
        setProprios(nextProprios);
    }, [filters]);

    const itemRenderer = ({data, index, style}) => {
        return(
            <div style={style}>
                <ProprietaireItem data={data[index]} />
            </div>
        );
    }

    return(
        <Fragment>
            <div className="proprios-header content-header bg-light px-3 d-flex align-items-center justify-content-between border-bottom">
                <h1 className="mb-0">{proprios.length} {proprios.length > 1  ? "propriétaires" : "propriétaire"}</h1>
                <div className="toolbar d-flex gap-2">
                    <Form.Group className="position-relative search-group">
                        <Form.Control type="text" placeholder="Votre recherche…" value={filters.texte || ''} onChange={onSearch} />
                        {filters.texte && <button className="btn btn-small position-absolute" onClick={clearSearch}><i className="icon icon-ios-close"></i></button>}
                    </Form.Group>
                    <Form.Select onChange={onActifs} value={filters.actifs ? filters.actifs : false}>
                        <option value={false}>Tous</option>
                        <option value="actif">Actifs</option>
                        <option value="inactif">Inactifs</option>
                    </Form.Select>
                </div>
            </div>
            <div className="content-body">
                    {(proprios.length > 0) ?
                        <AutoSizer>
                            {({ height, width }) => (
                            <List
                                height={height}
                                itemCount={proprios.length}
                                itemSize={100}
                                layout="vertical"
                                width={width} 
                                itemData={proprios}
                                itemKey={(index, data) => data[index].uid}
                            >
                                {itemRenderer}
                            </List>)}
                        </AutoSizer> :
                        <div></div>
                    }
                <div className="proprios">
                </div>
            </div>
        </Fragment>
    );
}

export default Proprietaires;
