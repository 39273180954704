import { config } from '../../config';
const linkStyle = {
    fontSize: "0.875rem",
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}

const FileItem = ({ file, deleteHandler, visiteUid }) => {

    const urlServerFiles = config.domain+config.apiData.dirServerFiles+visiteUid+"/";

    return (
        <div className="file-item d-flex gap-2 align-items-center">
            <a className="btn btn-outline-danger btn-sm" href={`${urlServerFiles}${file}`} onClick={(e) => deleteHandler(e, file)}>
                <i className='icon icon-trash' />
            </a>
            <a className='d-block' style={linkStyle} title={file} href={`${urlServerFiles}${file}`} target="_blank" rel="noreferrer">{file}</a>
        </div>
    )
}

export default FileItem;