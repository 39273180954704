import { Page, Text, View, Image, Font } from '@react-pdf/renderer';
import {getCurrentDate} from '../../services/utilities';
import Logotype from "../../images/logotype-en-tete.png";
import {getDateFromTimestamp, getDateLightFromTimestamp, getDateYearsAdded} from '../../services/utilities';
import { config } from '../../config';

import RobotoRegular from "../../fonts/Roboto-Regular.ttf";
import RobotoBold from "../../fonts/Roboto-Bold.ttf";
import CompteRenduHtml from './compte-rendu-html';

Font.register({
    family: 'Roboto',
    format: "truetype",
    src: RobotoRegular
});

Font.register({
    family: 'RobotoBold',
    format: "truetype",
    src: RobotoBold
});

const CompteRendu = ({ visite, proprio, bien, styles }) => {
    var cles = "";
    if( visite.type_bien === "inso" && visite.cles_calcul === 5){
        cles = "Insolite";
    }else{
        cles = (visite.cles && visite.cles !== 0) ? visite.cles : visite.cles_calcul;
    }

    const visiteType    = config.visits_types.find(type => type.id === visite.type_visite).name.toLowerCase();
    const dateVisite    = getDateFromTimestamp(visite.date_visite);
    const dateValidite  = getDateYearsAdded(visite.date_visite, 5);
    const today         = getCurrentDate();
    const contents      = (visite.contents && visite.contents.length > 0) ? visite.contents.find(content => !content.group_visite.startsWith('th_')) : null;

    let dateUpdate = getDateLightFromTimestamp(visite.date_update).replace("/", "_").replace("/", "_");
    document.title = "CR-"+visite.loc_hlo+"_"+dateUpdate+"_AGR";

    var gentile = "";
    if(proprio && proprio.gender){
        switch(proprio.gender) {
            case 'male_and_female':
                gentile = 'Madame et Monsieur';
                break;
            case 'male_or_female':
                gentile = 'Madame ou Monsieur';
                break;
            case 'male':
                gentile = 'Monsieur';
                break;
            case 'female':
                gentile = 'Madame';
                break;
            default:
                gentile = '';
                break;
        }
    }
    if( ! bien.geo_dep_id){
        bien.geo_dep_id = 0;
    }

    if( ! contents.content[0]){
        return (
            <Page size="A4" style={{ padding: '1.5cm', fontSize: '12pt', lineHeight: 1.5, fontFamily: 'Roboto' }}>
                <View style={{position: 'absolute', top: '1.98cm', left: '2cm'}}>
                    <Text style={{width: '100%'}}>Visite vide...</Text>
                </View>
            </Page>
        );
    }

    const superficie = contents ? contents.content[0].elements[0].valeur || 0 : 0;
    return(
        <Page size="A4" style={{ padding: '1.5cm', fontSize: '12pt', lineHeight: 1.5, fontFamily: 'Roboto' }}>
            <View style={{position: 'absolute', top: '1.98cm', left: 0}}>
                <View style={{fontSize: 8.5, lineHeight: 1.3}}>
                    <Image src={Logotype} style={{display: 'block', width: "7cm", height: '1.29cm', marginBottom: '0.5cm'}} />
                    <View style={{marginLeft: "1.78cm"}}>
                        <Text style={{width: '100%', fontFamily: 'RobotoBold'}}>{(bien.geo_dep_id)?config.deps[bien.geo_dep_id].nom.toUpperCase():''}</Text>
                        <>
                            <Text style={{width: '100%'}}>{visite.user_adress1}</Text>
                            <Text style={{width: '100%'}}>{visite.user_adress2}</Text>
                            <Text style={{width: '100%'}}>{visite.user_adress3}</Text>
                            <Text style={{width: '100%'}}>{visite.user_postal_code} {visite.user_commune}</Text>
                            <Text>--</Text>
                            <Text style={{width: '100%'}}>{visite.user_gender} {visite.user_firstname} {visite.user_lastname}</Text>
                            <Text style={{width: '100%'}}>{visite.user_email}</Text>
                            <Text style={{width: '100%'}}>T. {visite.user_tel}</Text>
                            <Text>--</Text>
                        </>
                        <Text style={{width: '100%', color: '#008CD2', fontFamily: 'RobotoBold'}}>clevacances.com</Text>
                    </View>
                </View>
                {proprio && <View style={{position: 'absolute', top: "2.8cm", left: "13cm", fontSize: 8.5, lineHeight: 1.3}}>
                    <Text style={{fontFamily: 'RobotoBold'}}>{`${gentile} ${proprio.firstname ? proprio.firstname : ''} ${proprio.lastname ? proprio.lastname : ''}`}</Text>
                    <Text>{proprio.adresse}{(proprio.adress2)?' - '+proprio.adress2:''}{(proprio.adress3)?' - '+proprio.adress3:''}</Text>
                    <Text>{proprio.postal_code} {proprio.commune}</Text>
                    <Text style={{marginTop: "0.6cm"}}>{(bien && bien.commune) ? bien.commune : ''}, le {today}</Text>
                </View>}
            </View>
            <View style={{marginTop: "6.5cm", backgroundColor: '#cd5c34', color: '#ffffff', padding: '10pt', textAlign: 'center', lineHeight: '1.25pt', fontWeight: "bold", fontSize: '16pt'}}>
                <Text>Compte-rendu de la {visiteType} Clévacances</Text>

                {visite.type_visite!=="vstcs"?<Text>Labellisation {cles} {cles !== "Insolite" ? (cles > 1 ? 'clés' : 'clé') : ''}</Text> :<Text></Text> }
            </View>
            <View style={styles.introduction}>
                <Text style={styles.paragraphe}>{gentile},</Text>
                <Text style={styles.paragraphe}>Nous avons le plaisir de vous communiquer ci-après, le compte-rendu de la visite de labellisation de votre hébergement.</Text>
                <View style={styles.flex}>
                    <View style={styles.col}>
                        <Text style={{fontFamily: 'RobotoBold'}}>Visite effectuée le : </Text>
                        <Text>{dateVisite}</Text>
                    </View>
                    <View style={styles.col}>
                        <Text style={{fontFamily: 'RobotoBold'}}>Par {visite.user_gender === 'Me.' ?'la référente':'le référent'} Clévacances :</Text>
                        <Text>{visite.user_gender} {visite.user_firstname} {visite.user_lastname}</Text>
                    </View>
                </View>
                <View style={styles.flex}>
                    <Text style={{fontFamily: 'RobotoBold'}}>Nature de la visite : </Text><Text >{visiteType}</Text>
                </View>
                <View>
                    <Text><Text style={{fontFamily: 'RobotoBold'}}>Nom commercial :</Text> {bien.name}</Text>

                    <Text><Text style={{fontFamily: 'RobotoBold'}}>Hébergement situé à  :</Text> {bien.adresse}{(bien.adress2) ? ' – '+bien.adress2 : ''}{(bien.adress3) ? ' – '+bien.adress3 : ''}, {bien.postal_code} {bien.commune}</Text>

                    <Text style={styles.flex}>
                        <Text style={{fontFamily: 'RobotoBold'}}>Superficie : </Text>
                        <Text>{superficie} m2</Text>
                    </Text>

                    {visite.type_visite!=="vstcs"?<Text style={styles.flex}>
                        <Text style={{fontFamily: 'RobotoBold'}}>Labellisé </Text>
                        <Text>{cles} {cles !== "Insolite" ? (cles > 1 ? 'clés' : 'clé') : ''} </Text>
                        <Text style={{fontFamily: 'RobotoBold'}}>pour </Text>
                        {bien && <Text>{bien.capacity} personnes</Text>}
                    </Text> :<Text></Text> }

                    <Text style={styles.flex}>
                        <Text style={{fontFamily: 'RobotoBold'}}>Agrément valable jusqu'au : </Text>
                        <Text>{dateValidite}</Text>
                    </Text>
                </View>
            </View>
            <View style={styles.marginBottom}>
                <Text style={[styles.intertitre, { marginBottom: 10}]}>Évaluation de la visite</Text>
                {(visite.cpt_rendu && visite.cpt_rendu.textpublic) && <View style={{backgroundColor: '#ededed', padding: 10}}>
                    <CompteRenduHtml html={visite.cpt_rendu.textpublic} />
                </View>}
            </View>
            <View>
                <Text style={styles.marginBottom}>{(visite.scelle && visite.type_visite !== "vstte" && visite.type_visite !== "vstcs" ) && "Nous vous adressons le certificat d’agrément à afficher dans votre hébergement."}</Text>
                <Text style={styles.marginBottom}>Nous restons à votre disposition pour tous compléments d’informations, et vous remercions pour l’intérêt que vous portez à notre label.</Text>
                <Text style={styles.bold}>L'Équipe Clévacances</Text>
            </View>
            <View style={{height: 80}}></View>
            <View>
                <Text style={styles.marginBottom}>
                    <Text style={[{color: '#f00'}]}>* ENGAGEMENT : </Text><Text style={[{display: 'inline'}]}>Si des aménagements et/ou équipements obligatoires vous ont été demandés pour valider le niveau de confort {cles} {cles !== "Insolite" ? (cles > 1 ? 'clés' : 'clé') : ''}, nous vous invitons à nous retourner une copie de ce compte-rendu et de nous joindre des photos et/ou les doubles de factures et/ou les tickets de caisse justifiant ces améliorations.</Text>
                </Text>
            </View>
        </Page>
    );
};

export default CompteRendu;
