import React, { useContext, useEffect, useState } from "react";
import { Badge, OverlayTrigger, Popover } from "react-bootstrap";
import { VisiteContext } from "../../services/visiteContext";

const CheckboxGroup = ({ block }) => {
    
    const { handleChange } = useContext(VisiteContext);

    block.cles = Number.isInteger(block.cles) ? [block.cles] : block.cles;

    const getScore = () => {
        return block.options.reduce((prev, current) => {
            return prev + (current.cles ? current.cles : 0);
        }, 0);
    }

    const clesCount = () => {

        const { max, min, defaut } = block.calcul;

        if(typeof(defaut) === 'boolean'){
            if(total >= min.total){
                block.valide = min.valide;
            } else {
                block.valide = defaut;
            }
        } else {
            if(total >= max.total){
                block.cles = max.cles[max.cles.length - 1];
            } else if(total >= min.total){
                block.cles = min.cles[min.cles.length - 1];
            } else {
                block.cles = defaut;
            }
        }


    }

    const [total, setTotal] = useState(getScore());

    const handleClick = (event, element) => {
        element.cles = event.target.checked ? block.calcul.increment : 0;
        setTotal(getScore());
    }

    const renderAide = (props, txt) => {
        return(
            <Popover id="aide" className="help-popover" {...props}>
                <Popover.Body>
                    <div dangerouslySetInnerHTML={{__html: txt}}></div>
                </Popover.Body>
            </Popover>
        );
    }
    
    useEffect(() => {
        if(block.calcul){
            clesCount();
            block.valeur = block.cles;
            handleChange(block.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [block, total]);

    return (
        <div className="checkbox-group mb-4">
            {block.titre && <label className="group-title">{block.titre}</label>}
            {block.options.map(checkbox => {
                const obligatoire = block.obligatoire ? ' form-obligatoire' : '';
                return <div key={checkbox.id} className={`form-check form-switch${obligatoire}`}>
                    <input className="form-check-input" 
                        type="checkbox" 
                        value={checkbox.titre} 
                        checked={checkbox.cles > 0} 
                        name={block.id} 
                        id={checkbox.id} 
                        onChange={(e) => handleClick(e, checkbox)} 
                    />
                    <label className="form-check-label" htmlFor={checkbox.id}>
                        {checkbox.aide ? <OverlayTrigger overlay={(props) => renderAide(props, checkbox.aide)} placement="auto">
                            <i className="icon icon-info fs-5 text-secondary"></i>
                            </OverlayTrigger> : <i className="spacer"></i>
                        }
                        <span>{checkbox.titre}</span>
                    </label>
                </div>
            })}
        </div>
    );
}

export default CheckboxGroup;