import React from "react";
import { Alert } from "react-bootstrap";
import { setSyncActive } from "../services/visitesService";

class ErrorBoundary extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            message: null,
            info: null
        };
    }

    static getDerivedStateFromError(error) {
        // Mettez à jour l'état, de façon à montrer l'UI de repli au prochain rendu.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Vous pouvez aussi enregistrer l'erreur au sein d'un service de rapport.
        // logErrorToMyService(error, errorInfo);
        this.setState({
            hasError: true,
            message: error,
            info: errorInfo.componentStack
        });

        setSyncActive(false);

        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // Vous pouvez afficher n'importe quelle UI de repli.
            return <div className="p-5">
                <h1>Une erreur s'est produite.</h1>
                <p>Veuillez contacter le service technique de Nomade en copiant le message d'erreur ci-dessous.</p>
                {(this.state.message || this.state.info) && <Alert variant="info">
                    {this.state.message && <p><strong>{this.state.message.toString()}</strong></p>}
                    {this.state.info && <p>{this.state.info}</p>}
                </Alert>}
            </div>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;