//import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, ListGroup, ListGroupItem } from "react-bootstrap";
import {getLastLocations} from "../../services/locationsService";
import { convertToFrenchDate } from "../../services/utilities";


const InfosNewlocations = () => {

    const lastLocations = getLastLocations(5);

    return(<Card className="card-biens">
        <Card.Body>
            <div className="d-flex justify-content-between">
                <Card.Title>Les nouvelles locations</Card.Title>
            </div>
        </Card.Body>
        <ListGroup variant="flush">
            {lastLocations.map(lastLocation => {
                return <ListGroupItem key={lastLocation.loc_hlo}>
                    <div className="d-flex justify-content-between gap-3">

                        <div className="title">
                            <Link to={`/locations?search=${lastLocation.loc_hlo}`}>{lastLocation.loc_hlo}</Link><br/>
                            <span className="text-muted">
                                <small className="text-nowrap">
                                    <span>Créée le {convertToFrenchDate(lastLocation.date_create)}</span>
                                </small>
                            </span>
                        </div>

                        <div className="d-flex title flex-grow-1"><p className="fst-italic">{lastLocation.name}</p></div>
                        <div className="status d-flex">{lastLocation.commune}</div>
                    </div>
                </ListGroupItem>
            })}
        </ListGroup>
    </Card>);
};

export default InfosNewlocations;
