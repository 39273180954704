import React, { useState, useContext } from "react";
import { VisiteContext } from "../../services/visiteContext";
import { Form } from "react-bootstrap";
import FormElement from "./formElement";
import { NextLevelContext } from "../../services/nextLevelContext";

const Div = ({ block }) => {

    const { handleChange } = useContext(VisiteContext);
    const { id, commentaire } = block;

    const [comment, setComment] = useState(commentaire);
    const editComment = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if(!commentaire){
            setComment(commentaire || " ");
        }
    }
    
    const updateComment = (event) => {
        setComment(event.target.value);
        handleChange(block.id, event);
    }
    
    const deleteComment = (event) => {
        event.preventDefault();
        setComment('');
        handleChange(block.id, event);
    }

    const nextLevelInputsCount = (input) => {
        return false;
    }

    return (
        <div className="group group-div">
            {!comment && 
                <a href={`#${id}`} className="d-block text-decoration-none text-success mb-3 pb-2" onClick={(e) => editComment(e)}>
                    <i className="icon icon-pencil"></i> <span>Ajouter un commentaire…</span>
                </a>
            }
            {comment && 
                <div className="commentaire-container mb-3">
                    <Form.Group>
                        <Form.Control as="textarea" rows={4} onChange={updateComment} value={commentaire} placeholder="Veuillez saisir un commentaire…" />
                    </Form.Group>
                    <div className="commentaire-controls text-end">
                        <a href="delete" className="text-danger text-decoration-none" onClick={(e) => deleteComment(e)}><span>Supprimer le commentaire</span></a>
                    </div>
                </div>
            }
            <NextLevelContext.Provider value={{ rootCles: block.cles, nextLevelInputsCount }}>
            {block.elements && block.elements.map((element, i) => <FormElement key={i} block={element} />)}
            </NextLevelContext.Provider>
        </div>
    );
}

export default Div;