import React, {useContext} from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { VisiteContext } from "../../services/visiteContext";

const Radio = ({ block }) => {

    const { handleChange } = useContext(VisiteContext);

    const renderAide = (props) => {
        return(
            <Tooltip id="aide" {...props}>
                <div dangerouslySetInnerHTML={{__html: block.aide}}></div>
            </Tooltip>
        );
    }

    return (
        <div className="form-check visit-form-check radio">
            <input 
                className="form-check-input" 
                type="radio" 
                checked={block.valeur || false} 
                name={block.nom} 
                id={block.id} 
                onChange={event => handleChange(block.id, event)} 
            />
            <label className="form-check-label" htmlFor={block.id}>
                {block.titre}
                {block.aide && <OverlayTrigger overlay={renderAide}>
                    <i className="icon icon-info fs-5 text-secondary"></i>
                    </OverlayTrigger>
                }
            </label>
        </div>
    );
}

export default Radio;