import React from "react";
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import {Link} from "react-router-dom";
import { useHistory } from 'react-router';
import DossierVisite from './dossier-visite';

const ViewerPDF = ({ visite, infos, isMobile, nextLevelInputs }) => {

    const history = useHistory();

    document.title = infos.documentTitle;

    if(isMobile){
        return(
            <PDFDownloadLink
                document={<DossierVisite visite={visite} infos={infos} />}
                fileName={infos.documentTitle}
                className="btn btn-outline-danger"
                title="Voir le dossier compte-rendu PDF"
            >
                {({ blob, url, loading, error }) =>
                    loading ? '...' : <i className="icon icon-pdf"></i>
                }
            </PDFDownloadLink>
        );
    }

    return(
        <>
            <PDFViewer width="100%" height="100%" style={{ height: 'calc(100vh - 50px)'}} showToolbar={true}>
                <DossierVisite visite={visite} infos={infos} nextLevelInputs={nextLevelInputs} />
            </PDFViewer>
            <div style={{position: "absolute", right: 45, top: 80, display: "flex", alignItems: "center", gap: 10}}>
                <Link
                    className="btn btn-outline-success"
                    style={{ backgroundColor: "#CD5C34", color: "white", fontSize: 24, border: "0 none", height: "48px"}}
                    to={`/visit/${visite.uid}`}
                    title="Voir cette visite"><i className="icon icon-eye"></i>
                </Link>
                <button
                    className="btn btn-outline-success"
                    style={{ backgroundColor: "#CD5C34", color: "white", fontSize: 22, border: "0 none", height: "48px"}}
                    onClick={() => history.push(`/visits/#${visite.uid}`)}
                    title="Retour">Retour
                </button>
                <PDFDownloadLink
                    document={<DossierVisite visite={visite} infos={infos} nextLevelInputs={nextLevelInputs} />}
                    fileName={infos.documentTitle}
                    className="btn btn-lg btn-danger"
                    style={{ right: "2rem", top: "5rem", backgroundColor: "#CD5C34" }}
                    title="Voir le dossier compte-rendu PDF"
                >
                    {({ blob, url, loading, error }) =>
                        loading ? '...' : "Télécharger"
                    }
                </PDFDownloadLink>
            </div>
        </>
    );
}

export default ViewerPDF;
