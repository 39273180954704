import { useEffect, useState } from "react"

const useInstallPrompt = () => {

    const [installPromptEvent, setInstallPromptEvent] = useState();

    useEffect(() => {

        const onBeforeInstallPrompt = (event) => {
            event.preventDefault();
            setInstallPromptEvent(event);
        }

        window.addEventListener('beforeinstallprompt', onBeforeInstallPrompt);

        return () => window.removeEventListener('beforeinstallprompt', onBeforeInstallPrompt);

    }, []);

    const onInstallDeclined = () => {
        setInstallPromptEvent(null);
    }

    const onInstallAccepted = () => {
        installPromptEvent.prompt();
        installPromptEvent.userChoice.then(choice => {
            setInstallPromptEvent(null);
        });
    }

    return [installPromptEvent, onInstallDeclined, onInstallAccepted];

}

export default useInstallPrompt;