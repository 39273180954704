import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { VisiteContext } from "../../services/visiteContext";

const Commentaire = ({ block }) => {

    const { handleChange } = useContext(VisiteContext);
    const { id, commentaire } = block;
    
    const [comment, setComment] = useState(commentaire);

    useEffect(() => {
        setComment(commentaire || "");
    }, [commentaire]);

    const editComment = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setComment(commentaire || " ");
    }

    const updateComment = (event) => {
        setComment(event.target.value);
        handleChange(block.id, event);
    }

    const deleteComment = (event) => {
        event.preventDefault();
        setComment('');
        handleChange(block.id, event);
    }

    return(
        <>
        {comment ?
            <div className="commentaire-container mb-3">
                <Form.Group>
                    <Form.Control as="textarea" rows={4} onChange={updateComment} value={commentaire} placeholder="Veuillez saisir un commentaire…" />
                </Form.Group>
                <div className="commentaire-controls text-end">
                    <a href="delete" className="text-danger text-decoration-none" onClick={(e) => deleteComment(e)}><span>Supprimer le commentaire</span></a>
                </div>
            </div> : 
            <a href={`#${id}`} className="d-block text-decoration-none text-success mb-3 pb-2 border-bottom" onClick={(e) => editComment(e)}>
                <i className="icon icon-pencil"></i> <span>Ajouter un commentaire…</span>
            </a>
        }
        </>
    )
}

export default Commentaire;