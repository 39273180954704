import { useHistory } from "react-router";
import { visiteDelete } from "../services/visitesService";
import { Button, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";

const ButtonDeleteVisite = ({visite}) => {

    let history = useHistory();

    const onDeleteVisite = () => {
        visiteDelete(visite).then(result => {
            history.push('/visits');
        });
    }

    const popover = (
        <Popover id="popover-effacer-visite">
            <Popover.Body>
                <p className="text-center fs-6">Êtes-vous sur de vouloir supprimer cette visite ?</p>
                <div className="d-flex justify-content-between">
                    <button className="btn btn-outline-secondary" onClick={() => document.body.click()}>Annuler</button>
                    <button className="btn btn-outline-danger" onClick={onDeleteVisite}>Supprimer</button>
                </div>
            </Popover.Body>
        </Popover>
    );

    const tooltip = (props) => (
        <Tooltip id="tooltip-effacer-visite" {...props}>
            Supprimer cette visite
        </Tooltip>
    );

    return(
        <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={tooltip}>
            <span>
                <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={popover}>
                    <Button className="btn btn-danger"><i className="fs-5 icon icon-trash" title="Supprimer la visite"></i></Button>
                </OverlayTrigger>
            </span>
        </OverlayTrigger>
    )
};

export default ButtonDeleteVisite;