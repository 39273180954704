import { config } from "../config";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { subscribeIsOnline } from "../services/isonlineService";
import { isLoggedIn, logout } from "../services/loginService";
import { getCurrentUser, subscribeUsers } from "../services/usersService";
import { stopReloadProteted } from "../services/utilities";
import PdfAide from "../images/nomade-aide.pdf";

const AppHeader = () => {

    // Gestion du status de la connexion
    const location = useLocation();
    const [isOnline, setIsOnline] = useState(window.navigator.onLine);
    const [connectedUser, setConnectedUser] = useState();

    const versions = config.versions;
    const email_error = config.email_error;
    const lastVersion = versions[versions.length - 1];
    const email_sujet = encodeURIComponent("Relevé : remarque / anomalie / bug");
    const email_body  = encodeURIComponent("Merci de décrire le plus précisement possible, vous pouvez joindre des captures si nécessaire.\n\nRemarque (amélioration, suggestion) : \n\nAnomalie (fonctionnement non adapté) :\n\n Bug (blocage, affichage d'erreur, perte de données...) :");

    useEffect(() => {

        const updateStatus = (status) => setIsOnline(status);
        const onUsers = (users) => setConnectedUser(getCurrentUser());

        const unsubscribeIsOnline = subscribeIsOnline(updateStatus);
        const unsubscribeCurrentUser = subscribeUsers(onUsers);

        return () => {
            unsubscribeIsOnline(updateStatus);
            unsubscribeCurrentUser(onUsers);
        }

    }, []);

    const toggleLogin = (event) => {
        if(isLoggedIn()){
            logout().then(result => {
                setConnectedUser();
            });
        }
    }

    const onBug = (event) => {
        event.preventDefault();
        stopReloadProteted();
        window.location = `mailto:${email_error}?subject=${email_sujet}&body=${email_body}`;
    }

    if(location.pathname === "/pwa"){
        return(<div></div>);
    }

    return (
        <div className="app-header d-flex align-items-center justify-content-between px-3">
            <div className="d-flex align-items-center">
                <div className="brand fs-5 me-5">
                    <NavLink to="/" exact={true}>Nomade </NavLink>
                    <NavLink to="/versions" exact={true}><small className="text-uppercase sm">v.{lastVersion.id}</small></NavLink>
                </div>
                {(isLoggedIn() && connectedUser) &&
                <nav className="nav pages-navigation">
                    <NavLink className="nav-link" to="/visits">Visites</NavLink>
                    <NavLink className="nav-link" to="/proprios">Propriétaires</NavLink>
                    <NavLink className="nav-link" to="/locations">Biens</NavLink>
                    <a className="nav-link" href={PdfAide} target="_blank" rel="noreferrer">Aide</a>
                </nav>}
            </div>
            <div className="d-flex gap-2 align-items-center">
                <a href={`mailto:${email_error}?subject=${email_sujet}&body=${email_body}`} title="Rapport de bug" className="d-flex" onClick={onBug}>
                    <i className="icon icon-bug fs-4"></i>
                </a>
                <div className="user-status position-relative">
                    {(isLoggedIn() && connectedUser) &&
                        <span>
                            <i className="icon icon-user fs-3"></i> {connectedUser.firstname} {connectedUser.lastname}
                            <Link to="/identification" onClick={(e) => toggleLogin(e)} className="btn-login" title="Identification">
                                <i className="icon icon-logout fs-3"></i>
                            </Link>
                        </span>
                    }
                </div>
                <div className="app-status">
                    {isOnline ? <i className="icon icon-online fs-3 text-info"></i> : <i className="icon icon-offline fs-3 text-warning"></i>}
                </div>
            </div>
        </div>
    )
};

export default AppHeader;
