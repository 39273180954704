import { ListGroup, ListGroupItem } from "react-bootstrap"
import { Link } from "react-router-dom";

const InfoBiensByDeps = ({ locations, chambres, insolites, hll, proprios, departement }) => {
    return (
        <ListGroup variant="flush">
            <ListGroupItem>
                <div className="d-flex justify-content-between">
                    <span className="title">Locations</span>
                    <div className="status d-flex">
                        <span className="active">
                            <Link to={`/locations?actifs=true&type=loc${departement ? `&departement=${departement}` : ''}`}>{locations.filter(location => location.state === 'actif').length}</Link>
                        </span>
                        <span className="inactive">
                            <Link to={`/locations?inactifs=true&type=loc${departement ? `&departement=${departement}` : ''}`}>{locations.filter(location => location.state === 'inactif').length}</Link>
                        </span>
                    </div>
                </div>
            </ListGroupItem>
            <ListGroupItem>
                <div className="d-flex justify-content-between">
                    <span className="title">Chambres</span>
                    <div className="status d-flex">
                        <span className="active">
                            <Link to={`/locations?actifs=true&type=ch${departement ? `&departement=${departement}` : ''}`}>{chambres.filter(location => location.state === 'actif').length}</Link>
                        </span>
                        <span className="inactive">
                            <Link to={`/locations?inactifs=true&type=ch${departement ? `&departement=${departement}` : ''}`}>{chambres.filter(location => location.state === 'inactif').length}</Link>
                        </span>
                    </div>
                </div>
            </ListGroupItem>
            <ListGroupItem>
                <div className="d-flex justify-content-between">
                    <span className="title">Insolites</span>
                    <div className="status d-flex">
                        <span className="active">
                            <Link to={`/locations?actifs=true&type=inso${departement ? `&departement=${departement}` : ''}`}>{insolites.filter(location => location.state === 'actif').length}</Link>
                        </span>
                        <span className="inactive">
                            <Link to={`/locations?inactifs=true&type=inso${departement ? `&departement=${departement}` : ''}`}>{insolites.filter(location => location.state === 'inactif').length}</Link>
                        </span>
                    </div>
                </div>
            </ListGroupItem>
            <ListGroupItem>
                <div className="d-flex justify-content-between">
                    <span className="title">Habitations légères de loisirs</span>
                    <div className="status d-flex">
                        <span className="active">
                            <Link to={`/locations?actifs=true&type=hll${departement ? `&departement=${departement}` : ''}`}>{hll.filter(location => location.state === 'actif').length}</Link>
                        </span>
                        <span className="inactive">
                            <Link to={`/locations?inactifs=true&type=hll${departement ? `&departement=${departement}` : ''}`}>{hll.filter(location => location.state === 'inactif').length}</Link>
                        </span>
                    </div>
                </div>
            </ListGroupItem>
            <ListGroupItem>
                <div className="d-flex justify-content-between">
                    <span className="title">Propriétaires</span>
                    <div className="status d-flex">
                        <span className="active">
                            <Link to={`/proprios?filter=actif${departement ? `&departement=${departement}` : ''}`}>{proprios.filter(proprio => proprio.state === 'actif').length}</Link>
                        </span>
                        <span className="inactive">
                            <Link to={`/proprios?filter=inactif${departement ? `&departement=${departement}` : ''}`}>{proprios.filter(proprio => proprio.state === 'inactif').length}</Link>
                        </span>
                    </div>
                </div>
            </ListGroupItem>
        </ListGroup>
    );
};

export default InfoBiensByDeps;