import { Page, Text, View, Image, Font } from '@react-pdf/renderer';

import LogoAgrement from "../../images/logo-agrement.png";
import Signature from "../../images/logo-signature.png";
import TitreChambre from "../../images/titre-chambre.png";
import TitreLocation from "../../images/titre-location.png";
import TitreInsolite from "../../images/titre-insolite.png";
import TitreHLL from "../../images/titre-HLL.png";
import img5 from '../../images/label-5-cles@3x.png';
import img4 from '../../images/label-4-cles@3x.png';
import img3 from '../../images/label-3-cles@3x.png';
import img2 from '../../images/label-2-cles@3x.png';
import img1 from '../../images/label-1-cle@3x.png';
import imgInso from '../../images/label-inso.png';
import imgLabelCleVerte from '../../images/pictoCleVerte.png';
import imgLabelEuroEco from '../../images/pictoEcoLabel.png';
import imgThemeEco from '../../images/pictoEnvironnement.png';
import { config } from '../../config';
import {getDateFromTimestamp, getYearYearsAdded} from '../../services/utilities';

const RobotoRegular = "../../fonts/Roboto-Regular.ttf";
const RobotoBold = "../../fonts/Roboto-Bold.ttf";

Font.register({
    family: 'Roboto',
    format: "truetype",
    src: RobotoRegular
});

Font.register({
    family: 'RobotoBold',
    format: "truetype",
    src: RobotoBold
})

const Agrement = ({ visite, bien, proprio }) => {

    let cles = "";
    if( visite.type_bien === "inso" && visite.cles_calcul === 5){
        cles = "Insolite";
    }else{
        cles = (visite.cles && visite.cles !== 0) ? visite.cles : visite.cles_calcul;
    }

    let labelEuroEco = false;
    let labelCleVerte = false;
    let themeEco = false;
    visite.contents.forEach(contentObject => {
        if( contentObject.group_visite === 'th_eco')
        {
            if(contentObject.valide) labelCleVerte = true;
            if(contentObject.valide2) labelEuroEco = true;
            
            if(!labelCleVerte && !labelEuroEco && contentObject.cles) themeEco = true;
        }
    });



    const typeBien = config.group_visit[bien.type].name.toLowerCase();
    //const dateValidite = getDateYearsAdded(visite.date_visite, 5);
    const dateValidite = getYearYearsAdded(visite.date_visite, 5);
    const dateVisite = getDateFromTimestamp(visite.date_visite);
    const imageCles = cles === 5 ? img5 : cles === 4 ? img4 : cles === 3 ? img3 : cles === 2 ? img2 : img1;
    const titreAgrement = visite.type_bien === 'ch' ? TitreChambre : visite.type_bien === 'loc' ? TitreLocation : visite.type_bien === 'inso' ? TitreInsolite : TitreHLL;
    let nomDepartement = "";
    var gentile = "";
    if(proprio && proprio.gender){
        switch(proprio.gender) {
            case 'male_and_female':
                gentile = 'Madame et Monsieur';
                break;
            case 'male_or_female':
                gentile = 'Madame ou Monsieur';
                break;
            case 'male':
                gentile = 'Monsieur';
                break;
            case 'female':
                gentile = 'Madame';
                break;
            default:
                gentile = '';
                break;
        }
    }
    if(bien.geo_dep_id){
        nomDepartement = config.deps[bien.geo_dep_id].nom.toUpperCase() || '';
    }else{
        bien.geo_dep_id = 0;
    }

    return(
        <Page size="A4" style={{fontSize: 12, lineHeight: 1.5, fontFamily: 'Roboto'}}>
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1cm'}}>
                <Image src={LogoAgrement} style={{width: '270pt', height: '54pt', marginTop: '54pt'}} />
            </View>
            <View style={{marginLeft: 44, marginRight: 40, position: 'absolute', top: '45mm', right: 0, bottom: '65mm', left: 0}}>
                <View style={{marginBottom: 30}}>
                    <Image src={titreAgrement} style={{width: '360pt'}} />
                </View>
                <View style={{marginBottom: 10}}>
                    <Text >Vous êtes dans un hébergement visité et homologué par {visite.user_gender} {visite.user_firstname} {visite.user_lastname}, représentant{visite.user_gender&&(visite.user_gender==='Me.'||visite.user_gender==='Mme') ?'e':''} territorial de Clévacances <Text style={{fontWeight: 'bold'}}>{nomDepartement}</Text> le <Text style={{fontWeight: 'bold'}}>{dateVisite}.</Text></Text>
                </View>
                {cles !== "Insolite" &&
                    <View style={{display: 'flex', flexDirection: 'row', marginBottom: 20}}>
                        <Text style={{width: '50%', marginRight: 5, marginTop: 20}}>Parmi les 5 niveaux de confort, ce logement a obtenu la garantie Clévacances : <Text style={{fontWeight: 'bold'}}>{cles} clé{cles>1 && "s"}</Text></Text>
                        <View style={{width: '40%',}}>
                            <Image src={imageCles} style={{height: 51, objectFit: 'contain', objectPositionX: 'right'}} />
                        </View>
                    </View>
                }
                {cles === "Insolite" &&
                    <View style={{display: 'flex', flexDirection: 'row', marginBottom: 20}}>
                        <Text style={{width: '35%', marginRight: 5, marginTop: 20}}><Text style={{fontWeight: 'bold'}}>Ce logement a obtenu l'agrément Clévacances {cles}</Text></Text>
                        <View style={{width: '40%',}}>
                            <Image src={imgInso} style={{height: 94, objectFit: 'contain', objectPositionX: 'left'}} />
                        </View>
                    </View>
                }

                <View style={{marginBottom: 20}}>
                    <Text style={{fontWeight: 'bold', color: '#cd5c34'}}>Référence de cette location</Text>
                    <Text><Text style={{fontWeight: 'bold'}}>Nom commercial :</Text> {bien.name}</Text>
                    <Text><Text style={{fontWeight: 'bold'}}>N° agrément Clévacances :</Text> {bien.agr}</Text>
                    <Text>
                        <Text style={{fontWeight: 'bold'}}>Propriétaire : </Text>
                        {`${gentile} ${(proprio && proprio.firstname) ? proprio.firstname : ''} ${(proprio && proprio.lastname) ? proprio.lastname : ''}`}
                    </Text>
                    <Text><Text style={{fontWeight: 'bold'}}>Adresse  :</Text> {bien.adresse}{(bien.adress2) ? ' – '+bien.adress2 : ''}{(bien.adress3) ? ' – '+bien.adress3 : ''}, {bien.postal_code} {bien.commune}</Text>
                </View>
                <View style={{marginBottom: 20}}>
                    {bien.nature && <Text>Nature du logement : {bien.nature.toLowerCase()}</Text>}
                    <Text>Type de logement : {typeBien}</Text>
                    <Text>Labellisée pour <Text style={{fontWeight: 'bold'}}>{bien.capacity} personnes</Text> maximum</Text>
                </View>
                <View style={{marginBottom: 20}}>
                    <Text style={{fontWeight: 'bold', color: '#cd5c34'}}>Ce certificat est conforme jusqu’en {dateValidite}*</Text>
                    <Text>* Sous condition de renouvellement de l’adhésion annuelle à Clévacances</Text>
                </View>

                { (labelEuroEco || labelCleVerte ) &&
                    <View style={{display: 'flex', flexDirection: 'row', marginBottom: 20}}>
                        <Text style={{width: '60%', marginRight: 15, marginTop: 10}}>Cet hébergement a obtenu la reconnaissance suivante : </Text>
                        <View style={{width: '40%', flexDirection: 'row'}}>
                            {(labelEuroEco)&&
                                <Image src={imgLabelEuroEco} style={{height: 60, objectFit: 'contain', objectPositionX: 'left'}} />
                            }
                            {(labelCleVerte)&&
                                <Image src={imgLabelCleVerte} style={{height: 60, objectFit: 'contain', objectPositionX: 'left'}} />
                            }
                        </View>
                    </View>
                }

                { themeEco &&
                    <View style={{display: 'flex', flexDirection: 'row', marginBottom: 20}}>
                        <Text style={{width: '60%', marginRight: 15, marginTop: 10}}>Cet hébergement a obtenu la thématique suivante : </Text>
                        <View style={{width: '40%', flexDirection: 'row'}}>
                            {(themeEco)&&
                                <Image src={imgThemeEco} style={{height: 60, objectFit: 'contain', objectPositionX: 'left'}} />
                            }
                        </View>
                    </View>
                }

                <View style={{position: 'absolute', bottom: '0', right: '0'}}>
                    <View style={{display: 'flex', justifyContent: 'center', width: '200pt', textAlign: 'center', position: 'absolute', right:'0'}}>
                        <Image src={Signature} style={{objectFit: 'contain'}} />
                        <Text style={{fontSize: '12pt', color: '#cd5c34', textTransform: 'uppercase', textAlign: 'center', marginTop: "-16pt"}}>{config.deps[bien.geo_dep_id].nom.toUpperCase()}</Text>
                        <>
                            <Text style={{width: '100%', fontSize: '10pt', lineHeight: '130%', marginTop: 10}}>{visite.user_adress1}</Text>
                            <Text style={{width: '100%', fontSize: '10pt', lineHeight: '130%'}}>{visite.user_adress2}</Text>
                            <Text style={{width: '100%', fontSize: '10pt', lineHeight: '130%'}}>{visite.user_adress3}</Text>
                            <Text style={{width: '100%', fontSize: '10pt', lineHeight: '130%'}}>{visite.user_postal_code} {visite.user_commune}</Text>
                            <Text style={{fontSize: '10pt', fontWeight: 'bold', lineHeight: '130%'}}>{visite.user_gender} {visite.user_firstname} {visite.user_lastname}</Text>
                            <Text style={{fontSize: '10pt', lineHeight: '130%'}}>{visite.user_email}</Text>
                            <Text style={{fontSize: '10pt', lineHeight: '130%'}}>{visite.user_tel}</Text>
                        </>
                    </View>
                </View>
            </View>
        </Page>
    );
}

export default Agrement;
