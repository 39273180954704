
let isOnline = false;

const subscribers = [];

window.addEventListener('online', () => {
    isOnline = true;
    _publish();
});

window.addEventListener('offline', () => {
    isOnline = false;
    _publish();
});

const subscribeIsOnline = (subscriber) => {
    if(subscribers.indexOf(subscriber) === -1){
        subscribers.push(subscriber);
        return _unsubscribe;
    }
}

const _unsubscribe = (subscriber) => {
    const index = subscribers.indexOf(subscriber);
    if(index !== -1){
        subscribers.splice(index, 1);
    }
}

const _publish = () => {
    subscribers.forEach(subscriber => {
        subscriber(isOnline);
    });
}

export { subscribeIsOnline }