import { Card, Tab, Tabs } from "react-bootstrap";
import { getBiens } from "../../services/locationsService";
import { getLocalProprios } from "../../services/propriosService";
import { getCurrentUser } from "../../services/usersService";
import InfoBiensByDeps from "./info-biens-by-deps";

const InfosLocations = () => {

    const user = getCurrentUser();
    const departements = user.deps.split(',').sort();
    const biens = getBiens();
    const biensActif = biens.filter(bien => bien.state === 'actif');
    const locations = biens.filter(bien => bien.type === 'loc');
    const chambres = biens.filter(bien => bien.type === 'ch');
    const insolites = biens.filter(bien => bien.type === 'inso');
    const hll = biens.filter(bien => bien.type === 'hll');
    const proprios = getLocalProprios();

    return(
        <Card className="card-biens">
            <Card.Body>
                <div className="d-flex justify-content-between">
                    <Card.Title>{biensActif.length} biens actifs dans {departements.length > 1 ? 'les départements' : 'le département'} {departements.join(', ')}</Card.Title>
                    <div className="status d-flex">
                        <span className="active">Actifs</span>
                        <span className="inactive">Inactifs</span>
                    </div>
                </div>
            </Card.Body>
            {departements.length > 1 ?
                <Tabs variant="pills">
                    <Tab eventKey="tous" title="Tous">
                        <InfoBiensByDeps locations={locations} chambres={chambres} insolites={insolites} hll={hll} proprios={proprios} />
                    </Tab>
                    {departements.map(departement =>
                        {
                            const locationsDep = locations.filter(location => location.geo_dep_id === departement);
                            const chambresDep = chambres.filter(location => location.geo_dep_id === departement);
                            const insolitesDep = insolites.filter(location => location.geo_dep_id === departement);
                            const hllDep = hll.filter(location => location.geo_dep_id === departement);
                            const propriosDep = proprios.filter(proprio => (proprio.postal_code)?proprio.postal_code.startsWith(departement<10?"0"+departement:departement):'');

                            return (
                                <Tab key={departement} eventKey={departement} title={departement}>
                                    <InfoBiensByDeps locations={locationsDep} chambres={chambresDep} insolites={insolitesDep} hll={hllDep} proprios={propriosDep} departement={departement} />
                                </Tab>
                            );
                        }
                    )}
                </Tabs> :
                <InfoBiensByDeps locations={locations} chambres={chambres} insolites={insolites} hll={hll} proprios={proprios} />
            }
        </Card>
    )
};

export default InfosLocations;
