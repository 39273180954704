import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ButtonDuplicateBloc = ({ id, action }) => {

    const tooltipDuplicate = (props) => (
        <Tooltip id="tooltip-dupliquer-bloc" {...props}>Dupliquer ce bloc</Tooltip>
    );

    return (
        <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={tooltipDuplicate}>
            <a href={`#${id}`} className="btn text-success" onClick={(e) => action(e)}><i className="icon icon-copy fs-3"></i></a>
        </OverlayTrigger>
    );

};

export default ButtonDuplicateBloc;