import { Button, ButtonGroup } from "react-bootstrap";
import { EditorContent } from "@tiptap/react";

const TextEditor = ({ editor }) => {
    
    const onClear = () => {
        editor.commands.clearContent(true);
    }

    return (
        <div className="border border-top-0 p-3">
            <div className="editor-menu d-flex">
                <ButtonGroup>
                    <Button variant='outline-secondary' size="sm" onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()} className={editor.isActive('heading', { level: 1 }) && 'active'}>
                        <i className="icon icon-h1"></i>
                    </Button>
                    <Button variant='outline-secondary' size="sm" onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()} className={editor.isActive('heading', { level: 2 }) && 'active'}>
                        <i className="icon icon-h2"></i>
                    </Button>
                    <Button variant='outline-secondary' size="sm" onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()} className={editor.isActive('heading', { level: 4 }) && 'active'}>
                        <i className="icon icon-h3"></i>
                    </Button>
                    <Button variant='outline-secondary' size="sm" onClick={() => editor.chain().focus().setParagraph().run()} className={editor.isActive('paragraph') && 'active'}>
                        <i className="icon icon-paragraph"></i>
                    </Button>
                    <Button variant='outline-secondary' size="sm" onClick={() => editor.chain().focus().toggleBulletList().run()} className={editor.isActive('bulletList') && 'active'}>
                        <i className="icon icon-ul"></i>
                    </Button>
                </ButtonGroup>
                <ButtonGroup>
                    <Button variant='outline-secondary' size="sm" onClick={() => editor.chain().focus().toggleBold().run()} className={editor.isActive('bold') && 'active'}>
                        <i className="icon icon-bold"></i>
                    </Button>
                    <Button variant='outline-secondary' size="sm" onClick={() => editor.chain().focus().toggleItalic().run()} className={editor.isActive('italic') && 'active'}>
                        <i className="icon icon-italic"></i>
                    </Button>
                </ButtonGroup>
                <ButtonGroup>
                    <Button variant='outline-secondary' size="sm" onClick={() => editor.chain().focus().undo().run()}>
                        <i className="icon icon-undo"></i>
                    </Button>
                    <Button variant='outline-secondary' size="sm" onClick={() => editor.chain().focus().redo().run()}>
                        <i className="icon icon-redo"></i>
                    </Button>
                </ButtonGroup>
                <ButtonGroup>
                    <Button variant='outline-danger' size="sm" onClick={onClear}>Effacer le commentaire</Button>
                </ButtonGroup>
            </div>
            <EditorContent editor={editor} className="editor-input mb-3" />
        </div>
    );
}

export default TextEditor;