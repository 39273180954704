import { useContext, useEffect, useState } from "react"
import { NextLevelContext } from "./nextLevelContext"

const useNextLevel = ({ block }) => {

    const { rootCles, nextLevelInputsCount } = useContext(NextLevelContext);
    const [isNextLevel, setIsNextLevel] = useState(false);
    
    useEffect(() => {

        let isMounted = true;

        const findClesFromChildren = (elements) => {

            const foundCles = elements.reduce((result, element) => {
                if(element.cles && !element.facultatif){
                    const nextCles = Array.isArray(element.cles) ? element.cles : [element.cles];
                    return [...new Set([...result, ...nextCles])];
                } else {
                    if(element.elements){
                        const nextCles = findClesFromChildren(element.elements)
                        return [...new Set([...result, ...nextCles])];
                    } else {
                        return result;
                    }
                }
            }, []);

            return foundCles;
        }

        if(isMounted){

            switch(block.type){

                case "checkbox":

                    if(block.cles && !block.valeur && !block.facultatif && block.cles.includes(rootCles + 1)){
                        setIsNextLevel(true);
                        nextLevelInputsCount({id: block.id, isNext: true});
                    } else {
                        setIsNextLevel(false);
                        nextLevelInputsCount({id: block.id, isNext: false});
                    }
                    break;

                case "radio":

                    const radioSelected = block.range && block.range.includes(rootCles + 1);
                    block.cles = block.cles && Array.isArray(block.cles) ? block.cles : block.cles && !Array.isArray(block.cles) ? [block.cles] : null;
                    const radioUnselected = block.cles && !block.active && block.cles.includes(rootCles + 1);
                    
                    if(radioSelected){
                        setIsNextLevel(false);
                        nextLevelInputsCount({id: block.id, isNext: false});
                    } else if(radioUnselected){
                        // Vérifier si le niveau de clé n'est pas déjà atteint au niveau du groupe
                        const parentIsRadioGroup = block.parent && block.parent.type === 'radiogroup';
                        const itemSelected = parentIsRadioGroup && block.parent.elements.find(element => element.valeur === block.parent.valeur);
                        if(itemSelected && itemSelected.cles && itemSelected.cles[itemSelected.cles.length - 1] >= rootCles + 1){
                            setIsNextLevel(false);
                            nextLevelInputsCount({id: block.id, isNext: false});
                        } else {
                            setIsNextLevel(true);
                            nextLevelInputsCount({id: block.id, isNext: true});
                        }
                    } else if(radioSelected === undefined && radioUnselected === undefined) {
                        if(block.elements && !block.active && !block.facultatif){
                            // Rechercher les clés parmi les enfants
                            const nextCles = findClesFromChildren(block.elements);
                            if(nextCles.length && nextCles.includes(rootCles + 1)){
                                setIsNextLevel(true);
                                nextLevelInputsCount({id: block.id, isNext: true});
                            }
                        }
                    } else {
                        setIsNextLevel(false);
                        nextLevelInputsCount({id: block.id, isNext: false});
                    }

                    break;
                case "radiogroup":
                    break;
                default:
                    setIsNextLevel(false);
                    break;
            }
        }

        return () => {
            isMounted = false;
        }

    }, [block, block?.valeur, block?.range, rootCles, nextLevelInputsCount]);

    return { isNextLevel };

}

export default useNextLevel;