import { Document, StyleSheet, Font } from '@react-pdf/renderer';

import CompteRendu from './compte-rendu';
import Agrement from './agrement';

import RobotoRegular from "../../fonts/Roboto-Regular.ttf";
import RobotoBold from "../../fonts/Roboto-Bold.ttf";
import VisiteNextLevel from './visite-next-level';

Font.register({
    family: 'Roboto',
    format: "truetype",
    src: RobotoRegular
});

Font.register({
    family: 'RobotoBold',
    format: "truetype",
    src: RobotoBold
});

const styles = StyleSheet.create({
    page: { padding: '1.cm', fontSize: '12pt', lineHeight: 1.5 },
    header: { position: 'absolute', top: '1.98cm', left: '0', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1cm'},
    logotype: { display: 'block', width: "7cm", height: '1.29cm', marginBottom: 10 },
    destinataire: {width: "8cm", paddingTop: "1cm", lineHeight: '1.5pt' },
    date: { paddingTop: '1cm' },
    pageTitle: { backgroundColor: '#cd5c34', color: '#ffffff', padding: '10pt', textAlign: 'center', lineHeight: '1.25pt', fontFamily: 'RobotoBold', fontSize: '16pt' },
    introduction: { paddingTop: 20, marginBottom: 20},
    paragraphe: { marginBottom: 12 },
    intertitre: { color: '#cd5c34', textAlign: 'center', borderBottom: '1px solid #000', paddingBottom: 5, textTransform: 'uppercase', fontFamily: 'RobotoBold'},
    flex: { display: 'flex', flexDirection: 'row' },
    col: { width: '45%' },
    marginBottom: { marginBottom: 10 }
});

const DossierVisite = ({ visite, infos, nextLevelInputs }) => {

    const { proprio, bien, documentTitle } = infos;

    return(
        <Document title={documentTitle}>
            <CompteRendu visite={visite} proprio={proprio} bien={bien} styles={styles} />
            {(visite.scelle && visite.type_visite !== 'vstte' && visite.type_visite !== 'vstcs') && <Agrement visite={visite} bien={bien} proprio={proprio} styles={styles} />}
            {nextLevelInputs && <VisiteNextLevel nextLevelInputs={nextLevelInputs} cles={visite.cles>0?visite.cles:visite.cles_calcul} />}
        </Document>
    );
}

export default DossierVisite;
