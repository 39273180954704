import { useState } from "react";
import { Button, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { getLocationByHLO, getLocationsByProprio } from "../services/locationsService";
import { getProprioByUID } from "../services/propriosService";
import ModalBiensParProprio from "./modal-biens-par-proprio";
import { useLocation } from 'react-router-dom';
import { setSyncActive } from "../services/visitesService";

const ButtonDuplicateVisite = ({ visite }) => {

    const location = useLocation();

    const bien = getLocationByHLO(visite.loc_hlo);
    const proprio = bien ? getProprioByUID(bien.props.split(',')[0]) : null;
    
    const propsUids = bien ? bien.props.split(',') : null;
    const props = propsUids ? propsUids.map(propUid => getProprioByUID(propUid)) : null;
    let tousLesBiens = [];
    props && props.forEach(prop => {
        const biensDuProp = getLocationsByProprio(prop.uid);
        tousLesBiens = tousLesBiens.concat(biensDuProp);
    });
    
    const biens = tousLesBiens.filter(location => location.type === visite.type_bien);

    const [showModal, setShowModal] = useState(false);

    const onDuplicateVisite = () => {
        if(location.pathname.startsWith('/visits')){
            setSyncActive(false);
        }
        document.body.click(); // fermeture du popover
        setShowModal(true); // Affichage de la modale pour la sélection des biens du proprio
    }

    const popover = (
        <Popover id="popover-dupliquer-visite">
            <Popover.Body>
                <p className="text-center fs-6">Êtes-vous sur de vouloir dupliquer cette visite ?</p>
                <div className="d-flex justify-content-between">
                    <button className="btn btn-outline-secondary" onClick={() => document.body.click()}>Annuler</button>
                    <button className="btn btn-outline-success" onClick={onDuplicateVisite}>Dupliquer</button>
                </div>
            </Popover.Body>
        </Popover>
    );

    const tooltip = (props) => (
        <Tooltip id="tooltip-dupliquer-visite" {...props}>
            Dupliquer cette visite
        </Tooltip>
    );

    const hideHandler = () => {
        setShowModal(false);
        if(location.pathname.startsWith('/visits')){
            setSyncActive(true);
        }
    }

    return(
        <>
            <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={tooltip}>
                <span>
                    <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={popover}>
                        <Button className="btn btn-info text-white" title="Dupliquer cette visite"><i className="fs-5 icon icon-copy"></i></Button>
                    </OverlayTrigger>
                </span>
            </OverlayTrigger>
            {showModal && <ModalBiensParProprio 
                show={showModal} 
                onHide={() => hideHandler()} 
                biens={biens} 
                proprios={props}
                proprio={proprio} 
                currenthlo={visite.loc_hlo} 
                visiteuid={visite.uid} 
            />}
        </>
    )
};

export default ButtonDuplicateVisite;