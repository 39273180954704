import { useState, useEffect, useRef } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getCurrentUser } from "../../services/usersService";
import { getCurrentVisite, setCurrentVisite } from "../../services/visitesService";
import { config } from '../../config';

const ClesEstimees = ({ clesCalcul }) => {

    const visite = getCurrentVisite();
    const user = getCurrentUser();
    const clesMax = config.autorisations.clesEstimees[user.level];

    const [clesEstimees, setClesEstimees] = useState(visite.cles);

    /**
     * Marqueur pour savoir si le composant vient d'étre monté
     * afin de ne pas supprimer la clé estimée reçue lors
     * de l'instanciation du composant
     */
     const isPristine = useRef(true);
     

    /**
     * Passe le marqueur isPristine à false 
     * après une seconde
     */
    useEffect(() => {
        setTimeout(()=>{
            isPristine.current = false;
        }, 1000);
    }, []);

    /**
     * Supprime les clés estimées si la valeur
     * des clés calculées a changé et si le
     * marqueur est à false.
     */
    useEffect(() => {
        if(!isPristine.current){
            setClesEstimees(null);
        }
        
    }, [clesCalcul]);
    
    useEffect(() => {
        if(clesEstimees !== undefined){
            setCurrentVisite({ cles: clesEstimees, cles_calcul: clesCalcul });
        }
        // eslint-disable-next-line
    }, [clesEstimees]);

    const isPossibleValue = (value) => {
        const estimationMin = clesCalcul - 1;
        const estimationMax = clesCalcul + 1 > clesMax ? clesMax : clesCalcul + 1;
        let range = [];
        if(estimationMin !== clesCalcul) range.push(estimationMin);
        if(estimationMax !== clesCalcul) range.push(estimationMax);
        return !range.includes(value);
    }

    const tooltipClesEstimees = (props) => (
        <Tooltip id="tooltip-cles-estimees-visite" {...props}>Clé estimée, limtée à +1 et -1 de la clé calculée.</Tooltip>
    );

    return(
        <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={tooltipClesEstimees}>
            <Dropdown>
                <Dropdown.Toggle size="sm">{clesEstimees ? clesEstimees : '/'}</Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item eventKey="0" active={clesEstimees === null} onClick={() => setClesEstimees(null)}>-</Dropdown.Item>
                    <Dropdown.Item eventKey="1" active={clesEstimees === 1} disabled={isPossibleValue(1)} onClick={() => setClesEstimees(1)}>1 clé</Dropdown.Item>
                    <Dropdown.Item eventKey="2" active={clesEstimees === 2} disabled={isPossibleValue(2)} onClick={() => setClesEstimees(2)}>2 clés</Dropdown.Item>
                    <Dropdown.Item eventKey="3" active={clesEstimees === 3} disabled={isPossibleValue(3)} onClick={() => setClesEstimees(3)}>3 clés</Dropdown.Item>
                    <Dropdown.Item eventKey="4" active={clesEstimees === 4} disabled={isPossibleValue(4)} onClick={() => setClesEstimees(4)}>4 clés</Dropdown.Item>
                    <Dropdown.Item eventKey="5" active={clesEstimees === 5} disabled={isPossibleValue(5)} onClick={() => setClesEstimees(5)}>5 clés</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </OverlayTrigger>
    )
}

export default ClesEstimees;
