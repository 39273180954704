import { useEffect, useState } from "react";
import { Card, ListGroup, Badge, Dropdown, DropdownButton, ButtonGroup } from "react-bootstrap";
import { config } from "../../config";
import { db } from '../../services/indexedDBService';
import { deleteThemeFromServer, getElementsCount, getScoreFromTheme } from "../../services/themeService";
import useTheme from "../../services/useTheme";
import { VisiteContext } from "../../services/visiteContext";
import { saveCurrentVisite } from "../../services/visitesService";
import ModaleTheme from "../modal-theme";
import { ThemeProvider } from "../../context/theme-context";

const CardVisiteThemes = ({ visite }) => {

    const [themes, setThemes] = useState([]);
    const [availablesThemes, setAvailablesThemes] = useState([]);
    const [currentTheme, setCurrentTheme] = useState();
    const [canUpdate, setCanUpdate] = useState(true);

    const { updateTheme, saveTheme, loadTheme, deleteThemeFromDB } = useTheme();

    useEffect(() => {

        if(visite){

            //setCanUpdate(canUpdateVisite(visite));
            setCanUpdate(true);

            if(visite.contents){
                setThemes(visite.contents.filter(content => content.group_visite.startsWith('th_')));
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visite]);

    useEffect(() => {

        const allThemes = Object.keys(config.group_visit).filter(key => key.startsWith('th_'));

        const nextThemes = allThemes.reduce((result, item) => {
            const existe = themes.findIndex(theme => theme.group_visite === item) !== -1;
            if(!existe) {
                const themeItem = {...config.group_visit[item], id: item}
                result.push(themeItem);
            }
            return result;
        }, []);

        setAvailablesThemes(nextThemes);

    }, [themes]);

    const handleChange = (blockid, event) => {
        const themeUpdated = updateTheme(currentTheme, blockid, event);
        setCurrentTheme({...themeUpdated});
        saveTheme(themeUpdated).then(id => updateVisite(themeUpdated));
    }

    const loadCurrentTheme = (theme) => {
        loadTheme(theme, visite.uid).then(result => {
            const nextThemes = themes;
            nextThemes.push({...result});
            setThemes([...nextThemes]);
            setCurrentTheme({...result});
            saveTheme({...result}).then(id => updateVisite({...result}));
        });
    }

    const updateVisite = (theme) => {
        const nextVisite = visite;
        const oldContentIndex = nextVisite.contents.findIndex(content => content.group_visits === theme.group_visit);
        nextVisite.contents.splice(oldContentIndex, 1);
        nextVisite.contents.push(theme);
        if(window.navigator.onLine){
            saveCurrentVisite(nextVisite);
        }
    }

    const deleteTheme = () => {
        deleteThemeFromDB(currentTheme).then(result => {
            deleteThemeFromServer(currentTheme).then(serverResult => {
                if(!serverResult) console.warn("Le serveur n'a pas supprimé le thème");
                const nextThemes = themes;
                nextThemes.splice(themes.indexOf(currentTheme), 1);
                setThemes([...nextThemes]);
                setCurrentTheme(null);
            });
        });
    }

    const onUpdate = () => {
        setTimeout(async() => {
            const result = await db.visits_content.where('uid').equals(visite.uid).toArray();
            const nextThemes = result.filter(theme => theme.group_visite.startsWith('th_'));
            const currentThemeIndex = nextThemes.findIndex(t => t.group_visite === currentTheme.group_visite);
            const themeUpdated = nextThemes[currentThemeIndex];
            setCurrentTheme(themeUpdated);
    
            updateVisite(themeUpdated);
            setThemes(nextThemes);
        }, 150);
    }

    const renderThemes = () => {
        return (
            themes.map((theme, index) => {

                // if(!theme.content) return;

                const totalToCheck = getElementsCount(theme.content[0].elements);
                const checkeds = getScoreFromTheme(theme);
                const isValide = theme.valide ? true : checkeds >= totalToCheck;

                return (
                    <ListGroup.Item key={index}>
                        <div className="theme-item" onClick={(e) => canUpdate ? setCurrentTheme(theme) : false}>
                            {isValide ?
                                <Badge bg="success" pill><i className="icon icon-check"></i></Badge> :
                                <Badge bg="info" pill>{checkeds}/{totalToCheck}</Badge>
                            }
                            <div className="theme-item-details">
                                <span className="titre">{theme.content[0].titre}</span>
                            </div>
                        </div>
                    </ListGroup.Item>
                );
            })
        );
    }

    return(
        <Card className="card-themes">
            <Card.Header>
                <div className="d-flex justify-content-between">
                    <span className="card-title">Thèmes</span>
                    {(availablesThemes.length > 0 ) &&
                        <DropdownButton as={ButtonGroup} drop="end" variant="outline-success" title={<i className="icon icon-plus me-3"></i>}>
                            {availablesThemes.map(theme => <Dropdown.Item key={theme.id} eventKey={theme.name} onClick={() => loadCurrentTheme(theme)}>{theme.name}</Dropdown.Item>)}
                        </DropdownButton>
                    }
                </div>
            </Card.Header>
            <ListGroup variant="flush">
                {(themes && themes.length) ? renderThemes() : <ListGroup.Item>Aucun thème pour cette visite</ListGroup.Item>}
            </ListGroup>
            {currentTheme && <VisiteContext.Provider value={{ handleChange, deleteTheme }}>
                <ThemeProvider>
                    <ModaleTheme theme={currentTheme} show={currentTheme !== null} onHide={() => setCurrentTheme(null)} onUpdate={onUpdate} />
                </ThemeProvider>
            </VisiteContext.Provider>}
        </Card>
    );
};

export default CardVisiteThemes;
