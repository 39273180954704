import { useState, useEffect, useRef, useCallback } from 'react';
import { getLocationByHLO } from './locationsService';
import { setCurrentVisite, getVisiteById, validateTheme, deleteVisiteContent, getVisiteContents, setSyncActive } from './visitesService';
import { deleteThemeFromServer, getThemeIsPristine, loadThemeContent } from './themeService';
import useBlock from './useBlock';
import useGroupeCles from './useGroupeCles';
import useSave from './useSave';
import { getTimestamp } from './utilities';

const useVisite = (id) => {

    const [visite, setVisite] = useState(null);
    const [elements, setElements] = useState([]);
    const [selectedTheme, setSelectedTheme] = useState(null);
    const [copy, setCopy] = useState();
    
    const { getNewElements, deleteBlock } = useBlock();
    const locationRef = useRef();
    const clesCalculees = useGroupeCles(elements);

    const { saveVisite } = useSave();

    const handleChange = (blockid, event) => {

        let newElements = [];

        if(blockid.includes('th_')){
            if(selectedTheme){
                newElements = [...selectedTheme.content];
            }
        } else {
            newElements = [...elements];
        }

        const updateValues = (block => {

            const { type, id } = block;
            if (blockid === id) {

                switch (type) {
                    case 'radio':
                    case 'checkbox':
                        block['valeur'] = event.target.checked;
                        break;
                    case 'checkboxgroup':
                        break;
                    case 'radiogroup':
                        break;
                    case 'text':
                        block['valeur'] = event.target.value;
                        break;
                    case 'date':
                        block['valeur'] = event.target.value;
                        break;
                    case 'h1':
                    case 'div':
                        if(event.target.type === "text"){
                            block["titreperso"] = event.target.value;
                        } else {
                            block["commentaire"] = event.target.value;
                        }
                        break;
                    default:
                        block['valeur'] = event.target.value;
                        break;
                }
            } else {
                if(block.elements){
                    block.elements.forEach(element => updateValues(element));
                }
            }
        });

        newElements.forEach(block => {
            updateValues(block);
        });

        if(blockid.includes('th_')){
            const calculIsValide = validateTheme(newElements);
            const nextThemeContent = {...selectedTheme, content: [...newElements], cles: calculIsValide};
            setSelectedTheme({...nextThemeContent});
        } else {
            setElements([...newElements]);
        }
    }

    const duplicateElement = async (newElement) => {
        await getNewElements(visite, newElement).then(newContent => {
            if(newContent && newContent.elements){
                setElements([...newContent.elements]);
                setCopy(newContent.id);
            }
        });
    }

    const removeElement = async (node) => {

        await deleteBlock(visite, node)
        .then(async result => {
            if(result){

                setCopy(null);
    
                const dataToSave = [{
                    content: [...result],
                    uid: visite.uid,
                    group_visite: visite.type_bien
                }];
        
                await setCurrentVisite({
                    contents: [...dataToSave],
                    cles_calcul: clesCalculees
                });
                
                setElements([...result]);
            }
        });

    }

    const deleteTheme = () => {
        selectedTheme.cles = 0;
        selectedTheme.valide = false;
        deleteVisiteContent(selectedTheme).then(response => {
            deleteThemeFromServer(selectedTheme);
            setSelectedTheme(null);
        });
    }

    const saveContents = useCallback(async () => {

        if(!visite) return;

        const dataToSave = [{
            content: [...elements],
            uid: visite.uid,
            group_visite: visite.type_bien
        }];

        if(selectedTheme){
            if(!getThemeIsPristine(selectedTheme)){
                dataToSave.push(selectedTheme);
            }
        }

        visite.date_update = getTimestamp();
        visite.cles_calcul = clesCalculees;

        // Enregistrement de la visite
        await saveVisite({...visite, contents: dataToSave});

        // eslint-disable-next-line 
    },[visite, elements, selectedTheme]);

    const loadTheme = (theme) => {
        loadThemeContent(theme, visite.uid).then(data => {
            setSelectedTheme({...data});
        });
    }

    const updateTheme = (theme) => {
        setSelectedTheme({...theme});
    }

    const closeThemeModal = () => {
        getVisiteContents(visite.uid).then(result => {
            visite.contents = result;
            setCurrentVisite({...visite}).then(id => {
                setSelectedTheme(null);
            });
        });
    }

    /* Arrêter les synchros lors de l'édition d'une visite */
    useEffect(() => {
        setSyncActive(false);
        return () => {
            // Reprendre la synchro lors de la fin de l'édition
            setSyncActive(true);
        };
    }, []);

    /* Chargement de la visite */
    useEffect(() => {
        getVisiteById(id).then(nextVisite => {
            locationRef.current = getLocationByHLO(nextVisite.loc_hlo);
            setVisite(nextVisite);
            if(nextVisite.contents.length > 0){
                const mainContent = nextVisite.contents.find(content => content.group_visite === nextVisite.type_bien);
                if(mainContent){
                    setElements(mainContent.content);
                }
            }
        });

    }, [id]);

    /* Enregistrement de la visite */
    useEffect(() => {
        if(visite && visite.contents) {
            saveContents();
        }
        // eslint-disable-next-line
    }, [visite]);

    useEffect(() => {
        if(visite && (clesCalculees !== visite.cles_calcul)){
            setVisite({...visite, cles_calcul: clesCalculees});
        }
    }, [clesCalculees, visite])

    /* Enregistrement des contenus si elements mis à jour */
    useEffect(() => {
        if(elements && elements.length) {
            saveContents();
        }
        // eslint-disable-next-line
    }, [elements]);

    /* Enregistrement des contenus lors de la création d'un thème */
    useEffect(()=> {
        if(selectedTheme) {
            saveContents();
        };
         // eslint-disable-next-line
    }, [selectedTheme]);

    return {
        visite, 
        elements, 
        locationRef, 
        clesCalculees, 
        copy, 
        selectedTheme, 
        handleChange, 
        duplicateElement, 
        removeElement, 
        updateTheme, 
        deleteTheme, 
        loadTheme, 
        closeThemeModal, 
    }
}

export default useVisite;