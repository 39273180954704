import React, { useEffect, useState } from "react";
import { initDB } from "./services/indexedDBService.js";
import { Switch, Route, useLocation } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute.js";
import AppHeader from "./components/AppHeader.js";
import * as serviceWorker from './serviceWorkerRegistration';

import Login from "./pages/Login.js";
import Dashboard from "./pages/dashboard";
import VisitRead from "./pages/visitRead";
import VisitEdit from "./pages/visitEdit";
import Proprietaires from "./pages/proprietaires";
import Biens from "./pages/biens.js";
import Visites from "./pages/visites.js";
import Versions from "./pages/versions.js";

import './styles/App.scss';
import PWA from "./pages/pwa.js";
import VisitePdf from "./pages/visitPdf.js";
import { disableReloadProtection, getIsReloadProtected, stopReloadProteted, setReloadProtected } from "./services/utilities.js";
import { Toast } from "react-bootstrap";
import { setSyncActive } from "./services/visitesService.js";
import ErrorBoundary from "./components/ErrorBoundary.js";


const App = () => {

    const location = useLocation();

    if(location.pathname === "/identification" || location.pathname === "/pwa"){
        disableReloadProtection();
    } else {
        setReloadProtected();
    }

    const [showToast, setShowToast] = useState(false);
    const [waitingWorker, setWaitingWorker] = useState(null);

    const onUpdate = (event) => {
        event.preventDefault();
        waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' });
        stopReloadProteted();
        setShowToast(false);
        window.location.reload();
    }

    useEffect(() => {

        const onSWUpdate = (registration) => {
            if(window.navigator.onLine){
                setWaitingWorker(registration.waiting);
                setSyncActive(true);
                setShowToast(true);
            }
        }

        serviceWorker.register({ onUpdate: onSWUpdate });

        initDB();
        
        window.onbeforeunload = (event) => {
            const isProtected = getIsReloadProtected();
            if(isProtected){
                event.preventDefault();
                if(event) {
                    event.returnValue = '';
                }
            }
        }

    }, []);

    const closeToast = () => setShowToast(false);

    return (
        <div className="app-container">

            <AppHeader />

            <div className="app-body">
                <div className={`page-content`}>
                    <ErrorBoundary>
                        <Switch location={location}>

                            <Route exact={true} path="/identification"><Login /></Route>
                            <Route exact={true} path="/identification/response"><Login /></Route>

                            <PrivateRoute exact={true} path="/pwa"><PWA /></PrivateRoute>
                            <PrivateRoute exact={true} path="/"><Dashboard /></PrivateRoute>
                            <PrivateRoute exact={true} path="/visits/"><Visites /></PrivateRoute>
                            <PrivateRoute exact={true} path="/visits/:theme"><Visites /></PrivateRoute>
                            <PrivateRoute path="/visit-update/:id"><VisitEdit /></PrivateRoute>
                            <PrivateRoute path="/visit/:id"><VisitRead /></PrivateRoute>
                            <PrivateRoute path="/compte-rendu/:id"><VisitePdf /></PrivateRoute>
                            <PrivateRoute path="/proprios"><Proprietaires /></PrivateRoute>
                            <PrivateRoute path="/locations"><Biens /></PrivateRoute>
                            <PrivateRoute path="/versions"><Versions /></PrivateRoute>
                            <PrivateRoute path="/about"><div>À propos…</div></PrivateRoute>

                        </Switch>
                    </ErrorBoundary>
                </div>
            </div>

            {showToast && <Toast position={'top-center'} bg='warning' onClose={closeToast}>
                <Toast.Header>
                    <strong>Nouvelle version</strong>
                </Toast.Header>
                <Toast.Body>
                    <div className="text-center">Une nouvelle version de Nomade est disponible. <a href="/" onClick={(e) => onUpdate(e)}>Recharger l'application</a> pour effectuer<br/>la mise à jour.</div>
                </Toast.Body>
            </Toast>}
        </div>
    );

};

export default App;
