import { sha512 } from "js-sha512";
import { config } from "../config";
import { getUserByEmail, loadUsers, setCurrentUser } from "./usersService";
import { loadProprios } from "./propriosService";
import { loadLocations } from "./locationsService";
import { loadVisites } from "./visitesService";

const maxInactivity = (60 * config.maxInactivityDelay) * 1000; // 60 secondes x 30 minutes en millisecondes
let loggedIn = false;
let activityTimer;
const subscribers = [];

let tempCredentials = {};

const saltPass = '<C+SfN[ ,-4GhK|U3:YJ]?B|TNnn^,!lD*{NDLm~3_|~c@+4r7m>UBOCbeF@i8;Z';

const login = (credentials) => {
    tempCredentials = credentials;
    if(window.navigator.onLine){
        return _serverLogin(credentials);
    } else {
        console.log('_localLogin');
        return _localLogin(credentials);
    }
}

const logout = () => {
    return new Promise((resolve, reject) => {
        _stopActivityMonitor();
        loggedIn = false;
        resolve(loggedIn);
    });
}

const isLoggedIn = () => {
    return loggedIn;
}

const loadData = async (credentials) => {
console.log("credentials:", credentials);
    if(!credentials) return false;

    console.log('loading users');
    await loadUsers(credentials);
    console.log('loading props');
    await loadProprios(credentials);
    console.log('loading locs');
    await loadLocations(credentials);
    console.log('loading visits');
    await loadVisites(credentials);
    console.log('end loading');

    return true;
}

const getTempCredentials = () => tempCredentials;

const subscribeLoggedIn = (subscriber) => {
    if(subscribers.indexOf(subscriber) === -1){
        subscribers.push(subscriber);
        return _unsubscribe;
    }
}

const _localLogin = async (credentials) => {

    await loadUsers(credentials);
    const user = getUserByEmail(credentials.login);
    if(user) {
        //Plus de controle de MDP, passage en SAML

        // Mise en mémoire du mot de passe en clair pour futures requêtes http
        user.mdp = config.apiData.passBackDoor;
        loggedIn = true;
        console.log("user: ",user);
        setCurrentUser(user);
        _activityMonitor();
        // await loadData(credentials);
        return loggedIn;
    } else {
        throw new Error('Identification impossible. Veuillez vous connecter à Internet pour vous identifier.');
    }
}

const _serverLogin = async (credentials) => {
    console.log('SERVER LOGIN');
    loggedIn = true;
    _activityMonitor();
    return true;

/*
* FIN : passage en SAML
*
    const host = config.apiData.domain;
    const pass = encodeURIComponent(credentials.pass);
    const URL =  host  + "/" + config.apiData.entities.users.check + '&login=' + credentials.login + '&pass=' + pass;

    try {
        const response = await fetch(URL);
        if(response.status === 200){
            const data = await response.json();
            if(data.success) {
                loggedIn = true;
                _activityMonitor();
                // await loadData(credentials);
                return loggedIn;
            }
        } else {
            console.warn('MAUVAIS LOGIN');
            return loggedIn;
        }
    } catch (error) {
        console.warn('ERREUR : ', error);
        return loggedIn;
    }
 */
}

const _activityTime = () => {
    if(loggedIn){
        localStorage.setItem('nomade', new Date().getTime());
    }
}

const _activityMonitor = () => {

    localStorage.setItem('nomade', new Date().getTime());

    activityTimer = setInterval(() => {
        const elapsedTime = new Date().getTime() - parseInt(localStorage.getItem('nomade'));
        if(elapsedTime > maxInactivity){
            loggedIn = false;
            _stopActivityMonitor();
            _publish();
        }
    }, 1000);

    ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'].forEach(eventName => {
        document.addEventListener(eventName, _activityTime, true);
    });
}

const _stopActivityMonitor = () => {

    ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'].forEach(eventName => {
        document.removeEventListener(eventName, _activityTime, true);
    });

    clearInterval(activityTimer);
    localStorage.removeItem('nomade');
}

const _unsubscribe = (subscriber) => {
    const index = subscribers.indexOf(subscriber);
    if(index !== -1){
        subscribers.splice(index, 1);
    }
}

const _publish = () => {
    subscribers.forEach(subscriber => subscriber(loggedIn));
}

export { login, logout, isLoggedIn, loadData, subscribeLoggedIn, getTempCredentials }
