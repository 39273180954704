import { memoize } from 'lodash';
import { memo } from 'react';
import AutoSizer from "react-virtualized-auto-sizer";
import { areEqual, FixedSizeList as List } from 'react-window';
import VisiteItem from './visiteItem';

const createItemData = memoize((items) => items);

const VisitesList = ({ visites, listRef }) => {

    const itemRenderer = memo(({data, index, style}) => {
        return(
            <div style={style}>
                <VisiteItem visite={data[index]} />
            </div>
        );
    }, areEqual);

    const itemData = createItemData(visites);

    return(
        <AutoSizer>
            {({ height, width }) => (
                <List
                height={height}
                itemCount={visites.length}
                itemSize={160}
                layout="vertical"
                width={width}
                itemData={itemData}
                ref={listRef}
                >
                    {itemRenderer}
                </List>
            )}
        </AutoSizer>
    );

}

export default VisitesList;