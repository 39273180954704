import { useEffect } from "react";
import { useThemeDispatch } from '../context/theme-context';
import DatePicker from "react-date-picker";
import FileLoader from "./files/file-loader";

const ThemeLabel = (props) => {

    const dispatchTheme = useThemeDispatch();

    const { theme, label, onUpdate } = props;
    const { content, group_visite, date_fin_label, date_fin_label2, files, valide, valide2 } = theme;

    const titreLabel = label === 'label' ? content[0].titre_label : content[0].titre_label2;
    const dateFinLabel = (label === 'label' && date_fin_label) ? new Date(date_fin_label * 1000) : (label === 'label2' && date_fin_label2) ? new Date(date_fin_label2 * 1000) : null;

    const setThemeValide = () => {
        dispatchTheme({type: 'force-theme-valide', value: label});
        if(onUpdate){
            onUpdate();
        }
    }

    const onDateChange = (event) => {
        dispatchTheme({type: 'date-fin-label', value: {date: event, label: label}});
    }

    const onFileDelete = (file) => {
        dispatchTheme({type: 'delete-file', value: {file: file, label: label}});
    }

    const getLabelFiles = () => {
        const prefixe = label === 'label' ? group_visite : 'label2_';
        if(files && files.length > 0){
            const labelFiles = files.filter(file => file.startsWith(prefixe));
            return labelFiles;
        } else {
            return [];
        }
    }

    useEffect(() => {

        const onFiles = (event) => {
            dispatchTheme({type: 'add-files', value: event.detail});
        };

        window.addEventListener('file_change', onFiles);

        return() => {
            window.removeEventListener('file_change', onFiles);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const canValidate = dateFinLabel && getLabelFiles().length > 0;
    const key = label === 'label2' ? valide2 : valide;

    return(
        <div className="theme-label">
            <p className="fw-bold">{titreLabel}</p>
            <div className="d-flex gap-4">
                <div className="d-flex gap-2 mb-3">
                    <label>Date de fin de validité</label>
                    <div className="date-picker-container">
                        <DatePicker value={dateFinLabel} onChange={onDateChange} locale="fr-FR" format="dd-MM-y" />
                    </div>
                </div>
                <FileLoader group_visite={theme.group_visite} uid={theme.uid} label={label} ondelete={onFileDelete} />
                <div>
                    <button className="btn btn-outline-success" onClick={setThemeValide} disabled={!canValidate}>
                        {(key && canValidate) ? "Invalider" : "Valider"}
                    </button>
                </div>
            </div>
            <hr/>
        </div>
    )
}

export default ThemeLabel;