import { config } from "../config";
import { dbBulkAdd, dbFetchStore, dbGet, dbAdd, dbDelete } from "./indexedDBService";
import { getProprioByUID } from "./propriosService";
import { getCurrentUser } from "./usersService";
import { getTimestamp, removeAccents } from "./utilities";

let locations = [];
let locationsFiltered = [];
const subscribers = [];
let filters = {
    type: null,
    search: null
}

const loadLocations = async (credentials) => {

    if(credentials){
        if(window.navigator.onLine){
            await _loadLocationsFromServer(credentials).then(result => {
                locations = locationsFiltered = result;
            });
        } else {
            await dbFetchStore('locs').then(data => {
                locations = locationsFiltered = data;
            });
        }
    } else {
        await dbFetchStore('locs').then(data => {
            locations = locationsFiltered = data;
        });
    }

    _publish();
    return locations;
}

const subscribeLocations = (subscriber) => {
    if(subscribers.indexOf(subscriber) === -1){
        subscribers.push(subscriber);
        return _unsubscribe;
    }
}

const getLocations = async () => {
    const { email, mdp } = getCurrentUser();
    return _loadLocationsFromServer({login: email, pass: mdp}).then(result => {
        _publish();
    });
}

const getBiens = () => {
    return locations;
}

//retourne les qte dernières locations de créées (pour le Tab2Bord)
const getLastLocations = (qte) => {
    var lastLocations = locations.sort((a,b) => {
        return new Date(a.date_create).getTime() -
            new Date(b.date_create).getTime()
    }).reverse().slice(0,qte);

    return lastLocations;
}

const getLocationByHLO = (hlo) => {
    return locations.find(location => location.loc_hlo === hlo);
}

const getLocationsWithoutAgr = (qte) => {
    return locations.filter(location => (!location.agr || location.agr === ""));
}

const getLocationsByProprio = (idProprio) => {
    return locations.filter(location => {
        return location.props.split(',').includes(idProprio);
    });
}

const filterLocations = (newfilter) => {

    locationsFiltered = locations;

    if(newfilter){
        const filtersUpdated = {
            ...filters,
            ...newfilter
        };
        filters = filtersUpdated;
    }

    if(filters.type) {
        locationsFiltered = _filterByType(locationsFiltered, filters.type);
    }

    if(filters.search) {
        locationsFiltered = _filterBySearch(locationsFiltered, filters.search);
    }
    _publish();
}

const updateLocationAgrement = async (hlo, agrement) => {
    await dbGet('locs', hlo).then(location => {

        location.agr = agrement;

        const locationToUpdate = locations.find(loc => loc.loc_hlo === location.loc_hlo);

        if(locationToUpdate){
            locationToUpdate.agr = agrement;
        }

        return location;
    });
}

const _loadLocationsFromServer = async (credentials) => {
    try {

        const lastSyncDate = await dbGet('app_params', 'last_sync_locations').then(result => {
            return result ? result.value : false;
        });

        const lastsyncParam = lastSyncDate ? '&last_sync=' + lastSyncDate : '';

        const host = config.apiData.domain;
        const pass = encodeURIComponent(credentials.pass);
        const fetchUrl = `${host}${config.apiData.entities.locs.getList}&login=${credentials.login}&pass=${pass}${lastsyncParam}`;

        const response = await fetch(fetchUrl);
        if(response.ok){
            // Les données du serveur
            const data = await response.json();

            // Les données locales
            const localLocs  = await dbFetchStore('locs');

            // Identification des locations locales qui ne sont plus sur le serveur
            const localLocsNotOnServer = localLocs.reduce((result, current) => {
                const isOnServer = data.locs.find(serverLoc => serverLoc.loc_hlo === current.loc_hlo);
                if(!isOnServer) result.push(current.loc_hlo);
                return result;
            }, []);

            // Les visites locales
            const localVisites = await dbFetchStore('visits');

            // Liste des locations locales à supprimer
            const localLocsToDelete = localLocsNotOnServer.reduce((result, current) => {
                const hasVisite = localVisites.find(visite => visite.loc_hlo === current.loc_hlo);
                if(!hasVisite) result.push(current);
                return result;
            }, []);

            // Suppression des locations locales
            localLocsToDelete.forEach(async hlo => {
                await dbDelete('locs', hlo);
            });
            
            return await dbBulkAdd('locs', data.locs).then(async result => {
                locations = result;
                await dbAdd('app_params', {'name': 'last_sync_locations', 'value': getTimestamp()});
                return locations;
            });
        } else {
            console.warn('MAUVAIS LOGIN');
            return locations;
        }
    } catch (error) {
        console.warn('ERREUR : ', error);
        return locations;
    }
}

const _unsubscribe = (subscriber) => {
    const index = subscribers.indexOf(subscriber);
    if(index !== -1){
        subscribers.splice(index, 1);
    }
}

const _publish = () => {
    subscribers.forEach(subscriber => {
        subscriber(locationsFiltered);
    });
}

const _filterByType = (data, type) => {
    return data.filter(location => location.type === type);
}

const _filterBySearch = (data, search) => {
    return data.filter(location => {
        const hlo = location.loc_hlo ? location.loc_hlo.toLowerCase() : '';
        const agr = location.agr ? location.agr.toLowerCase() : '';
        const commune = location.commune ? location.commune.toLowerCase() : '';
        const name = location.name ? location.name.toLowerCase() : '';

        let propsNames = '';

        const propsUids = location.props.split(',');
        propsUids.forEach(propUid => {
            const prop = getProprioByUID(propUid);
            if(prop){
                const prenom = prop.firstname ? prop.firstname.toLowerCase() : '';
                const nom = prop.lastname ? prop.lastname.toLowerCase() : '';
                const propString = `${prenom} ${nom} `;
                propsNames += propString;
            }
        });

        let searchString = `${hlo} ${agr} ${commune} ${name} ${propsNames}`;

        searchString = removeAccents(searchString);
        search = removeAccents(search.toLowerCase());

        return searchString.indexOf(search) !== -1;
    });
}

export {
    loadLocations,
    subscribeLocations,
    getLocations,
    getBiens,
    getLocationByHLO,
    getLocationsByProprio,
    filterLocations,
    updateLocationAgrement,
    getLastLocations,
    getLocationsWithoutAgr
}
