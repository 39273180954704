import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// import * as serviceWorker from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import App from './App.js';

import './fonts/Roboto-Regular.ttf';
import './fonts/Roboto-Bold.ttf';
import './fonts/RobotoCondensed-Regular.ttf';
import './fonts/RobotoCondensed-Bold.ttf';

ReactDOM.render((
    <StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </StrictMode>
), document.getElementById("nomade-app"));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
