import { Modal } from "react-bootstrap";
import { getGenderName } from "../services/propriosService";
import LocationItem from './biens/location-item/location-item';

const ModalBiensParProprio = (props) => {

    const { biens, proprios, visiteuid } = props;

    const getTitle = () => {

        let title = '';

        proprios.forEach((prop, index) => {
            const genre = getGenderName(prop.gender);
            title += ` ${genre} ${prop.firstname} ${prop.lastname},`;
        });

        return title.slice(0, -1);
    }

    return(
        <Modal {...props} size="xl" centered>
            <Modal.Header closeButton>
                <Modal.Title>{getTitle()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {biens.length > 0 ? 
                    biens.map((bien, index) => <LocationItem key={`${bien.id}-${index}`} location={bien} visiteuid={visiteuid} hide={props.onHide} />) :
                    <p className="lead my-3 text-center text-muted">Aucun bien trouvé pour ce propriétaire.</p>
                }
            </Modal.Body>
        </Modal>
    )
}

export default ModalBiensParProprio;