import { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';

import { getCurrentUser } from "../../services/usersService";
import { config } from '../../config';
import { subscribeIsOnline } from "../../services/isonlineService";
import {deleteFileVisite} from "../../services/visitesService";
import {loadFilesFromServer} from "../../services/filesService";
import FileList from "./file-list";

const FileLoader = ({ uid, group_visite, label, ondelete }) => {

    const [selectedFile, setSelectedFile] = useState();
    const [tabFiles, setTabFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState();

    const [isOnline, setIsOnline] = useState(window.navigator.onLine);

    const { email, mdp } = getCurrentUser();
    const pass = encodeURIComponent(mdp);
    
    const apiGetVisitsFiles = config.apiData.domain+config.apiData.entities.visits.visit_files_get+`&login=${email}&pass=${pass}&visit_uid=${uid}&group_visite=${group_visite}&label=${label}`;
    const apiPostFile = config.apiData.domain+config.apiData.entities.visits.visit_file_add+`&login=${email}&pass=${pass}&visit_uid=${uid}&group_visite=${group_visite}&label=${label}`;

    //submit, envoi fichier vers serveur
    const handleSubmit = async(event) => {

        if(event){
            event.preventDefault();
        }

        setErrorMessage(null);

        const formData = new FormData();
        formData.append("selectedFile", selectedFile);
        
        await fetch(apiPostFile, { method: 'POST', body: formData })
        .then(response => response.json())
        .then(data => {
            //console.log(data);
            if(data.success){
                getFilesName();
            } else {
                setErrorMessage(data.message);
            }
        });
    }

    const handleFileSelect = (event) => {

        const nextFile = event.target.files[0];
        const fileSizeMb = (nextFile.size / 1048576).toFixed(1);
        if(fileSizeMb <= 10){
            setSelectedFile(event.target.files[0]);
        } else {
            setErrorMessage("Ce fichier est trop lourd");
        }

        event.target.value = null;
    }

    //suppression d'un fichier
    const onDeleteFile = (event, file) => {
        event.preventDefault();
        if(window.confirm("Voulez-vous vraiment supprimer ce fichier ?") ){
            deleteFileVisite(`${uid}/${file}`).then(result => {
                if(result.success){
                    const nextFiles = [...tabFiles];
                    nextFiles.splice(nextFiles.indexOf(file), 1);
                    setTabFiles(nextFiles);
                    if(ondelete) ondelete(file);
                }
            });
        }
    }

    //declenche submit à la sélection d'un fichier
    useEffect(() => {
        if(selectedFile) handleSubmit();
        // eslint-disable-next-line
    }, [selectedFile]);

    const getFilesName = async () => {
        await fetch(apiGetVisitsFiles, { method: 'GET' })
        .then(response => response.json())
        .then(data => {
            if(data.files){
                if(label === 'label2'){
                    setTabFiles(data.files.filter(file => file.startsWith('label2_')));
                } else {
                    setTabFiles(data.files.filter(file => file.startsWith(group_visite)));
                }
            }
        });
    }

    //recupere la liste des fichiers de ce groupe/visite présents sur le serveur vers le tableau affiché tabFiles
    useEffect(() => {

        const updateStatus = (status) => setIsOnline(status);

        loadFilesFromServer(uid).then( files =>{
            if(label && label === 'label2'){
                setTabFiles(files.filter(file => file.startsWith('label2_')));
            } else {
                setTabFiles(files.filter(file => file.startsWith(group_visite)));
            }
            }
        );

        const unsubscribeIsOnline = subscribeIsOnline(updateStatus);
        return () => {
            unsubscribeIsOnline(updateStatus);
        }

        // eslint-disable-next-line
    }, [uid, label, group_visite]);

    //declenche l'event "file_change" (déclaré en entête de ce fichier) au changement (useEffect) du tableau des fichiers "tabFiles"
    useEffect(()=>{
        window.dispatchEvent(new CustomEvent('file_change', {detail: tabFiles}));
    }, [tabFiles]);

    return(
        <div className='file-manager'>
            <form onSubmit={handleSubmit} className="mb-2">
                <input type="file" className='form-control' style={{maxWidth: '340px'}} onChange={handleFileSelect} disabled={!isOnline} accept="image/*,.pdf,.doc,.docx" />
            </form>
            {errorMessage && <Alert variant='danger' style={{ padding: "0.5rem", lineHeight: '1.2' }}><small>{errorMessage}</small></Alert>}
            <FileList files={tabFiles} visiteUid={uid} deleteHandler={onDeleteFile} />
        </div>
    );
}

export default FileLoader;