import React, { Fragment, useState, useEffect, useRef } from "react";
import { getVisites, getVisitesFiltered, setSyncActive, subscribeVisites } from "../services/visitesService";
import { FloatingLabel, Form } from "react-bootstrap";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { config } from "../config";
import useQuery from "../services/useQuery";
import { subscribeIsOnline } from "../services/isonlineService";
import VisitesList from '../components/visites/visitesList';

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

const Visites = () => {

    const searchParams = useQuery();

    const visitesListRef = useRef();
    
    const [visites, setVisites] = useState([]);
    const [filters, setFilters] = useState({});
    const [dates, setDates] = useState(null);
    const [sortby, setSortby] = useState('uid');
    const [sortAsc, setSortAsc] = useState(true);

    useEffect(() => {

        let mounted = true;

        if(mounted){
            getVisites()
            .then(result => {
                const nextVisites = result.filter(visite => visite.type_visite !== 'vstarch');
                nextVisites.sort((a, b) => (a[sortby] > b[sortby]) ? 1 : ((b[sortby] > a[sortby]) ? -1 : 0));
                if(sortAsc) nextVisites.reverse();

                setVisites(nextVisites);

                if(searchParams) {
                    
                    let nextFilters = filters;
                    
                    if(searchParams.get('scelle')){
                        nextFilters = {...nextFilters, scelle: searchParams.get('scelle') === 'false' ? false : true}
                    }
                    
                    if(searchParams.get('actifs') === 'true'){
                        nextFilters = {...nextFilters, actifs: true}
                    }
                    
                    if(searchParams.get('inactifs') === 'true'){
                        nextFilters = {...nextFilters, inactifs: true}
                    }
                    
                    if(searchParams.get('departement')){
                        nextFilters = {...nextFilters, departement: searchParams.get('departement')}
                    }

                    setFilters(nextFilters);
                    
                } else {
                    setFilters({});
                }

            });
        }

        return () => {
            mounted = false;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if(!searchParams.get('scelle')){
            setFilters({});
        }

    }, [searchParams]);

    useEffect(() => {

        let isMounted = true;

        const loadVisitesFiltered = () => {
            if(isMounted){
                getVisitesFiltered(filters).then(result => {
            
                    let nextVisites = result;
                    
                    if(!filters.type){
                        if(nextVisites && nextVisites.length > 0){
                            nextVisites = [...nextVisites].filter(visite => visite.type_visite !== 'vstarch');
                        }
                    }

                    if(nextVisites){
        
                        nextVisites.sort((a, b) => (a[sortby] > b[sortby]) ? 1 : ((b[sortby] > a[sortby]) ? -1 : 0));
                        if(sortAsc) nextVisites.reverse();
        
                        setVisites([...nextVisites]);
                        
                    }

                });
            }
        }

        loadVisitesFiltered();

        const updateVisites = () => {
            loadVisitesFiltered();
        }

        const unsubscribeVisites = subscribeVisites(updateVisites);

        return () => {
            unsubscribeVisites(updateVisites);
            isMounted = false;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, sortby, sortAsc]);

    useEffect(() => {

        let isMounted = true;

        if(isMounted){

            const url = new URL(window.location.href);
            const hash = url.hash;
            if(hash && visitesListRef.current){
                const visiteId = parseInt(hash.split('#')[1]);
                const index = visites.findIndex(visite => visite.uid === visiteId);
                visitesListRef.current.scrollToItem(index, "start");
                isMounted = false;
            }
        }

        const onOnlineEvent = (status) => {
            if(status){
                setSyncActive(true);
            }
        }
        
        const unsubscribeOnline = subscribeIsOnline(onOnlineEvent);

        return () => {
            isMounted = false;
            unsubscribeOnline(onOnlineEvent);
        }
    }, [visites]);

    const onTheme = (event) => {
        setFilters({...filters, theme: event.target.value !== 'all' ? event.target.value : null});
    }

    const onType = (event) => {
        // reset des filtres si searchparams dans url
        const nextFilters = filters;
        delete nextFilters.actifs;
        delete nextFilters.inactifs;
        delete nextFilters.scelle;
        delete nextFilters.departement;
        setFilters({...nextFilters, type: event.target.value ? event.target.value : null});
    }

    const onSearch = (event) => {
        setFilters({...filters, search: event.target.value !== '' ? event.target.value : null});
    }

    const onDates = (newDates) => {
        setFilters({...filters, daterange: newDates});
        setDates(newDates);
    }

    const updateSortBy = (by) => {
        if(by === sortby){
            setSortAsc(!sortAsc);
        } else {
            setSortby(by);
        }
    }

    return(
        <Fragment>
            <div className="content-header px-3 d-flex align-items-center justify-content-between border-bottom position-relative flex-wrap">
                <h1 className="mb-2 mb-lg-0">{visites.length} {visites.length > 1 ? "visites" : "visite"}</h1>
                <Form>
                    <div className="d-flex gap-3 align-items-center">
                        <div>
                            <DateRangePicker className={`visites-date-picker${ dates ? ' clear' : ''}`} value={dates} onChange={onDates} locale="fr-FR" maxDetail="month" />
                        </div>
                        <FloatingLabel label="Recherche…">
                            <Form.Control type="text" value={filters.search || ''} onChange={onSearch}></Form.Control>
                        </FloatingLabel>
                        <FloatingLabel label="Types de locations" className="text-nowrap">
                            <Form.Select className="visites-select" aria-label="Types de locations" value={filters.theme || 'all'} onChange={onTheme}>
                                <option value="all">Toutes</option>
                                <option value="loc">Meublés</option>
                                <option value="ch">Chambres d'hôtes</option>
                                <option value="inso">Insolite</option>
                                <option value="hll">HLL</option>
                            </Form.Select>
                        </FloatingLabel>
                        <FloatingLabel label="Types de visite" className="text-nowrap">
                            <Form.Select className="visites-select" aria-label="Types de visites" value={filters.type || 'all'} onChange={onType}>
                                <option value="all">Toutes (sauf archives)</option>
                                {config.visits_types.map(t => <option key={t.id} value={t.id}>{t.name}</option>)}
                            </Form.Select>
                        </FloatingLabel>
                    </div>
                </Form>

            </div>
            <div className="content-body visites-content-body">
                <div className="visites-list-header sticky-top visite-item border-bottom bg-light d-flex justify-content-between">
                    <div className="d-flex align-items-start">
                        <div className="d-flex">
                            <div className={`visite-id sort-btn p-2 ${sortby === 'uid' ? "active" : ''}`}  onClick={() => updateSortBy('uid')}><small>ID</small></div>
                            <div className={`visite-infos sort-btn p-2 ${sortby === 'loc_hlo' ? "active" : ''}`} onClick={() => updateSortBy('loc_hlo')}><small>Informations</small></div>
                        </div>
                        <div className="d-none d-lg-flex">
                            <div className={`visite-cles sort-btn p-2 ${sortby === 'cles' ? "active" : ''}`} onClick={() => updateSortBy('cles')}><small>Clé</small></div>
                            <div className={`visite-type sort-btn p-2 ${sortby === 'type_visite' ? "active" : ''}`} onClick={() => updateSortBy('type_visite')}><small>Type</small></div>
                            <div className={`visite-date sort-btn p-2 ${sortby === 'date_visite' ? "active" : ''}`} onClick={() => updateSortBy('date_visite')}><small>Date visite</small></div>
                        </div>
                    </div>
                    <div className="visite-actions">
                        {/* <div className="p-2"><small>Actions</small></div> */}
                    </div>
                </div>
                {visites.length > 0 && <div className="visites-list-container flex-grow-1">
                    <VisitesList visites={visites} listRef={visitesListRef} />
                </div>}
                {/* {visites.map((visite, index) => <VisiteItem key={visite.uid + '-' + index} visite={visite} /> )} */}
                {!visites.length && <div className="flex-grow-1">
                    <div className="alert alert-info m-5 text-center">Aucune visite ne correspond à vos critères.</div>
                </div>}
            </div>
        </Fragment>
    );
}

export default Visites;
