import { Card, ListGroup } from "react-bootstrap";
import { config } from "../../config";
import { getLocationByHLO } from "../../services/locationsService";
import {getGenderName, getProprioByUID} from "../../services/propriosService";
import { stopReloadProteted } from "../../services/utilities";
import React, {Fragment} from "react";

const CardBien = ({ hlo }) => {

    const bien = getLocationByHLO(hlo);

    const propsUids = bien.props.split(',');
    const props = propsUids.map(propUid => getProprioByUID(propUid));

    const typeBien = config.group_visit[bien.type].name;
    const { adresse, commune, postal_code: departement, capacity } = bien;

    const onLink = (event) => {
        event.preventDefault();
        stopReloadProteted();
        window.location = event.target.getAttribute('href');
    }

    return(
        <Card>
            <Card.Header>
                <Card.Title className="mb-0">
                {typeBien} ({capacity} {parseInt(capacity) > 1 ? 'personnes' : 'personne'})
                </Card.Title>
            </Card.Header>
            <ListGroup variant="flush">
                <ListGroup.Item>
                    <span>{adresse}</span>
                    <br/>
                    <span>{departement} {commune}</span>
                </ListGroup.Item>

                <ListGroup.Item>
                    {props && props.map(prop => {
                        return(
                            <Fragment key={prop.uid}>
                                <strong>{getGenderName(prop.gender)} {prop.firstname} {prop.lastname}</strong>
                                <br/>
                                {prop.tel && <a href={`tel:${prop.tel}`} onClick={onLink}>{prop.tel}</a>}<br/>
                                {prop.email && <a href={`mailto:${prop.email}`} onClick={onLink}>{prop.email}</a>}
                                <br/><br/>
                            </Fragment>
                        )
                    })}
                </ListGroup.Item>

            </ListGroup>
        </Card>
    )
}

export default CardBien;
