import { config } from "../config";
import { dbAdd, dbDelete, dbGet, dbUpdate, dbBulkAdd, dbFetchStore, dbFetchFiltered, dbFetchFilteredNotEqual, dbDeleteFiltered } from "./indexedDBService";
import { getLocationByHLO, updateLocationAgrement } from "./locationsService";
import { isLoggedIn } from "./loginService";
import { getProprioByUID } from "./propriosService";
import { getCurrentUser } from "./usersService";
import { getTimestamp, getTimestampFromDate, removeAccents } from "./utilities";
import Constantes from "./constantes";

// Les visites, sera rempli après la connexion à la base
let visites = [];

// Les abonnés au service
const subscribers = [];

// Les abonnés au compteur de champs
// pour atteindre le niveau de clés supérieur
const nextLevelCountSubscribers = [];

// Visite en cours
let currentVisite = null;

// La liste des schemas des themes
const themesSchemas = [];

// Un timer pour les synchros
let syncInterval = null;

// Un flag pour autoriser les synchro
let isSyncActive = true;

/* MÉTHODES PUBLIQUES */

const loadVisites = async (credentials) => {

    await dbFetchStore('visits').then(data => {
        visites = data;
    });

    if(credentials){

        if(window.navigator.onLine){

            await _loadVisitesFromServer(credentials).then(result => {
                visites = result ? result : [];
            });

            await _loadArchives(credentials).then(result => {
                visites = [...visites, ...result];
            });

            await _checkVisitesWithServer(credentials);

        } else {

            await dbFetchStore('visits').then(data => {
                visites = data;
            });
        }

        // Mise à jour de la date de synchro
        // Récupération de la date de dernière synchro
        await dbGet('app_params', 'last_sync_visites')
        .then(async syncData => {
            if(!syncData){
                dbAdd('app_params', {'name': 'last_sync_visites', 'value': getTimestamp()});
            }
        });

        // Lancement de la boucle de synchro
        setSyncActive(true);

    }

    // Vérifier si les visites ont des contenus
    await _getHasContent(visites);

    _loadThemesSchemas();

    _publish();
    return visites;
}

const getVisites = async () => {
    visites = await dbFetchStore('visits').then(result => {
        result.map(async visite => {
            return await dbGet('visits_content', [visite.uid, visite.type_bien]).then(data => {
                visite.hascontent = data ? data.content.length > 0 : false;
                return visite;
            });
        });
        return result
    });
    return visites;
}

const getAllVisites = async () => {
    return _loadVisitesFromDB().then(data => {
        visites = data
        return visites;
    });
}
/**
 * Rafraîchit la la liste des visites après une modification dans la base de données
 */
const refreshVisites = async () => {
    await getAllVisites();
    _publish();
}

const getVisitesByTheme = async (theme) => {
    visites = await dbFetchFiltered('visits', 'theme', theme);
    return visites;
}

const getVisitesByHlo = async (hlo) => {
    visites = await dbFetchFiltered('visits', 'loc_hlo', hlo);
    return visites;
}

const getLastVisitesByHlo = async (hlo) => {
    visites = await dbFetchFiltered('visits', 'loc_hlo', hlo);
    visites = visites.filter(visite => (visite.type_visite === 'vst' || visite.type_visite === 'vstre'));

    const visitesDateV = [...visites].sort((a, b) => b.date_visite - a.date_visite);
    return visitesDateV[0];
}

const getVisitesFiltered = async (filter) => {

    return getAllVisites().then(data => {

        let result = data;

        if(filter.type) {
            if(filter.type === 'all'){
                result = result.filter(visite => visite.type_visite !== 'vstarch');
            } else {
                result = result.filter(visite => visite.type_visite === filter.type);
            }
        }

        if(filter.theme) {
            result = result.filter(visite => visite.type_bien === filter.theme);
        }

        if(filter.hlo) {
            try {
                result = result.filter(visite => visite.loc_hlo === filter.hlo);
            } catch(error) {
                console.log(error);
            }
        }

        if(filter.actifs) {
            result = result.reduce((cumul, visite) => {
                const bien = getLocationByHLO(visite.loc_hlo);
                if(bien && bien.state === "actif"){
                    cumul.push(visite);
                }
                return cumul;
            }, []);
        }

        if(filter.inactifs) {
            result = result.reduce((cumul, visite) => {
                const bien = getLocationByHLO(visite.loc_hlo);
                if(bien && bien.state === "inactif"){
                    cumul.push(visite);
                }
                return cumul;
            }, []);
        }

        if(filter.scelle === false) {
            result = result.filter(visite => !Number.isInteger(visite.scelle) && visite.scelle !== true && visite.type_visite !== 'vstte');
        }

        if(filter.departement) {
            result = result.filter(visite => getLocationByHLO(visite.loc_hlo).geo_dep_id === filter.departement);
        }

        if(filter.search){
            result = result.filter(visite => {
                const loc = getLocationByHLO(visite.loc_hlo);
                console.log(loc);
                let searchString = "";
                const proprio = (loc && loc.props) ? getProprioByUID(loc.props) : null;
                if(proprio){
                    searchString = `${visite.loc_hlo} ${loc.agr ? loc.agr : ''} ${proprio.firstname ? proprio.firstname : ''} ${proprio.lastname ? proprio.lastname : ''}`.toLowerCase();
                } else {
                    searchString = `${visite.loc_hlo} ${(loc && loc.agr) ? loc.agr : ''}`.toLowerCase();
                }

                searchString = removeAccents(searchString);
                return searchString.indexOf(filter.search.toLowerCase()) !== -1;
            });
        }

        if(filter.daterange) {
            const range = [getTimestampFromDate(filter.daterange[0]), getTimestampFromDate(filter.daterange[1])];
            result = result.filter(visite => visite.date_visite >= range[0] && visite.date_visite <= range[1]);
        }

        return result;
    });

}

const getVisiteById = async (id) => {

    if(!isNaN(id)){
        id = parseInt(id);
    }

    return await dbGet('visits', id)
    .then(async visite => {
        if(visite) {
            return await dbFetchFiltered('visits_content', 'uid', visite.uid)
            .then(contents => {
                if(contents){
                    visite.contents = contents;
                }
                currentVisite = visite;
                return currentVisite;
            })
            .catch(error => {
                console.log("Erreur lors de la récupération des contenus", error);
            });
        }
    });
}

const getVisiteContent = async (content_id) => {
    return dbGet('visits_content', content_id)
    .then(result => {
        return result;
    }).catch(() => {
        console.log('ERREUR LORS DE LA LECTURE DE LA VISITE');
    });
}

const getVisiteContents = async (visiteId) => {
    return await dbFetchFiltered('visits_content', 'uid', visiteId)
    .then(result => {
        return result;
    })
    .catch(() => {
        console.log('ERREUR LORS DE LA LECTURE DE LA VISITE');
    });
}

const visiteAdd = async (visite) => {

    if(!visite.uid) {
        console.log('Enregistrement des contenus impossible !');
        return false;
    }

    _saveContentsToDB(visite).then(result => {
        console.log(result);
        return result[0];
    });
}

const visiteUpdate = async ({id, date_update, content_id, content}) => {

    // Mise à jour du contenu
    return dbUpdate('visits_content', content_id, {content: content})
    .then(() => {
        // Mise à jour de la visite
        return dbUpdate('visits', id, { date_update: date_update })
        .then(result => {
            const selectedVisite = visites.filter(visite => visite.id === id);
            Object.assign(selectedVisite[0], { date_update: date_update });

            return result;
        })
        .catch(() => {
            console.log('ERREUR LORS DE LA MISE À JOUR DE LA VISITE');
        });
    })
    .catch(() => {
        console.log('ERREUR LORS DE LA MISE À JOUR DU CONTENU DE LA VISITE');
    });

}

const visiteDelete = async (visite) => {
    // Suppression de la visite sur le serveur
    return await _deleteVisitFromServer(visite.uid)
    .then(async uid => {

        // Suppression des contenus de la visite
        const contentsToDelete = await dbFetchFiltered('visits_content', 'uid', uid);

        if(contentsToDelete){
            contentsToDelete.forEach(async content => {
                await dbDelete('visits_content', [uid, content.group_visite]);
            });
        }

        // suppression de la visite en local
        return await dbDelete('visits', visite.uid)
        .then(async deletedId => {
            // Suppression de la visite dans la liste
            if(visites && Array.isArray(visites)){
                const visiteToDelete = visites.find(v => v.uid === deletedId);
                visites.splice(visites.indexOf(visiteToDelete), 1);
                _publish();
            }
            console.log(`la visite ${deletedId} a été supprimée`);
            return deletedId;
        });

    });
}

const deleteFileVisite = async (file) => {
    // const { email, mdp } = getCurrentUser();
    // const pass = encodeURIComponent(mdp);
    const apiDeleteFileVisit = config.apiData.domain+config.apiData.entities.visits.visit_file_delete+config.apiData.loginpass+`&file=${file}`;
    return await fetch(apiDeleteFileVisit)
    .then(response => response.json())
    .then(data => data);
}

const subscribeVisites = (subscriber) => {
    if(subscribers.indexOf(subscriber) === -1){
        subscribers.push(subscriber);
        return _unsubscribeVisites;
    }
}

const initVisite = (hlo) => {

    const bien = getLocationByHLO(hlo);
    const typeBien = bien.type;
    const { version } = config.group_visit[typeBien];
    const user = getCurrentUser();
    const now = getTimestamp();
    currentVisite = {
        date_create: now,
        date_update: now,
        date_visite: now,
        user_uid: user.uid,
        user_email: user.email,
        user_tel: user.tel,
        user_gender: user.gender,
        user_lastname: user.lastname,
        user_firstname: user.firstname,
        user_adress1: user.adress1,
        user_adress2: user.adress2,
        user_adress3: user.adress3,
        user_commune: user.commune,
        user_postal_code: user.postal_code,
        loc_hlo: hlo,
        agr: null,
        type_bien: typeBien,
        version: version,
        contents: []
    }

    return currentVisite;

}

const setCurrentVisite = async (data) => {

    if(data.contents) {
        // Vérifier si les nouveaux contenus existent déjà dans la visite
        // Si oui, le supprimer
        data.contents.forEach(content => {
            const contentIndex = currentVisite.contents.findIndex(currentContent => currentContent.group_visite === content.group_visite && currentContent.uid === content.uid);
            if(contentIndex !== -1){
                currentVisite.contents.splice(contentIndex, 1);
            }
        });
        const newContents = [...currentVisite.contents, ...data.contents];
        data.contents = newContents;
    }

    const nextVisite = {
        ...currentVisite,
        ...data
    }

    //les updates par des admins ne modifient pas les _update
    const { level, uid:userUid } = getCurrentUser();
    if( parseInt(level) !== 100 || userUid === nextVisite.user_uid ){
        nextVisite.user_update = userUid;
        nextVisite.date_update = getTimestamp();
    }

    if(parseInt(level) === 100){
        nextVisite.date_update = getTimestamp();
    }

    currentVisite = nextVisite;

    return await saveVisite();

}

const createVisite = async(data) => {
    const newVisite = {
        ...currentVisite,
        ...data,
        cles_calcul: 0
    };

    currentVisite = newVisite;
    return await saveVisite();
}

const saveVisite = async () => {

    // Génération de l'UID de la visite
    if(!currentVisite.uid){
        currentVisite.uid = `fk-${getTimestamp()}`;
    }

    // Si nouvelle visite, chargement du schema de la visite
    if(!currentVisite.contents.length) {

        if( getIsForVisite(currentVisite) === false){

            console.log(config.visits_types.find(item => item.id === currentVisite.type_visite).forvisit);
            const firstContent = {
                uid: currentVisite.uid,
                content: [{"id": "0","type": "h1","calcul": "non","titre": "Visite spéciale pour thématique !","elements": [{}]}],
                group_visite: currentVisite.type_bien
            }
            currentVisite.contents.push(firstContent);

        } else {

            let loadedSchema = [];

            if(currentVisite.type_visite === 'dev'){
                loadedSchema = await import("../form-schema/loc-schema.dev.json");
            } else {
                console.log("Schema : ", currentVisite.type_bien + "-schema." + currentVisite.version + ".json");
                loadedSchema = await import("../form-schema/" + currentVisite.type_bien + "-schema." + currentVisite.version + ".json");
            }

            const firstContent = {
                uid: currentVisite.uid,
                content: loadedSchema.default,
                group_visite: currentVisite.type_bien
            }
            
            currentVisite.contents.push(firstContent);
        }
    }

    if(window.navigator.onLine && isNaN(currentVisite.uid)){
        delete currentVisite.uid;
        const newUid = await _saveVisiteToServer(currentVisite);
        if(newUid){
            currentVisite.uid = parseInt(newUid);
            currentVisite.contents.forEach(content => {
                content.uid = parseInt(newUid);
            });
        }
    }

    return _saveContentsToDB(currentVisite)
    .then(async result => {

        const visiteToSave = {...result};
        delete visiteToSave.contents;

        return dbAdd('visits', visiteToSave)
        .then(async id => {
            if(visites && visites.length){
                const existe = visites.filter(v => v.uid === visiteToSave.uid);
                if(!existe.length){
                    visites.push(visiteToSave);
                }
            } else {
                visites = _loadVisitesFromDB();
            }
            return id;
        });

    });

}

const saveVisiteComment = async (visiteId, comment) => {
    return await dbGet('visits', visiteId)
    .then(async visite => {

        const cptRendu = visite.cpt_rendu || {};

        if('textpublic' in comment) {
            cptRendu.textpublic = comment.textpublic;
        }

        if('textprive' in comment){
            cptRendu.textprive = comment.textprive;
        }

        const localVisite = visites.find(v => v.uid === visite.uid);
        const currentVisite = getCurrentVisite();

        if(!cptRendu.textpublic && !cptRendu.textprive){
            delete visite.cpt_rendu;
            delete localVisite.cpt_rendu;
            if(currentVisite && currentVisite.uid === visite.uid) delete currentVisite.cpt_rendu;
        } else {
            visite.cpt_rendu = cptRendu;
            localVisite.cpt_rendu = cptRendu;
            if(currentVisite && (currentVisite.uid === visite.uid)) currentVisite.cpt_rendu = cptRendu;
        }


        visite.date_update = getTimestamp();

        return await dbAdd('visits', visite)
        .then(result => {
            return result;
        });

    });
}

const getCurrentVisite = () => {
    return currentVisite;
}

const deleteVisiteContent = async (content) => {
    return await dbDelete('visits_content', [content.uid, content.group_visite]).then(result => {
        if(currentVisite && content.uid === currentVisite.uid){
            const nextContents = currentVisite.contents.filter(c => c.group_visite !== content.group_visite);
            currentVisite.contents = nextContents;
        }
        return result;
    });
}

const lockVisite = async (visiteUid) => {

    // Récupération de la visite local sur IDB
    const visite = await dbGet('visits', visiteUid);

    if(!visite) return false;

    //si la visite a un uid temporaire (fk-xxx), il faut la créer sur le serveur avant de la sceller
    if( isNaN(visite.uid) ){

        // Récupérer les contenus de la visite avant de la créer sur le serveur
        const visiteContents = await dbFetchFiltered('visits_content', 'uid', visite.uid);

        // Attacher les contenus à la visite
        visite.contents = visiteContents;

        // Créer la nouvelle visite sur le serveur et
        // met à jour localement l'uid retourné par le serveur
        const nextUid = await _saveFakeVisite(visite);

        // Si l'uid retourné est valide
        if(nextUid && !isNaN(nextUid)){

            // Récupérer la visite avec le nouvel uid
            const visiteToSave = await dbGet('visits', parseInt(nextUid));

            // Récupère les contenus associés à cette visite
            const visiteToSaveContents = await dbFetchFiltered('visits_content', 'uid', nextUid);

            // Associe les contenus à la visite
            visiteToSave.contents = visiteToSaveContents;

            // Enregistre (Update) la visite sur le serveur
            const result = await _saveVisiteToServer(visiteToSave);

            // Finalement, scellage de la visite sur le serveur
            if(result) {
                return _lockVisiteToServer(visiteToSave);
            }

        } else {
            return false;
        }

    } else {

        return _lockVisiteToServer(visite);

    }

}

const validateTheme = (children) => {

    const getInputsToCheck = (children) => {

        let count = 0;
        const types = ['checkbox', 'radio', 'checkboxgroup'];

        const countInputs = (elements) => {
            elements.forEach(element => {
                if(element.facultatif) return;
                if(types.includes(element.type) && !element.facultatif){
                    count += 1;
                } else if(element.type === "checkboxgroup"){
                    count += 1;
                } else if(element.elements){
                    countInputs(element.elements);
                }
            });
        }

        countInputs(children);

        return count;

    }

    const getInputsChecked = (children) => {

        let totalChecked = 0;

        const countFromChildren = (children) => {
            children.forEach(element => {
                if(element.facultatif) return false;
                if(element.valeur || element.valide) {
                    totalChecked += 1;
                } else if(element.elements){
                    countFromChildren(element.elements);
                }
            });
        }

        countFromChildren(children);

        return totalChecked;

    }

    const total = getInputsChecked(children);
    const totalToCheck = getInputsToCheck(children);

    return total >= totalToCheck;

}

const getThemeSchemaById = (id) => {
    return themesSchemas.find(schema => schema.id.startsWith(id));
}

const getVisiteHasContent = async (visiteUid, type) => {
    return dbGet('visits_content', [visiteUid, type])
    .then(result => {

        if(!result) {
            return false;
        }

        if(result.content && result.content.length){
            return true;
        }

        return false;
    });
}

const setSyncActive = (active) => {

    if(active && isSyncActive) return false;

    if(!active && !isSyncActive) return false;

    isSyncActive = active;

    clearInterval(syncInterval);
    syncInterval = null;

    if(isSyncActive){
        // lancer la synchro immédiatement
        _syncWithServer();
    
        // puis lancer les synchros à intervalles réguliers
        syncInterval = setInterval(_syncWithServer, config.apiData.syncFrequency * 1000);
    }

}

/*
 * appelé au chargement form visite et à chaque changement de la liste des fichiers
 */
const getIsLockable = async (visite) => {

    if (!window.navigator.onLine) {
        return {
            lockable: false,
            message: Constantes.TEXT_TOOLTIP_SI_NON_CONNECTE
        };
    }

    if (visite.scelle) {
        return {
            lockable: false,
            message: Constantes.TEXT_TOOLTIP_SI_DEJA_SCELLE
        };
    }

    if (visite.type_visite === "vs4themes") {

        return {
            lockable: false,
            message: Constantes.TEXT_TOOLTIP_FOR_THEMES
        };
    }

    if ( visite.type_visite === "vstcs"
        || visite.type_visite === "vstte") {
        return {
            lockable: false,
            message: Constantes.TEXT_TOOLTIP_SI_NON_SCELLABLE_TYPE_VISITE
        };
    }


    if( visite.type_bien === "inso" && document.getElementById("inso-2.4.0").value === "" ){
        return {
            lockable: false,
            message: Constantes.TEXT_TOOLTIP_INSO_CRITERE_2_4_OBLIGATOIRE
        };
    }

    const currentUser = getCurrentUser();
    const userLevel = parseInt(currentUser.level);

    //seul les admins peuvent sceller des visites 5 clés
    if (visite.type_bien !== "inso" && visite.cles_calcul > 4 && userLevel <= config.autorisations.sceller.level) {
        return {
            lockable: false,
            message: Constantes.TEXT_TOOLTIP_SI_NON_SCELLABLE
        };
    }

    //les insolites doivent avoir au moins un fichier de lié
    if (visite.type_bien === "inso") {

        if(!visite.cles_calcul || visite.cles_calcul < 5 || visite.cles_calcul === Infinity){
            return {
                lockable: false,
                message: Constantes.TEXT_TOOLTIP_SI_NON_SCELLABLE_INCOMPLETE
            }
        }

        const hasFile = await isInsoFile(visite);
        
        if(!hasFile){
            return {
                lockable: false,
                message: Constantes.TEXT_TOOLTIP_INSO_PAS_DE_FICHIER
            };
        }
    }

    //si pas de clés, pas possible de sceller
    if (!(visite.cles > 0 || visite.cles_calcul > 0)) {
        return {
            lockable: false,
            message: Constantes.TEXT_TOOLTIP_PAS_DE_CLES
        };
    }

    // Si la visite est en fk-xxxxx
    if(isNaN(visite.uid)){
        return {
            lockable: false,
            message: Constantes.TEXT_TOOLTIP_NEW_VISITE
        };
    }

    return {
        lockable: true,
        message: Constantes.TEXT_TOOLTIP_SI_SCELLABLE
    };
}

const isInsoFile = async (visite) => {
    const {email, mdp} = getCurrentUser();
    const pass = encodeURIComponent(mdp);
    const apiGetVisitsFiles = config.apiData.domain + config.apiData.entities.visits.visit_files_get + `&login=${email}&pass=${pass}&visit_uid=${visite.uid}`;
    return fetch(apiGetVisitsFiles, {method: 'GET'})
        .then(response => response.json())
        .then(data => {
            if (data.files.length === 0) {
                return false;
            } else {
                return data.files.findIndex(file => file.startsWith('inso_')) !== -1;
            }
        });
}

const getIsForVisite = (visite) => {

    if( config.visits_types.find(item => item.id === visite.type_visite)
        && config.visits_types.find(item => item.id === visite.type_visite).forvisit !== "undefined"
        && config.visits_types.find(item => item.id === visite.type_visite).forvisit !== undefined
        && config.visits_types.find(item => item.id === visite.type_visite).forvisit === false){
        return false;
    } else {
        return true;
    }
}

const setVisiteType = async (visiteUid, type) => {

    // Récupérer la visite dans la base locale
    await dbGet('visits', visiteUid).then(async visite => {

        // Changer le type de visite avec le nouveau type
        visite.type_visite = type;

        // Mettre à jour la date d'update et le user_update
        visite.date_update = getTimestamp();
        const user = getCurrentUser();
        visite.user_update = user.uid;

        // Enregistrer la visite dans la base de données locale
        await dbAdd('visits', visite).then(async uid => {

            // Mettre à jour la visite dans la liste en mémoire
            if(visites){
                const visiteToUpdate = visites.find(v => v.uid === uid);
                visiteToUpdate.type_visite = type;
                visiteToUpdate.date_update = visite.date_update;
            } else {
                await dbFetchStore('visits').then(data => {
                    visites = data;
                    _publish();
                });
            }

            // Enregistrement de la visite sur le serveur
            if(window.navigator.onLine){
                const visiteToSave = {...visite};
                const contents = await dbFetchFiltered('visits_content', 'uid', uid);
                visiteToSave.contents = contents;
                await _saveVisiteToServer(visiteToSave);
            }

            // Mise à jour de la vue
            _publish();
        });
    });
}

const saveCurrentVisite = async (visiteToSave) => {
    
    const nextVisite = await dbGet('visits', visiteToSave.uid);
    const nextVisiteContents = await dbFetchFiltered('visits_content', 'uid', visiteToSave.uid);
    nextVisite.contents = nextVisiteContents;

    const uid = await _saveVisiteToServer({...nextVisite})
    return uid;
}

const subscribeNextLevelCount = (subscriber) => {
    if(nextLevelCountSubscribers.indexOf(subscriber) === -1){
        nextLevelCountSubscribers.push(subscriber);
        return _unsubscribeNextLevelCount;
    }
}

/**
 *
 * @param {Object} data
 *
 * renvoie aux inscrits (visite-sommaire-item) le nombre
 * de criteres manquants pour la section concernée.
 *
 * Cette fonction est appellée par les blocks H1.
 *
 */
const publishNextLevelCount = (data) => {
    nextLevelCountSubscribers.forEach(subscriber => {
        subscriber(data);
    })
}

/* MÉTHODES PRIVÉES */

const _unsubscribeVisites = (subscriber) => {
    if(subscribers.indexOf(subscriber) !== -1){
        subscribers.splice(subscribers.indexOf(subscriber), 1);
    }
}

const _publish = () => {
    subscribers.forEach(subscriber => {
        subscriber(visites);
    });
}

const _unsubscribeNextLevelCount = (subscriber) => {
    if(nextLevelCountSubscribers.indexOf(subscriber) !== -1){
        nextLevelCountSubscribers.splice(nextLevelCountSubscribers.indexOf(subscriber), 1);
    }
}

const _loadVisitesFromServer = async (credentials) => {
    try {

        // Adresse du serveur selon environnement
        const host = config.apiData.domain;

        // Récupération de la dernière date de synchronisation
        const lastSyncDate = await dbGet('app_params', 'last_sync_visites').then(result => {
            return result ? result.value : false;
        });

        // préparation de la date de synchro à envoyer au serveur
        const lastsyncParam = lastSyncDate ? '&last_sync=' + lastSyncDate : '';

        // URL définitive pour la requête
        const pass = encodeURIComponent(credentials.pass);
        const fetchUrl = `${host}${config.apiData.entities.visits.getList}&login=${credentials.login}&pass=${pass}${lastsyncParam}`;

        const response = await fetch(fetchUrl);

        if(response.ok){

            const data = await response.json();

            if(data.visits.length > 0){

                // Tableau pour stocker momentanement les visites formatées
                const visitesFiltered = [];

                // Mise au norme des visites reçues
                for(const item of data.visits){
                    item.uid = item.type_visite === "vstarch" ? item.uid : parseInt(item.uid);
                    item.version = parseInt(item.version);
                    item.date_create = parseInt(item.date_create);
                    item.date_update = parseInt(item.date_update);
                    item.date_visite = item.date_visite ? parseInt(item.date_visite) : parseInt(item.date_create);
                    item.cpt_rendu = (item.cpt_rendu && item.cpt_rendu.length > 0) ? JSON.parse(item.cpt_rendu) : null;
                    item.cles = item.cles ? parseInt(item.cles) : 0;
                    item.cles_calcul = item.cles_calcul ? parseInt(item.cles_calcul) : 0;
                    item.scelle = item.scelle ? parseInt(item.scelle) : null;

                    // Vérifier si la visite existe en local
                    await dbGet('visits', item.uid)
                    .then(result => {
                        // Si oui…
                        if(result){
                            // Si la visite reçue est plus récente…
                            if((item.date_update) > (result.date_update)){
                                // On l'ajoute au tableau
                                visitesFiltered.push(item);
                            }
                        } else { // Si non, elle n'exite pas en local
                            // on l'ajoute au tableau
                            visitesFiltered.push(item);
                        }
                    });
                }

                if(visitesFiltered.length > 0){

                    // Formatage et enregistrement des contenus des visites
                    visitesFiltered.forEach(async visite => {


                        if(visite){

                            if(visite.contents.length) {

                                visite.contents.forEach(contentObject => {

                                    contentObject.uid = parseInt(contentObject.uid);

                                    if(contentObject.group_visite.startsWith('th_') && contentObject.valide){
                                        if(contentObject.valide === "1"){
                                            contentObject.valide = true;
                                        } else {
                                            contentObject.valide = false;
                                        }
                                    }

                                    if(contentObject.content && contentObject.content.length){
                                        try {
                                            const parsedContent = JSON.parse(contentObject.content);
                                            if(Array.isArray(parsedContent)){
                                                contentObject.content = parsedContent;
                                            }
                                        } catch(e) {
                                            console.log("Json Content invalid for visite ", visite.uid);
                                        }
                                    } else {
                                        contentObject.content = [];
                                    }
                                });

                                // Enregistrement des contenus dans la base locale
                                await _saveContentsToDB(visite).then(async result => {
                                    // Suppression des contenus et enregistrement de la visite dans la base locale
                                    delete visite.contents;
                                    await dbAdd('visits', visite);
                                });

                            }
                        }

                    });

                } else {
                    _publish();
                    return visites;
                }

            } else {
                _publish();
                return visites;
            }

        } else {
            console.warn('ERREUR LORS DU CHARGEMENT DES VISITES !');
            return visites;
        }
    } catch (error) {
        console.warn('ERREUR : ', error);
        return visites;
    }

}

const _loadVisiteFromServer = async (credentials, uid) => {

    const host = config.apiData.domain;
    const pass = encodeURIComponent(credentials.pass);
    const url = `${host}${config.apiData.entities.visits.get}&login=${credentials.login}&pass=${pass}&visit_uid=${uid}`;
    const response = await fetch(url);

    if(response.ok){
        const data = await response.json();
        if(data.success){
            const visite = data.visit;

            visite.uid = visite.type_visite === "vstarch" ? visite.uid : parseInt(visite.uid);
            visite.version = parseInt(visite.version);
            visite.date_create = parseInt(visite.date_create);
            visite.date_update = parseInt(visite.date_update);
            visite.scelle = visite.scelle ? parseInt(visite.scelle) : null;
            visite.date_visite = visite.date_visite ? parseInt(visite.date_visite) : parseInt(visite.date_create);
            visite.cpt_rendu = (visite.cpt_rendu && visite.cpt_rendu.length > 0) ? JSON.parse(visite.cpt_rendu) : null;
            visite.cles = visite.cles ? parseInt(visite.cles) : 0;
            visite.cles_calcul = visite.cles_calcul ? parseInt(visite.cles_calcul) : 0;

            visite.contents.forEach(contentObject => {
                contentObject.uid = parseInt(contentObject.uid);
                if(contentObject.group_visite.startsWith('th_') && contentObject.valide){
                    contentObject.valide = parseInt(contentObject.valide);
                }
                if(contentObject.content && contentObject.content.length){
                    try {
                        const parsedContent = JSON.parse(contentObject.content);
                        if(Array.isArray(parsedContent)){
                            contentObject.content = parsedContent;
                        }
                    } catch(e) {
                        console.log("Json Content invalid for visite ", visite.uid);
                        // throw new Error('contenu JSON de la visite mal formé');
                    }
                } else {
                    contentObject.content = [];
                }
            });

            _saveContentsToDB(visite).then(result => {
                delete visite.contents;
                dbAdd('visits', visite);
            });
        }
    }
}

const _lockVisiteToServer = async (visite) => {

    const { uid, email, mdp } = getCurrentUser();
    const pass = encodeURIComponent(mdp);

    const host = config.apiData.domain;
    const endpoint = `${host}${config.apiData.entities.visits.sceller}&login=${email}&pass=${pass}&user_uid=${uid}&visit_uid=${visite.uid}`;

    const response = await fetch(endpoint);

    if(response.ok){

        const data = await response.json();

        if(!data.success) {
            console.warn("ERREUR SERVEUR : ", data.message);
            return false;
        }

        if(data.agr){

            visite.scelle = getTimestamp();
            visite.user_uid = uid;

            await dbAdd('visits', visite);

            await updateLocationAgrement(visite.loc_hlo, data.agr);
        }

        return true;

    } else {

        console.warn("ERREUR LORS DE L'ENREGISTREMENT DE LA VISITE !");
        return false;

    }
}

/**
 *
 * Chargement des archives à partir du serveur
 * et remplacement des archives locales
 *
 */
const _loadArchives = async (credentials) => {

    // Vider les archives de la table visite
    await dbDeleteFiltered('visits', 'type_visite', 'vstarch').then(count => console.log(`${count} archives deleted`));

    // Chargement des archives
    const host = config.apiData.domain;
    const pass = encodeURIComponent(credentials.pass);
    const fetchUrl = `${host}${config.apiData.entities.visits.archives}&login=${credentials.login}&pass=${pass}`;

    const response = await fetch(fetchUrl);

    if(response.ok){
        const data = await response.json();

        const visitesToSave = data.visits.map(visite => {
            visite.scelle = true;
            visite.cles = parseInt(visite.cles);
            visite.cles_calcul = parseInt(visite.cles_calcul);
            visite.date_create = parseInt(visite.date_create);
            visite.date_update = parseInt(visite.date_update);
            visite.date_visite = visite.date_visite ? parseInt(visite.date_visite) : parseInt(visite.date_create);
            return visite;
        });

        return await dbBulkAdd('visits', visitesToSave).then(result => {
            return result;
        });
    }

}

const _checkVisitesWithServer = async (credentials) => {
    try {
        const host = config.apiData.domain;
        const pass = encodeURIComponent(credentials.pass);
        const url = `${host}${config.apiData.entities.visits.check}&login=${credentials.login}&pass=${pass}`;
        const response = await fetch(url);
        if(response.ok){
            const data = await response.json();
            if(data.success){

                const visitesIds = data.visits; // tableau des uid reçus

                // Récupérer les visites manquantes
                visitesIds.forEach(uid => {
                    const existe = visites.find(visite => visite.uid === uid);
                    if(!existe){
                        _loadVisiteFromServer(credentials, uid);
                    }
                });


                visites.forEach(async visite => {

                    if(isNaN(visite.uid)){
                        return;
                    } else {
                        // Si l'uid de la visite locale, n'est pas dans les uid reçus, supprimer localement la visite
                        if(!visitesIds.includes(visite.uid) && visite.type_visite !== 'vstarch'){
                            await _deleteLocalVisite(visite);
                        }
                    }
                });
                return visites;
            }
        } else {
            console.warn('ERREUR LORS DU CHARGEMENT DES VISITES SUPPRIMÉES !');
            return visites;
        }
    } catch (error) {
        console.warn('ERREUR : ', error);
    }
}

const _deleteLocalVisite = async (visite) => {
    await dbDelete('visits', visite.uid).then(async () => {
        await dbFetchFiltered('visits_content', 'uid', visite.uid);
        const index = visites.findIndex(v => v.uid === visite.uid);
        visites.splice(index, 1);
    })
}

const _saveVisiteToServer = async (visite) => {

    if(visite.type_visite === 'vstarch') return false;

    try {
        const currentUser = getCurrentUser();
        const host = config.apiData.domain;
        let endpoint = "";

        // Mise en conformité des timestamp avec le serveur
        const dateVisite = visite.date_visite;
        const dateUpdate = visite.date_update;
        const dateCreate = visite.date_create;

        const api_pass = encodeURIComponent(currentUser.mdp);

        const isVisiteScellee = visite.scelle ? visite.scelle : false;

        // Ne pas enregistrer une visite qui n'a pas de contenu
        if(!visite.contents) {
            console.log(`La visite ${visite.uid} n'a pas de contenus`);
            return false;
        }

        if(visite.uid){ // Mise à jour

            if(isNaN(visite.uid)) return false;

            endpoint = `${host}${config.apiData.entities.visits.update}&login=${currentUser.email}&pass=${api_pass}&user_uid=${visite.user_uid}&visit_uid=${visite.uid}&date_update=${dateUpdate}&date_create=${dateCreate}&date_visite=${dateVisite}&scelle=${isVisiteScellee}&cles=${visite.cles ? visite.cles : ''}&cles_calcul=${visite.cles_calcul}&version=${visite.version}&loc_hlo=${visite.loc_hlo}&type_bien=${visite.type_bien}&type_visite=${visite.type_visite}`;

            //les updates par des admins ne modifient pas les _update
            if( parseInt(currentUser.level) !== 100 || currentUser.uid === visite.user_uid ){
                endpoint += `&date_update=${dateUpdate}`;
            }

        } else { // Creation
            endpoint = `${host}${config.apiData.entities.visits.new}&login=${currentUser.email}&pass=${api_pass}&user_uid=${currentUser.uid}&date_create=${dateCreate}&date_visite=${dateVisite}&version=${visite.version}&type_visite=${visite.type_visite}&type_bien=${visite.type_bien}&loc_hlo=${visite.loc_hlo}&fkuid=${visite.fkuid}&user_email=${currentUser.email}&user_tel=${currentUser.tel}&user_lastname=${currentUser.lastname}&user_firstname=${currentUser.firstname}&user_adress1=${currentUser.adress1}&user_adress2=${currentUser.adress2}&user_adress3=${currentUser.adress3}&user_commune=${currentUser.commune}&user_postal_code=${currentUser.postal_code}`;
        }

        const formdata = new FormData();

        formdata.append("contents", JSON.stringify(visite.contents));

        if(visite.cpt_rendu){
            formdata.append('cpt_rendu', JSON.stringify(visite.cpt_rendu));
        }

        // Arrêt des requêtes en attente
        window.stop();

        const response = await fetch(endpoint, {
            method: 'POST',
            body: formdata
        });

        if(response.ok){
            const data = await response.json();
            if(!data.success) {
                console.warn("ERREUR CHEZ MIKA : ", data.message);
                return false;
            }

            return visite.uid ? visite.uid : data.uid;

        } else {
            console.warn("ERREUR LORS DE L'ENREGISTREMENT DE LA VISITE !");
            return false;
        }
    } catch (error) {
        console.warn('ERREUR : ', error);
        return false;
    }
}

const _deleteVisitFromServer = async (visitUid) => {
    
    if(!window.navigator.onLine) return visitUid;
    
    try {
        const { uid, email, mdp } = getCurrentUser();
        const pass = encodeURIComponent(mdp);
        const host = config.apiData.domain;
        const response = await fetch(`${host}${config.apiData.entities.visits.delete}&login=${email}&pass=${pass}&visit_uid=${visitUid}&user_update=${uid}`);
        if(response.ok){
            const data = await response.json();
            if(!data.success) {
                console.warn("ERREUR CHEZ MIKA : ", data.message);
                return false;
            }
            return visitUid;
        } else {
            console.warn("ERREUR LORS DE LA SUPPRESSION DE LA VISITE !");
            return visitUid;
        }
    } catch (error) {
        console.warn('ERREUR : ', error);
        return visitUid;
    }
}

const _saveContentsToDB = async (visite) => {

    visite.contents.forEach(async content => {

        if(!content.group_visite.length || !content.content || !content.content.length) return;

        await dbAdd('visits_content', content);

    });

    return visite;
}

const _syncWithServer = async() => {

    if(!isSyncActive) return false;

    if(!window.navigator.onLine || !isLoggedIn()) {
        clearInterval(syncInterval);
        syncInterval = null;
        return false;
    };

    // Récupération de la date de dernière synchro
    await dbGet('app_params', 'last_sync_visites')
    .then(async syncData => {

        // Récupération des visites
        await dbFetchFilteredNotEqual('visits', 'type_visite', 'vstarch')
        .then(async visitesData => {

            for(const visite of visitesData) {

                // Récupération des contenus de la visite
                await dbFetchFiltered('visits_content', 'uid', visite.uid)
                .then(result => {
                    if(result.length < 1){
                        return false;
                    }
                    visite.contents = result;
                });

                // Si nouvelle visite créée localement : enregistrement sur le serveur
                if( isNaN(visite.uid) ){
                    _saveFakeVisite(visite);
                } else {
                    // Sinon visite mise à jour : enregistrement de la visite modifiée sur le serveur
                    if((!syncData || visite.date_update > syncData.value) && visite.type_visite !== 'vstarch'){
                        await _saveVisiteToServer(visite);
                    }
                }
            }
        });

    });

    // Récupération des visites à partir du serveur
    const { email, mdp } = getCurrentUser();
    await _loadVisitesFromServer({login: email, pass: mdp})
    .then(async result => {
        // Vérifier si les visites ont des contenus
        await _getHasContent(result);

        visites = result;
        _publish();
    });

    // Mise à jour de la date de synchro
    dbAdd('app_params', {'name': 'last_sync_visites', 'value': getTimestamp()});

}

/* Enregistrer une visite créée hors ligne sur le serveur */
const _saveFakeVisite = async (visite) => {

    const visiteToSave = JSON.parse(JSON.stringify(visite));
    visiteToSave.fkuid = visiteToSave.uid;
    delete visiteToSave.uid;

    return await _saveVisiteToServer(visiteToSave)
    .then(async newUid => {
        if (newUid) {

            visiteToSave.uid = parseInt(newUid);

            for (const content of visiteToSave.contents) {
                content.uid = parseInt(newUid);
            }

            return await _saveContentsToDB(visiteToSave)
            .then(async result => {

                try{
                    await _saveVisiteToServer(visiteToSave);
                } catch(error) {
                    console.log(error);
                }

                delete visiteToSave.contents;

                return await dbAdd('visits', visiteToSave)
                .then(async id => {
                    // Ajout de la visite dans la liste des visites
                    visites.push(visiteToSave);

                    await dbDelete('visits', visite.uid);
                    visites.forEach((v, index) => {
                        if (v.uid === visite.uid) {
                            visites.splice(index, 1);
                        }
                    });

                    visite.contents.forEach(async content => {
                        await dbDelete('visits_content', [content.uid, content.group_visite]);
                    });

                    _publish();
                    return visiteToSave.uid;
                });
            });

        } else {
            console.warn("Erreur lors de l'envoie de la nouvelle visite au serveur");
        }
    });
}

const _getHasContent = async (data) => {

    if(!data) return false;

    return data.map(async visite => {
        return await dbGet('visits_content', [visite.uid, visite.type_bien]).then(mainContent => {
            visite.hascontent = mainContent ? (mainContent.content && mainContent.content.length > 0) : false;
            return visite;
        });
    });
}

const _loadThemesSchemas = async () => {
    const groupes = config.group_visit;
    for(const id in groupes){
        if(id.startsWith('th_')){
            const version = groupes[id].version;
            await import("../form-schema/" + id + "-schema." + version + ".json")
            .then(result => {
                themesSchemas.push(result[0]);
            })
            .catch(error => console.warn("Erreur fichier json : ", error));
        }
    };
}

const _loadVisitesFromDB = async () => {
    return await dbFetchStore('visits').then(data => {
        return data;
    });
}

export {
    loadVisites,
    getVisites,
    getAllVisites,
    refreshVisites, 
    getVisiteContent,
    getVisiteContents,
    getVisiteById,
    getVisitesByHlo,
    getLastVisitesByHlo,
    subscribeVisites,
    visiteAdd,
    visiteUpdate,
    visiteDelete,
    setCurrentVisite,
    createVisite,
    getCurrentVisite,
    initVisite,
    saveVisite,
    getVisitesByTheme,
    getVisitesFiltered,
    deleteVisiteContent,
    lockVisite,
    validateTheme,
    getThemeSchemaById,
    saveVisiteComment,
    getVisiteHasContent,
    setSyncActive,
    getIsLockable,
    getIsForVisite,
    setVisiteType,
    saveCurrentVisite,
    subscribeNextLevelCount,
    publishNextLevelCount,
    deleteFileVisite,
}
