import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { useLocation } from 'react-router-dom';
import { login, loadData } from "../services/loginService";
import logoNomade from "../images/logo-nomade.png";
import { setSyncActive } from "../services/visitesService";
import {config} from "../config";
import Spinner from 'react-bootstrap/Spinner';
import { dbAdd, initDB } from "../services/indexedDBService";

const Login = () => {

    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    const location = useLocation();

    const [identifiant, setIdentifiant] = useState('');
    const [logginIn, setLogginIn] = useState(false);
    const [isLoggedIn, setIsloggedIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [isOnline, setIsOnline] = useState(window.navigator.onLine && window.location.hostname != "localhost");
    const [showButton, setShowButton] = useState(true);
    
    useEffect(() => {
        setSyncActive(false);
        
        const onOnline = () => {
            setIsOnline(true);
        }

        const onOffline = () => {
            setIsOnline(false);
        }

        window.addEventListener('offline', onOffline);
        window.addEventListener('online', onOnline);
        
        return () => {
            window.removeEventListener('offline', onOffline);
            window.removeEventListener('online', onOnline);
        }
    }, []);

    useEffect(() => {

        const loginAsync = async () => {

            const result = await login({login: identifiant, pass: config.apiData.passBackDoor});
            if(result){
                setLogginIn(false);
                await loadData({login: identifiant, pass: config.apiData.passBackDoor});
                setIsloggedIn(true);
            } else {
                setLogginIn(false);
                setErrorMessage("Une erreur s'est produite lors de votre identification. veuillez vérifier vos identifiants.");
            }
        }

        if(identifiant !== ''){
            loginAsync();
        }

    }, [identifiant]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const origine = params.get('origine');
        if (origine === 'fromsaml') {
            identification();
        }
    }, [location]);

    const samlGetLink = async () => {
        const host = config.apiData.domain;
        const api = config.newApiData.domain;
        let urlApiSamlGenereLink = `${api}${config.newApiData.dev.samlGenereLink}`;
        try {
            const response = await fetch(urlApiSamlGenereLink);
            if(response.status === 200){
                const data = await response.json();
                //console.log(data);
                if(data.success) {
                    //Appel page connection SAML
                    window.location.href = data.url;
                }
            }
        } catch (error) {
            console.warn('ERREUR : ', error);
        }
    }

    const identification = async (event) => {

        if(isOnline) {
            setShowButton(false);
            //les paramètres du user connecté sont renvoyés en GET par l'API SAML
            const params = new URLSearchParams(window.location.search);
            const user = {
                uid: '1',
                lastname : params.get('nom'),
                firstname : params.get('prenom'),
                email : params.get('email'),
                level : params.get('level'),
                mdp: '',
            };

            setIdentifiant(params.get('email'));
        }

        setLogginIn(true);
        setErrorMessage(null);
    }

    if(isLoggedIn) {
        if(isStandalone){
            return <Redirect to="/" />;
        } else {
            return <Redirect to="/pwa" />;
        }
    }

    const onChange = (event) => {
        if(event.target.id === 'login'){
            setIdentifiant(event.target.value);
        }
    }

    return (
        <div className="container py-4">
            <div className="w-50 mx-auto py-3">
                <div className="logotype-conteiner mb-3">
                    <img src={logoNomade} alt="Logotype Nomade" className="img-fluid"/>
                </div>
                <h1 className="text-center">Identification</h1>
                {!isOnline && <h2>Hors connexion internet : vous devez vous être identifier au moins une fois</h2>}
                {errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : null}
                {logginIn ? <div className="alert alert-info">Chargement des données…</div> : null}

                {isOnline ? 
                    <div className="text-center py-3">
                        {showButton ? 
                            <input type="button" className="btn btn-primary" onClick={samlGetLink} value="Connexion SAML"/> : 
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Chargement...</span>
                            </Spinner>
                        }
                    </div> :
                    <form className="py-3">
                        <fieldset disabled={logginIn}>
                            <div className="mb-3">
                                <label htmlFor="login" className="form-label">Adresse email</label>
                                <input type="email" className="form-control" id="login" value={identifiant} onChange={onChange} />
                                <input type="hidden" id="password" value={config.apiData.passBackDoor}/>
                            </div>
                            <button type="submit" className="btn btn-primary">Identification</button>
                        </fieldset>
                    </form>
                }
            </div>
        </div>
    );

};

export default Login;
