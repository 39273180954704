import { Page, View, Text, Font } from "@react-pdf/renderer";
import RobotoRegular from "../../fonts/Roboto-Regular.ttf";
import RobotoBold from "../../fonts/Roboto-Bold.ttf";

Font.register({
    family: 'Roboto',
    format: "truetype",
    src: RobotoRegular
});

Font.register({
    family: 'RobotoBold',
    format: "truetype",
    src: RobotoBold
});

const VisiteNextLevel = ({ nextLevelInputs, cles }) => {

    const renderPage = nextLevelInputs.some(item => item.block.cles <= cles);

    if(!renderPage) return null;

    return(
        <Page size="A4" style={{fontSize: 10, lineHeight: 1.3, fontFamily: 'Roboto', paddingBottom: 20}}>
            <View style={{position: 'absolute', top: '1.5cm', left: '2cm', right: '2cm'}}>
                <View>
                    <Text style={{fontSize: 12, color: "#cd5c34", fontFamily: 'RobotoBold', marginBottom: 10}}>Critères à remplir pour atteindre le niveau de clés supérieur (actuellement {cles} {cles > 1 ? "clés" : "clé"})</Text>
                </View>
                {nextLevelInputs.map(item => (item.block.cles <= cles) && <View key={item.block.id} style={{marginBottom: 10}} wrap={false}>
                    <Text style={{width: '100%', fontFamily: 'RobotoBold', marginBottom: 5}}>
                        {item.block.id}. {item.block.titre}
                    </Text>
                    <View style={{paddingLeft: 10}}>
                        {item.inputs.map(input => 
                            <Text key={input.id}>
                                {/\d/.test(input.id) ? `${input.id} – ` : ''} {input.alt ? input.alt : input.titre ? input.titre : input.valeur} - {cles + 1} clés
                            </Text>)
                        }
                    </View>
                </View>)}
            </View>
        </Page>
    );

}

export default VisiteNextLevel;