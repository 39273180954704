import { dbAdd, dbGet } from "./indexedDBService"

let userPrefs = {
    showVisiteThemes: true,
    showVisiteSommaire: true
}

const subscribers = [];

const getUserPrefs = async () => {
    return await dbGet('app_params', 'user_prefs').then(data => {
        if(data){
            userPrefs = data.value;
            _publish();
            return userPrefs;
        }
    }).catch(error => {
        console.log("ERREUR : ", error.message);
        return userPrefs;
    });
}

const setUserPref = (pref) => {
    userPrefs = {...userPrefs, ...pref};
    dbAdd('app_params', {name: 'user_prefs', value: userPrefs})
    .then(result => {
        _publish();
    });
}

const subscribeUserPrefs = (subscriber) => {
    if(subscribers.indexOf(subscriber) === -1){
        subscribers.push(subscriber);
        return _unsubscribePrefs;
    }
}

const _unsubscribePrefs = (subscriber) => {
    if(subscribers.indexOf(subscriber) !== -1){
        subscribers.splice(subscribers.indexOf(subscriber), 1);
    }
}

const _publish = () => {
    subscribers.forEach(subscriber => {
        subscriber(userPrefs);
    });
}

const loadUserPrefs = () => {
    dbGet('app_params', 'user_prefs').then(data => {
        if(data){
            userPrefs = data.value;
            _publish();
        } else {
            dbAdd('app_params', {name: 'user_prefs', value: userPrefs});
            _publish();
        }
    }).catch(error => {
        console.log("ERREUR : ", error.message);
    });
}

export { loadUserPrefs, getUserPrefs, setUserPref, subscribeUserPrefs }