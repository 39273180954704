import { useContext } from "react";
import { Badge, OverlayTrigger, Popover } from "react-bootstrap";
import { VisiteContext } from "../../services/visiteContext";
import useNextLevel from "../../services/useNextLevel";

const RadiogroupInput = ({ block, radio, valeur, onClick }) => {

    const { locationRef } = useContext(VisiteContext);
    
    radio.cles = Number.isInteger(radio.cles) ? [radio.cles] : radio.cles;
    
    const isChecked = radio.valeur === valeur;
    const { isNextLevel } = useNextLevel({ block: {...radio, active: isChecked, range: block.range, parent: block} });

    const renderAide = (props) => {
        return(
            <Popover id="aide" {...props}>
                <Popover.Body>
                    <div dangerouslySetInnerHTML={{__html: radio.aide}}></div>
                </Popover.Body>
            </Popover>
        );
    }

    const getClesLabel = (radio) => {
        let label = undefined;
        if(radio.cles){
            label = radio.cles[0];
            if(radio.cles.length > 1){
                label = label + ' ➝ ' + radio.cles[radio.cles.length - 1];
            }
        }
        return label;
    }

    const getCles = (radio) => {
        if(radio.cles && radio.cles.condition){
            if(radio.cles.condition === "capacite" && parseInt(radio.cles.max) >= parseInt(locationRef.current.capacity)){
                return parseInt(radio.cles.value[radio.cles.value.length - 1]);
            } else {
                return 0;
            }
        } else {
            const nextCles = radio.cles ? radio.cles[radio.cles.length -1] : undefined;
            return nextCles;
        }
    }

    const clesLabel = getClesLabel(radio);
    const cles = getCles(radio);

    return (
        <div  className={`form-check visit-form-check form-check-radio${(isNextLevel && !isChecked) ? " next" : ""}`} data-checked={isChecked ? 'oui' : 'non'} data-cles={isChecked? radio.cles : 0}>
            <input className="form-check-input radio-group-input" type="radio" value={radio.valeur} name={block.id} id={radio.id} cles={cles} onChange={onClick} checked={isChecked} range={radio.cles} />
            <label className="form-check-label" htmlFor={radio.id}>
                <span className="label-id me-2">{block.id}</span>
                {clesLabel && clesLabel !== "0" ? <span className="me-2"><Badge pill bg="secondary">{clesLabel} <i className="icon icon-cle"></i></Badge></span> : null}
                {radio.aide && <OverlayTrigger placement="auto" overlay={renderAide}>
                    <i className="icon icon-info fs-5 text-secondary"></i>
                    </OverlayTrigger>
                }
                <span className="titre">{radio.valeur}</span>
            </label>
        </div>
    );
}

export default RadiogroupInput;
